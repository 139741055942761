import React, { useEffect } from "react";
import {
  Card,
  Grid,
  Typography,
  Box,
  LinearProgress,
  Tooltip,
} from "@mui/material";
import LocalPostOfficeIcon from "@mui/icons-material/LocalPostOffice";
import DirectionsBikeIcon from "@mui/icons-material/DirectionsBike";
import WarningIcon from "@mui/icons-material/Warning";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

const DashIconsResumenDispachsMinimal = ({ items, cant }) => {
  useEffect(() => {}, [cant]);

  const COLORS = {
    Creadas: "#1E90FF", // Azul
    Ruta: "#591e8f", // Morado
    Incidencias: "#FF0000", // Rojo
    Finalizadas: "#32CD32", // Verde
    Total: "#030303", // Negro
  };

  const getIcon = (id) => {
    switch (id) {
      case "Creadas":
        return <LocalPostOfficeIcon sx={{ color: COLORS[id] }} />;
      case "Ruta":
        return <DirectionsBikeIcon sx={{ color: COLORS[id] }} />;
      case "Incidencias":
        return <WarningIcon sx={{ color: COLORS[id] }} />;
      case "Finalizadas":
        return <CheckCircleIcon sx={{ color: COLORS[id] }} />;
      default:
        return <DirectionsBikeIcon sx={{ color: COLORS[id] }} />;
    }
  };

  const total = cant?.Total || 1; // Evitar división por 0
  const getPercentage = (value) => ((value / total) * 100).toFixed(1); // Formato con 1 decimal

  return (
    <Box sx={{ p: 0.5 }}>
      <Grid container spacing={1}>
        {items &&
          items.map((item) => (
            <Grid
              item
              xs={12}
              sm={6}
              md={2.4}
              key={item.id}
              sx={{ display: "flex", justifyContent: "center" }}
            >
              <Card
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  p: 1,
                  borderRadius: 2,
                  boxShadow: 2,
                  width: "100%",
                  textAlign: "center",
                }}
              >
                {/* Ícono con Tooltip */}
                {/* Cantidad */}
                <Typography
                  variant="h4"
                  sx={{ fontWeight: 600, color: COLORS[item.id] }}
                >
                  {cant[item.id]}
                </Typography>

                <Typography variant="body2" sx={{ fontWeight: 500 }}>
                  {item.id}
                </Typography>

                {/* Nombre del estado */}
                {/* Barra de Progreso */}
                <LinearProgress
                  variant="determinate"
                  value={parseInt(cant[item.id])}
                  sx={{
                    width: "80%",
                    height: 6,
                    borderRadius: 3,
                    backgroundColor: "#f1f1f1",
                    "& .MuiLinearProgress-bar": {
                      backgroundColor: COLORS[item.id],
                    },
                    mt: 1,
                  }}
                />
                {/* Porcentaje */}
                <Box
                  sx={{
                    display: "flex",
                    mt: 1,
                  }}
                >
                  <Tooltip title={item.id}>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: 30,
                        height: 30,
                        borderRadius: "50%",
                        backgroundColor: "#FFF",
                        boxShadow: `0 4px 10px ${COLORS[item.id]}`,
                        mb: 1,
                      }}
                    >
                      {getIcon(item.id)}
                    </Box>
                  </Tooltip>
                  <Typography
                    variant="caption"
                    sx={{ m: 1, color: COLORS[item.id] }}
                  >
                    {getPercentage(cant[item.id])}%
                  </Typography>
                </Box>
              </Card>
            </Grid>
          ))}
      </Grid>
    </Box>
  );
};

export default DashIconsResumenDispachsMinimal;
