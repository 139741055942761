import { useEffect } from "react";
import {
  getFirestore,
  collectionGroup,
  query,
  where,
  getDocs,
} from "firebase/firestore";

const FirestoreIndexTester = () => {
  useEffect(() => {
    const fetchData = async () => {
      try {
        const db = getFirestore(); // Obtener instancia de Firestore
        const testOrderId = "99M101455519CL"; // 🔥 Usa un ID de prueba de una orden existente

        // 🔍 Primera consulta: Buscar en "waypoints" donde `ids` contiene `testOrderId`
        const waypointsQuery = query(
          collectionGroup(db, "waypoints"),
          where("ids", "array-contains", testOrderId)
        );
        const snapshot = await getDocs(waypointsQuery);

        console.log(
          `✅ Consulta 1 ejecutada. Se encontraron ${snapshot.docs.length} documentos.`
        );
        snapshot.docs.forEach((doc) => {
          console.log(`📌 Documento encontrado:`, doc.id, doc.data());
        });
      } catch (error) {
        console.error("❌ Error en la consulta 1 Firestore:", error);
      }

      try {
        const db = getFirestore(); // Instancia de Firestore

        // 🔍 Segunda consulta: Buscar `ids` con un valor exacto
        const waypointsQuerydirect = query(
          collectionGroup(db, "waypoints"),
          where("id", "==", "Ruta_5_index_0") // Usa un ID de prueba conocido
        );

        const snapshotDirect = await getDocs(waypointsQuerydirect);
        console.log(
          `✅ Consulta 2 ejecutada. Se encontraron ${snapshotDirect.docs.length} documentos.`
        );
        snapshotDirect.docs.forEach((doc) => {
          console.log(
            `📌 Documento encontrado en consulta directa:`,
            doc.id,
            doc.data()
          );
        });
      } catch (error) {
        console.error("❌ Error en la consulta 2 Firestore:", error);
      }
    };

    fetchData();
  }, []);

  return <div>Ejecutando consulta Firestore...</div>;
};

export default FirestoreIndexTester;
