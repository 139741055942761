export const routeInfo = async (updatedOrders, routes) => {
  const packageCounts = {};
  const ordersByRoute = {};

  if (updatedOrders && updatedOrders.length > 0) {
    updatedOrders.forEach((order) => {
      const route = order.driverLicensePlate || "Sin Ruta";
      if (!packageCounts[route]) {
        packageCounts[route] = 0;
      }
      packageCounts[route] += 1; // Suponiendo 1 bulto por orden
      if (!ordersByRoute[route]) {
        ordersByRoute[route] = [];
      }
      ordersByRoute[route].push(order);
    });
  }

  // Asegurar que existan entradas para rutas sin órdenes
  routes.forEach((route) => {
    if (!ordersByRoute[route]) {
      ordersByRoute[route] = [];
      packageCounts[route] = 0;
    }
  });

  return { packageCounts, ordersByRoute };
};
