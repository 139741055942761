import PropTypes from "prop-types";
import { useState } from "react";
// @mui
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import FormHelperText from "@mui/material/FormHelperText";
// hooks
import { useResponsive } from "../../hooks/useResponsive.js";
import LocalizationProviderContext from "../../context/LocalizationProviderContext.jsx";
import { format } from "date-fns";

// ----------------------------------------------------------------------

export default function CustomDateRangePicker({
  title = "Seleccione la fecha o rango de su búsqueda",
  variant,
  startDate,
  endDate,
  onChangeStartDate,
  onChangeEndDate,
  open,
  onClose,
  error,
  setNewDate,
  singleDateSelection = false, // Nueva opción para permitir solo una fecha
}) {
  const mdUp = useResponsive("up", "md");
  const isCalendarView = variant === "calendar";

  // Estado interno para manejar la selección del rango
  const [selectedStartDate, setSelectedStartDate] = useState(startDate);
  const [selectedEndDate, setSelectedEndDate] = useState(endDate);

  const handleDateSelection = (date) => {
    if (singleDateSelection) {
      // Si solo se permite un día, actualiza solo la fecha de inicio
      setSelectedStartDate(date);
      setSelectedEndDate(null);
      onChangeStartDate(date);
      onChangeEndDate(null);
    } else {
      if (!selectedStartDate || (selectedStartDate && selectedEndDate)) {
        // Si no hay fecha de inicio o ya hay un rango seleccionado, se inicia uno nuevo
        setSelectedStartDate(date);
        setSelectedEndDate(null);
        onChangeStartDate(date);
        onChangeEndDate(null);
      } else {
        // Si ya hay una fecha de inicio, establecer la fecha final asegurando que sea posterior
        if (date > selectedStartDate) {
          setSelectedEndDate(date);
          onChangeEndDate(date);
        } else {
          // Si la segunda fecha es anterior a la primera, reiniciar el rango
          setSelectedStartDate(date);
          setSelectedEndDate(null);
          onChangeStartDate(date);
          onChangeEndDate(null);
        }
      }
    }
  };

  return (
    <LocalizationProviderContext>
      <Dialog
        fullWidth
        maxWidth={isCalendarView ? false : "xs"}
        open={open}
        onClose={onClose}
        PaperProps={{
          sx: {
            ...(isCalendarView && {
              maxWidth: 720,
            }),
          },
        }}
      >
        <DialogTitle sx={{ pb: 2 }}>{title}</DialogTitle>

        <DialogContent
          sx={{
            ...(isCalendarView &&
              mdUp && {
                overflow: "unset",
              }),
          }}
        >
          <Stack
            justifyContent="center"
            spacing={isCalendarView ? 3 : 2}
            direction={isCalendarView && mdUp ? "row" : "column"}
            sx={{ pt: 1 }}
          >
            {isCalendarView ? (
              <>
                <Paper
                  variant="outlined"
                  sx={{
                    borderRadius: 2,
                    borderColor: "divider",
                    borderStyle: "dashed",
                  }}
                >
                  <DateCalendar
                    value={selectedStartDate}
                    onChange={handleDateSelection}
                    renderDay={(day, _selectedDates, pickersDayProps) => {
                      const formattedDay = format(day, "yyyy-MM-dd");
                      const formattedStart = selectedStartDate
                        ? format(selectedStartDate, "yyyy-MM-dd")
                        : null;
                      const formattedEnd = selectedEndDate
                        ? format(selectedEndDate, "yyyy-MM-dd")
                        : null;

                      const isInRange =
                        selectedStartDate &&
                        selectedEndDate &&
                        formattedDay > formattedStart &&
                        formattedDay < formattedEnd;

                      return (
                        <div
                          {...pickersDayProps}
                          style={{
                            backgroundColor: isInRange ? "#e3f2fd" : "inherit",
                            borderRadius: "50%",
                            padding: "5px",
                          }}
                        >
                          {day.getDate()}
                        </div>
                      );
                    }}
                  />
                </Paper>

                {!singleDateSelection && (
                  <Paper
                    variant="outlined"
                    sx={{
                      borderRadius: 2,
                      borderColor: "divider",
                      borderStyle: "dashed",
                    }}
                  >
                    <DateCalendar
                      value={selectedEndDate}
                      onChange={handleDateSelection}
                    />
                  </Paper>
                )}
              </>
            ) : (
              <>
                <DatePicker
                  label="Start date"
                  value={selectedStartDate}
                  onChange={handleDateSelection}
                />

                {!singleDateSelection && (
                  <DatePicker
                    label="End date"
                    value={selectedEndDate}
                    onChange={handleDateSelection}
                  />
                )}
              </>
            )}
          </Stack>

          {error && (
            <FormHelperText error sx={{ px: 2 }}>
              La fecha de finalización debe ser posterior a la de inicio.
            </FormHelperText>
          )}
        </DialogContent>

        <DialogActions>
          <Button variant="outlined" color="inherit" onClick={onClose}>
            Cancelar
          </Button>

          <Button
            disabled={error}
            variant="contained"
            onClick={() => setNewDate(selectedStartDate, selectedEndDate)}
          >
            Aplicar
          </Button>
        </DialogActions>
      </Dialog>
    </LocalizationProviderContext>
  );
}

CustomDateRangePicker.propTypes = {
  error: PropTypes.bool,
  onChangeEndDate: PropTypes.func,
  onChangeStartDate: PropTypes.func,
  onClose: PropTypes.func,
  open: PropTypes.bool,
  title: PropTypes.string,
  startDate: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.instanceOf(Date),
  ]),
  endDate: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.instanceOf(Date),
  ]),
  setNewDate: PropTypes.func,
  singleDateSelection: PropTypes.bool, // Nueva propiedad para permitir selección de un solo día
};
