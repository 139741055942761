import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  Typography,
  Paper,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  LinearProgress,
} from "@mui/material";
import { doc, GeoPoint, getDoc } from "firebase/firestore";
import * as XLSX from "xlsx";
import { useDropzone } from "react-dropzone";
import { parseISO, format } from "date-fns";
import { db } from "../../firebase";
import { updateDoc } from "firebase/firestore";

const ReusableFormImportOrdersByData99Minutos = ({
  open,
  handleClose,
  setOpen,
  returnDocumentData,
}) => {
  const [erroMensaje, setErroMensaje] = useState(null);
  const [file, setFile] = useState(null);
  const [validationErrors, setValidationErrors] = useState([]); // Almacena los errores de validación
  const [dataDocument, setDataDocument] = useState([]);
  const [orderOnlyDelyvery, setOrderOnlyDelyvery] = useState([]);
  const [Loading, setLoading] = useState(false);

  const [selectedBodegas, setSelectedBodegas] = useState([
    "100",
    "494",
    "617",
    "549",
  ]);

  const onDrop = (acceptedFiles) => {
    setErroMensaje(null);
    setFile(acceptedFiles[0]); // Almacena el archivo seleccionado
  };

  useEffect(() => {
    console.log(selectedBodegas);
  }, [selectedBodegas]);

  const fieldConfig = {
    ID: { required: true },
    FECHACARGA: { required: true },
    IDEINTERNAL: { required: true },
    FECHAENTREGA: { required: true },
    TIPODELIVERY: { required: true },
    CODIGOSUCRUSAL: { required: false },
    ORIGEN: { required: true },
    ORIGENLAT: { required: false },
    ORIGENLOG: { required: false },
    DESTINO: { required: true },
    DIR1: { required: true },
    DIR2: { required: false },
    DIR3: { required: false },
    COMUNA: { required: true },
    REGION: { required: true },
    PAIS: { required: true },
    LAT: { required: false },
    LOG: { required: false },
    COMENTARIO: { required: false },
    TELEFONO: { required: true },
    EMAIL: { required: true },
    NOMBRECLIENTE: { required: true },
    TIPOPRODUCTO: { required: false },
    CONTENIDO: { required: true },
    SKU: { required: false },
    DESCRPCION: { required: false },
    INSTRUCCIONES: { required: false },
    UNIDADES: { required: true },
    VALOR: { required: true },
    ALTO: { required: true },
    ANCHO: { required: true },
    LARGO: { required: true },
    PESO: { required: true },
    VOLUMEN: { required: true },
  };

  const bodegasOrigenes = [
    {
      codigo: "100",
      nombre: "AVDA. PADRE HURTADO N  40, LAS CONDES",
      location: { lat: -33.3942082, lng: -70.5485522 },
    },
    {
      codigo: "104",
      nombre: "AVDA. PEDRO FONTOVA N 7777 LOCAL 2, HUECHURABA",
      location: { lat: -33.3483687, lng: -70.6727171 },
    },
    {
      codigo: "178",
      nombre: "GRAN AVENIDA N  8877, LA CISTERNA",
      location: { lat: -33.5383471, lng: -70.6675443 },
    },
    {
      codigo: "287",
      nombre: "AVDA. VICUNA MACKENNA N  9101, LA FLORIDA",
      location: { lat: -33.539887, lng: -70.5942882 },
    },
    {
      codigo: "291",
      nombre:
        "SAN PEDRO DE LA PAZ, CAMINO EL VENADO N  716, SAN PEDRO DE LA PAZ",
      location: { lat: 36.8494738, lng: -73.0959819 },
    },
    {
      codigo: "348",
      nombre: "21 DE MAYO N 298-300, ARICA",
      location: { lat: -18.4784108, lng: -70.3216163 },
    },
    {
      codigo: "357",
      nombre: "AVDA. BORIES N  971 - 972, PUNTA ARENAS",
      location: { lat: -53.1614083, lng: -70.9100888 },
    },
    {
      codigo: "362",
      nombre: "AVDA. LIBERTAD N 1217 - 1223, VINA DEL MAR",
      location: { lat: -33.0098774, lng: -71.551928 },
    },
    {
      codigo: "376",
      nombre: "AVDA. PEDRO MONTT N  1898, VALPARAISO",
      location: { lat: -33.0470167, lng: -33.0470167 },
    },
    {
      codigo: "413",
      nombre: "GREGORIO CORDOVEZ N  601, LA SERENA",
      location: { lat: -29.9033508, lng: -71.251009 },
    },
    {
      codigo: "48",
      nombre: "BARROS ARANA N  762 - 774, CONCEPCION",
      location: { lat: -36.8260969, lng: -73.0516382 },
    },
    {
      codigo: "494",
      nombre: "SAN FRANCISCO N  521 LOCAL 1, SANTIAGO",
      location: { lat: -33.4513468, lng: -70.6494758 },
    },
    {
      codigo: "53",
      nombre: "CHACABUCO N  393, COPIAPO",
      location: { lat: -27.366712, lng: -70.3360593 },
    },
    {
      codigo: "549",
      nombre: "SALESIANOS N  1334 LOCAL 1, SAN MIGUEL",
      location: { lat: -33.4909839, lng: -70.659971 },
    },
    {
      codigo: "556",
      nombre: "AVDA.PAJARITOS N  2624, LOCAL1,2,3,4, MAIPU",
      location: { lat: -33.506567, lng: -70.7603913 },
    },
    {
      codigo: "617",
      nombre: "AVDA. MACUL N  3019 LOCAL 2,3 Y 4, MACUL",
      location: { lat: -33.4823761, lng: -70.6021013 },
    },
    {
      codigo: "62",
      nombre: "SANTA BLANCA N  1996, LO BARNECHEA",
      location: { lat: -33.3552463, lng: -70.5350653 },
    },
    {
      codigo: "620",
      nombre: "AVENIDA ANGAMOS B  02170 LOCAL 103, ANTOFAGASTA",
      location: { lat: -23.695352, lng: -70.4185503 },
    },
    {
      codigo: "638",
      nombre: "ROOSEVELT N  1702, CONCEPCION",
      location: { lat: -36.8218442, lng: -73.0383021 },
    },
    {
      codigo: "971",
      nombre: "AVDA. APOQUINDO N  3363 L-2, LAS CONDES",
      location: { lat: -33.4166925, lng: -70.5974901 },
    },
    {
      codigo: "973",
      nombre: "IRARRAZAVAL N  2700, NUNOA",
      location: { lat: -33.4542578, lng: -70.6059563 },
    },
  ];

  const validateFields = (data) => {
    let errors = [];

    const transformedData = data.map((item) => {
      // Convertimos TELEFONO a string si no lo es
      if (item.TELEFONO && typeof item.TELEFONO !== "string") {
        item.TELEFONO = String(item.TELEFONO);
      }
      return item;
    });

    transformedData.forEach((item, index) => {
      // Recorremos cada campo de la configuración
      Object.keys(fieldConfig).forEach((field) => {
        const isRequired = fieldConfig[field].required;
        const fieldValue = item[field];

        // Si el campo es obligatorio y está vacío o no existe
        if (isRequired && (!fieldValue || fieldValue === "")) {
          errors.push({
            index,
            field,
            message: `Falta el valor de ${field}`,
          });
        }
      });
    });

    setValidationErrors(errors); // Almacena los errores
    return { isValid: errors.length === 0, errors, transformedData }; // Retorna un objeto con la validación, los errores y los datos transformados
  };

  const filterByBodegas = (data, selectedBodegas) => {
    return data.filter((row) => selectedBodegas.includes(row["Bodega"]));
  };

  const filterByStatus = (data, status) => {
    return data.filter((row) => row["Estado"] === status);
  };

  const formatFecha = (fecha) => {
    const parsedDate = parseISO(fecha); // Convierte la fecha en formato ISO
    return format(parsedDate, "dd/MM/yyyy"); // Devuelve la fecha en "DD/MM/YYYY"
  };

  const convertToGeoPoint = (geoString) => {
    if (!geoString) return null; // Maneja valores faltantes

    try {
      // Parsear el string como un array
      const coords = JSON.parse(geoString);

      // Verificar que el array tiene dos elementos válidos
      if (Array.isArray(coords) && coords.length === 2) {
        const [lng, lat] = coords;

        // Retorna un objeto GeoPoint de Firebase
        return new GeoPoint(lat, lng);
      }
      throw new Error("Formato inválido para DELIVERYGEOREF");
    } catch (error) {
      console.error("Error al convertir DELIVERYGEOREF:", error);
      return null; // Retorna null en caso de error
    }
  };

  const mapFields = (data, columnMapping) => {
    return data.map((row) => {
      let mappedRow = {};

      // Itera sobre las columnas y asigna valores mapeados
      for (const [externalKey, internalKey] of Object.entries(columnMapping)) {
        let value = row[externalKey];

        // Formatea fechas
        if (internalKey === "FECHACARGA" || internalKey === "FECHAENTREGA") {
          value = value ? formatFecha(value) : ""; // Formatea la fecha si es válida
        }

        // Valores predeterminados para campos específicos
        if (internalKey === "EMAIL") value = value || "noinforma@bicci.cl";
        if (internalKey === "REGION") value = value || "Region Metropolitana";
        if (internalKey === "PAIS") value = value || "CHILE";
        if (internalKey === "VALOR") value = value || 1;
        if (internalKey === "ALTO") value = value || 0.01;
        if (internalKey === "ANCHO") value = value || 0.01;
        if (internalKey === "VOLUMEN") value = value || 0.03;
        if (internalKey === "PESO") value = value || 0.03;
        if (internalKey === "LARGO") value = value || 0.01;
        if (internalKey === "TELEFONO") value = value || 1;
        if (internalKey === "CONTENIDO") value = value || "BULTO";

        if (internalKey === "TIPODELIVERY") value = "SD";
        if (internalKey === "SKU") value = "NOINFORMA";

        // Asignar ORIGENLAT y ORIGENLOG según la bodega
        if (internalKey === "ORIGENLAT" || internalKey === "ORIGENLOG") {
          const bodegaCode = row["Bodega"]; // Campo en los datos fuente que contiene el código de la bodega
          const bodega = bodegasOrigenes.find((b) => b.codigo === bodegaCode);

          if (bodega) {
            value =
              internalKey === "ORIGENLAT"
                ? bodega.location.lat
                : bodega.location.lng;
          } else {
            value = ""; // Si no se encuentra la bodega, deja el valor vacío
          }
        }
        if (internalKey === "DELIVERYGEOREF") value = convertToGeoPoint(value);

        // Asigna el valor mapeado al nuevo objeto
        mappedRow[internalKey] = value || "";
      }

      return mappedRow;
    });
  };
  const handleFileSave = async () => {
    if (!file) return;

    const data = await file.arrayBuffer();
    const workbook = XLSX.read(data, { type: "buffer" });
    const worksheet = workbook.Sheets[workbook.SheetNames[0]];
    const jsonData = XLSX.utils.sheet_to_json(worksheet);

    // crear objeto jsonData filtrar Bodega por las selectedBodegas
    console.log(jsonData, selectedBodegas);
    const filteredData = filterByBodegas(jsonData, selectedBodegas);

    console.log(filteredData);

    const columnMapping = {
      "Codigo Interno": "ID",
      "Fecha Creación": "FECHACARGA",
      "Fecha Creación": "FECHAENTREGA",
      "Codigo Externo": "IDEINTERNAL",
      "Tipo Servicio": "TIPODELIVERY",
      Bodega: "CODIGOSUCRUSAL",
      "Dirección Origen": "ORIGEN",
      ORIGENLAT: "ORIGENLAT",
      ORIGENLOG: "ORIGENLOG",
      Destinatario: "NOMBRECLIENTE",
      Dirección: "DESTINO",
      DIR1: "DIR1",
      DIR2: "DIR2",
      DIR3: "DIR3",
      Comuna: "COMUNA",
      Región: "REGION",
      País: "PAIS",
      LAT: "LAT",
      LOG: "LOG",
      Comentario: "COMENTARIO",
      Teléfono: "TELEFONO",
      Email: "EMAIL",
      Contenido: "CONTENIDO",
      SKU: "SKU",
      DESCRPCION: "DESCRPCION",
      Instrucciones: "INSTRUCCIONES",
      Paquetes: "UNIDADES",
      Valor: "VALOR",
      Alto: "ALTO",
      Ancho: "ANCHO",
      Largo: "LARGO",
      Peso: "PESO",
      Volumen: "VOLUMEN",
    };

    const columnDeliveryMapping = {
      "Codigo Interno": "ID",
      "Codigo Externo": "IDEINTERNAL",
      "Fecha Entrega": "FECHAENTREGA",
      Conductor: "DRIVER",
      Geolocalización: "DELIVERYGEOREF",
      Bodega: "CODIGOSUCRUSAL",
    };

    const mappedData = mapFields(filteredData, columnMapping);

    console.log(mappedData);

    const onlyDelivery = filterByStatus(filteredData, "Pedido Entregado");

    console.log(onlyDelivery);

    const mappedDataDelyvery = mapFields(onlyDelivery, columnDeliveryMapping);

    console.log(mappedDataDelyvery);

    setOrderOnlyDelyvery(mappedDataDelyvery);

    // crear objeto jsonData haciendo Mach entre los valores del documeto ejemplo NOMBRECLIENTE = Destinatario

    const validation = validateFields(mappedData);

    if (validation.isValid) {
      returnDocumentData(
        validation.transformedData,
        validation.errors,
        "ready"
      );
      handleClose();
    } else {
      setDataDocument(validation.transformedData);
      setErroMensaje(validation.errors);
    }
  };

  const hadleEdith = async (data, validation) => {
    returnDocumentData(data, validation, "ready");
    setErroMensaje(null);
    setFile(null);
    setValidationErrors([]); // Almacena los errores de validación
    setDataDocument([]);
    handleClose();
  };

  const {
    getRootProps,
    getInputProps,
    open: openFileSelector,
  } = useDropzone({
    onDrop,
    accept: ".xlsx",
    maxFiles: 1,
    maxSize: 5000000,
    noClick: true,
  });

  const handleSendApiUldate = async (data) => {
    console.log(data);

    //F149172446186

    //createdAt
    //updatedAt

    //driverName
    //driverPhone
    //driverLocation
    //driverLicensePlate
    //driverID
    //driverAmount

    //userNameReceptor
    //userDniReceptor

    //status
    //picsTaken
    //pics
    //*** NUEVA  */
    //deliveryGeoRef

    setLoading(true);
    try {
      if (!data || data.length === 0) {
        console.warn("No hay datos para procesar.");
        // props.setAlertMensajes("No se recibieron datos para procesar.");
        return;
      }

      for (const [index, element] of data.entries()) {
        const companyIdOrder = element.ID ? element.ID.toString() : null;
        if (!companyIdOrder) {
          console.warn(`Orden sin ID en la fila ${index + 1}`);
          continue;
        }

        const newComment = element.COMMET || "Datos en aplicación externa";
        const closedOrder = element.CLOSED ? 8 : 7;
        const userNameReceptor =
          element.RECEPTOR?.trim() || "Gestionado en APP externa";
        const userDniReceptor = element.DNIRECEPTOR?.trim() || "123456789";
        const pics = element.FOTO?.trim()
          ? [element.FOTO]
          : [
              "https://firebasestorage.googleapis.com/v0/b/bicci-7ed2f.appspot.com/o/webfrontend%2Fpicsun.png?alt=media&token=eb050e77-6351-4958-988b-06c129f1f9e8",
            ];
        const picsTaken = pics.length > 0;

        const orderRef = doc(
          db,
          process.env.REACT_APP_COL_USERS_ORDERS,
          companyIdOrder
        );
        const docSnapshot = await getDoc(orderRef);

        if (docSnapshot.exists()) {
          console.log("Actualizando orden de admin");
          const currentComments = docSnapshot.data().observationAdmin || [];
          currentComments.push(newComment);

          await updateDoc(orderRef, {
            observationAdmin: currentComments,
            pics,
            userNameReceptor,
            userDniReceptor,
            status: closedOrder,
            picsTaken,
          });

          console.log(`Datos actualizados con éxito: ${companyIdOrder}`);
        } else {
          console.log(`No se encontró el ID: ${companyIdOrder}`);
        }
      }
    } catch (error) {
      console.error("Error al procesar datos:", error);
      //      props.setAlertMensajes("Error al procesar los datos. Por favor, verifique los detalles.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      PaperProps={{
        style: {
          width: "90%",
          height: "90vh",
          maxWidth: "none",
        },
      }}
    >
      <DialogTitle id="form-dialog-title">
        <Typography variant="h6">
          Crear envíos con documento importación
        </Typography>
      </DialogTitle>

      <DialogContent>
        <FormControl fullWidth sx={{ mt: 2 }}>
          <InputLabel id="bodega-select-label">Seleccionar Bodega</InputLabel>
          <Select
            labelId="bodega-select-label"
            multiple
            value={selectedBodegas} // Cambia el estado a un array
            onChange={(event) => setSelectedBodegas(event.target.value)} // Maneja múltiples selecciones
            renderValue={
              (selected) =>
                selected
                  .map(
                    (codigo) =>
                      bodegasOrigenes.find((bodega) => bodega.codigo === codigo)
                        ?.nombre
                  )
                  .join(", ") // Muestra los nombres seleccionados separados por comas
            }
          >
            {bodegasOrigenes.map((bodega) => (
              <MenuItem key={bodega.codigo} value={bodega.codigo}>
                <Checkbox
                  checked={selectedBodegas.indexOf(bodega.codigo) > -1}
                />
                {bodega.codigo} - {bodega.nombre}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Button onClick={openFileSelector} color="primary">
          Seleccionar Archivo
        </Button>
        <Paper variant="outlined" sx={{ p: 2, textAlign: "center", mt: 2 }}>
          <Box
            {...getRootProps()}
            sx={{ border: "2px dashed #ccc", p: 2, minHeight: "450px" }}
          >
            <input {...getInputProps()} />
            <Typography>
              Arrastra y suelta tu reporte archivo aquí, o haz clic para
              seleccionar archivos
            </Typography>
          </Box>
        </Paper>
        {file && <p>Archivo listo para guardar: {file.name}</p>}
        {erroMensaje
          ? erroMensaje.map((error, index) => (
              <div key={index}>
                <Typography variant="body2" color="error">
                  {`LINEA ${error.index + 1},  Error en el campo ${
                    error.field
                  }: ${error.message}`}
                </Typography>
              </div>
            ))
          : null}
      </DialogContent>
      <div>{Loading ? <LinearProgress></LinearProgress> : null}</div>
      <DialogActions>
        {orderOnlyDelyvery && orderOnlyDelyvery.length > 0 ? (
          <Button
            onClick={() => {
              handleSendApiUldate(orderOnlyDelyvery);
            }}
            color="primary"
          >
            Enviar Actualizacion de {orderOnlyDelyvery.length} Ordenes
            Entregadas
          </Button>
        ) : null}
        <Button
          onClick={() => {
            setErroMensaje(null);
            setFile(null);
            handleClose();
          }}
          color="primary"
        >
          Cancelar
        </Button>
        {erroMensaje ? (
          <Button
            onClick={() => hadleEdith(dataDocument, erroMensaje)}
            color="primary"
            disabled={!file}
          >
            Editar
          </Button>
        ) : null}
        <Button onClick={handleFileSave} color="primary" disabled={!file}>
          Guardar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ReusableFormImportOrdersByData99Minutos;
