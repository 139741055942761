function useGlobalObject() {
  const newRoute = {
    driverName: "", //1
    currentOrderList: {}, //2
    durationValue: 0, //3
    endTimeDelivery: "", //4
    legs: [], //5
    driverID: "", //6
    startTimeDelivery: "", //7
    origin: { lat: -33.4592491, lng: -70.6010891 }, //8
    id: "", //9
    lastModify: "", //10
    dispacthDelyvery: 0, //11
    waypoint_order: [], //12
    date: 0, //13
    travelMode: "", //14
    dateDelivery: "", //15
    routeStates: 0, //16
    dateDeliveryText: "", //17
    isDelete: false, //18
    isActive: true, //19
    routePoints: 0, //20
    dispacthPartials: 0, //21
    destination: { lat: -33.4592491, lng: -70.6010891 }, //22
    wayPoints: [], //23
    maxTimeDelivery: "00:10", //24
    vehicleID: "", //25
    warnings: "", //26
    distanceValue: 0, //27
    optimizeWaypoints: false, //28
    companyId: "", //29
    createBy: "", //30
    dispacthNotDelyvery: 0, //31
    setRoutePoints: 0, //32
    createDate: "0", //33
    fullCompliance: 0, //34
  };

  return { newRoute };
}

export default useGlobalObject;
