import React from "react";
import { Container, Input, Form } from "semantic-ui-react";
import { Box, Button } from "@mui/material";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { actualizarPassPorEnvioEmailUdk } from "../../redux/usuarioDucks";
import { actualizarPassPorEnvioEmail } from "../../redux/acccionesClientes";

const data = {
  email: "",
};
const Recuperarcuenta = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [userPassReset, setUserPassReset] = React.useState("");
  const [success, setSuccess] = React.useState(null);
  const [error, setError] = React.useState(null);

  const actualizarPassPorEmail = (e) => {
    e.preventDefault();

    if (!userPassReset.trim()) {
      console.log("Ingrese Email");
      setError("Ingrese Email");
      return;
    }
    data.email = userPassReset;

    setSuccess("El mensaje fue enviado a tu correo electrónico...");
    dispatch(actualizarPassPorEnvioEmail(data));
    function delay() {
      setError(null);
      setSuccess(null);
      //console.log('Navigate login')
      navigate(`/login`);
    }
    setTimeout(delay, 1000);
  };

  return (
    <Container className="bodyFroms" id="bodyFroms">
      <div className="mt-5 text-center">
        <Container className="formcontent " id="ajustefromIni">
          Recuperar cuenta
          {error ? (
            <div className="alert alert-danger topalerts">{error}</div>
          ) : null}
          <Form onSubmit={actualizarPassPorEmail}>
            {success ? (
              <div className="alert alert-success topalerts">{success}</div>
            ) : null}
            <Form.Group widths="equal">
              <Form.Field
                className="field "
                control={Input}
                type="email"
                icon="at"
                iconPosition="left"
                label="Recibirás un correo electrónico para establecer una nueva contraseña"
                value={userPassReset}
                onChange={(e) => setUserPassReset(e.target.value)}
              />
            </Form.Group>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                p: 2,
              }}
            >
              <Button
                sx={{
                  backgroundColor: "#591E8F",
                  border: "none",
                  borderRadius: "3px",
                  color: "#fff",
                  cursor: "pointer",
                  fontSize: "14px",
                  marginTop: "30px",
                  outline: "none",
                  padding: "5px",
                  minWidth: "50px",
                  fontWeight: 400,
                  margin: "0em 1em 0em 0em",
                  ":hover": {
                    backgroundColor: "#25b2f4 !important",
                    color: "#591e8f",
                  },
                }}
                variant="contained"
                type="sutmit"
              >
                Enviar email
              </Button>
            </Box>
          </Form>
        </Container>
      </div>
    </Container>
  );
};

export default Recuperarcuenta;
