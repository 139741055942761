import React from "react";
import {
  Grid,
  Box,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Chip,
  Button,
  Stack,
  FormControlLabel,
  Switch,
  FormGroup,
  Divider,
} from "@mui/material";
import EventIcon from "@mui/icons-material/Event";
import CustomDateRangePicker from "../../../CustomDateRangePicker";
import { fDate } from "../../../../utils/objects/FormatTime";
import DashIconsResumenDispachsMinimal from "../../DashIconsResumenDispachs/DashIconsResumenDispachsMinimal";
import RouteAnalyticsBox from "../ToolRouteAnalyticsBox/RouteAnalyticsBox";

const ToolFiltersComponent = ({
  selectedCompanies,
  selectedOrigins,
  filteredCompanies,
  filteredOrigins,
  handleChange,
  handleOrigins,
  rangeInputPicker,
  setNewDate,
  excludePendings,
  handleExcludePendings,
  rightComponent, // Permite pasar otro componente para la otra mitad
  listItems,
  cantListItems,
  viewRangeInputPicker,
  routes,
}) => {
  return (
    <Grid
      container
      spacing={2}
      sx={{
        backgroundColor: "#F9FAFC",
        padding: "10px",
        borderRadius: "8px",
      }}
      id="filtrosEmpresas"
    >
      {/* 🎯 MITAD IZQUIERDA - FILTROS */}
      <Grid item xs={12} md={6}>
        <Box sx={{ width: "100%", p: 1 }}>
          <Box sx={{ display: "flex", gap: 1, flexWrap: "wrap" }}>
            {/* 📌 Selección de Empresas */}
            <FormControl fullWidth sx={{ flex: 1, backgroundColor: "#FFF" }}>
              <InputLabel id="company-select-label">
                Filtrar Empresas
              </InputLabel>

              <Select
                labelId="company-select-label"
                multiple
                value={selectedCompanies}
                onChange={handleChange}
                renderValue={(selected) => (
                  <Chip
                    label={`${selected.length} empresa(s) seleccionada(s)`}
                  />
                )}
              >
                {filteredCompanies.map((company, index) => (
                  <MenuItem
                    key={index}
                    value={company.integrationServicesBicciID}
                  >
                    {company.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            {/* 📌 Selección de Origen */}
            {filteredOrigins.length > 0 && (
              <FormControl fullWidth sx={{ flex: 1, backgroundColor: "#FFF" }}>
                <InputLabel id="origin-select-label">Filtrar Origen</InputLabel>
                <Select
                  labelId="origin-select-label"
                  multiple
                  value={selectedOrigins}
                  onChange={handleOrigins}
                  renderValue={(selected) => (
                    <Chip
                      label={`${selected.length} origen(es) seleccionado(s)`}
                    />
                  )}
                >
                  {filteredOrigins.map((origin, index) => (
                    <MenuItem key={index} value={origin.placeName}>
                      {origin.placeName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
            {viewRangeInputPicker && rangeInputPicker && (
              <Box>
                <Button
                  variant="contained"
                  onClick={rangeInputPicker.onOpen}
                  sx={{
                    textTransform: "none",
                    width: "100%",
                    height: "100%",
                    background: "#6b1dc6",
                  }}
                >
                  <Stack direction="row" spacing={2} alignItems="center">
                    <Typography variant="body2" sx={{ fontWeight: 600, mb: 0 }}>
                      Fecha
                    </Typography>
                    <Typography variant="body2">
                      <strong>Desde:</strong>{" "}
                      {fDate(rangeInputPicker.startDate)}
                      <EventIcon color="#fff" />
                    </Typography>
                    <Typography variant="body2">
                      <strong>Hasta:</strong> {fDate(rangeInputPicker.endDate)}
                      <EventIcon color="#fff" />
                    </Typography>
                  </Stack>
                </Button>

                <CustomDateRangePicker
                  variant="calendar"
                  open={rangeInputPicker.open}
                  startDate={rangeInputPicker.startDate}
                  endDate={rangeInputPicker.endDate}
                  onChangeStartDate={rangeInputPicker.onChangeStartDate}
                  onChangeEndDate={rangeInputPicker.onChangeEndDate}
                  onClose={rangeInputPicker.onClose}
                  error={rangeInputPicker.error}
                  setNewDate={setNewDate}
                />
              </Box>
            )}
          </Box>
          {/* 📌 Metricas rutas si estan disponibles */}
          {routes && routes.length > 0 ? (
            <RouteAnalyticsBox routes={routes}></RouteAnalyticsBox>
          ) : null}

          {handleExcludePendings && (
            <Box sx={{ mt: 2 }}>
              <Typography variant="subtitle1" sx={{ fontWeight: 600, mb: 1 }}>
                Seleccionar Vista
              </Typography>
              <FormGroup
                sx={{ backgroundColor: "#F8F8F8", p: 1, borderRadius: "5px" }}
              >
                <FormControlLabel
                  control={
                    <Switch
                      checked={excludePendings}
                      onChange={() => handleExcludePendings(!excludePendings)}
                    />
                  }
                  label={
                    excludePendings
                      ? "Vista Sin Pendientes"
                      : "Vista Con Pendientes"
                  }
                />
              </FormGroup>
            </Box>
          )}
        </Box>
      </Grid>

      {/* 🎯 MITAD DERECHA - OTRO COMPONENTE */}
      <Grid item xs={12} md={6}>
        {rightComponent ? (
          <Typography variant="subtitle1">
            <DashIconsResumenDispachsMinimal
              items={listItems}
              cant={cantListItems}
            />
          </Typography>
        ) : null}
      </Grid>
    </Grid>
  );
};

export default ToolFiltersComponent;
