import { getFarthestPoint } from "./getFarthestPoint";
import { routeInfo } from "./routeInfo";

export const buildOptimizationPayload = async (
  optimizeOptions,
  updatedOrders,
  routes
) => {
  const { ordersByRoute } = await routeInfo(updatedOrders, routes); // Obtener órdenes agrupadas por ruta
  const optimizationRequests = [];

  Object.entries(ordersByRoute).forEach(([route, orders]) => {
    if (orders.length === 0) return; // Ignorar rutas sin órdenes

    // 📌 Determinar el origen  Ivertir valores para el API
    let origin = optimizeOptions.inputOrigin || orders[0].fromAddressLocation;
    if (!origin || !origin.lat || !origin.lng) {
      console.warn(
        `⚠️ Origen no definido para ${route}, usando primera orden.`
      );
      origin = {
        lat: orders[0]?.fromAddressLocation?._long,
        lng: orders[0]?.fromAddressLocation?._lat,
      };
    }

    // 📌 Determinar el destino según `optimizeOptions.destinationType`
    let destination;
    switch (optimizeOptions.destinationType) {
      case "mas_lejano":
        destination = getFarthestPoint(origin, orders);
        break;
      case "retorno":
        destination = optimizeOptions.origen || origin;
        break;
      case "destino":
        destination = optimizeOptions.destino || origin;
        break;
      default:
        console.warn(
          `⚠️ Tipo de destino desconocido (${optimizeOptions.destinationType}), usando origen.`
        );
        destination = origin;
    }

    // 📌 Construir la lista de waypoints
    const waypoints = [
      { id: "origen", name: "", lon: origin.lng, lat: origin.lat },
      ...orders.map((order) => ({
        id: order.id,
        name: "",
        lon: order.toAddressLocation._lat,
        lat: order.toAddressLocation._long,
      })),
      {
        id: "destino",
        name: "",
        lon: destination.lat, // Invertidos para el API
        lat: destination.lng,
      },
    ];

    // 📌 Estructura final para la optimización de esta ruta
    const routeOptimizationPayload = {
      vehicle: "driving",
      route: route,
      waypoints,
    };

    optimizationRequests.push(routeOptimizationPayload);
  });

  return optimizationRequests;
};
