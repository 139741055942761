import { Button } from "@mui/material";
import React from "react";

const ButonhandleCombineWithRoutes = ({
  dataMerge,
  selectedOrders,
  routeInfo,
  setSelectedOrders,
  setSelectedRoutes,
  setTargetRoute,
  setViewDataMerge,
  setLocalDataMerge,
}) => {
  // Calcula los centroides de las rutas
  const calculateRouteCentroids = (routeInfo) => {
    const routeCentroids = {};
    Object.entries(routeInfo.ordersByRoute).forEach(
      ([routeName, routeOrders]) => {
        const validOrders = routeOrders.filter(
          (o) =>
            o.toAddressLocation &&
            typeof o.toAddressLocation._lat === "number" &&
            typeof o.toAddressLocation._long === "number"
        );

        if (validOrders.length > 0) {
          const sumLat = validOrders.reduce(
            (acc, o) => acc + o.toAddressLocation._lat,
            0
          );
          const sumLng = validOrders.reduce(
            (acc, o) => acc + o.toAddressLocation._long,
            0
          );

          routeCentroids[routeName] = {
            lat: sumLat / validOrders.length,
            lng: sumLng / validOrders.length,
          };
        }
      }
    );
    return routeCentroids;
  };

  // Calcula la distancia entre dos coordenadas usando la fórmula de Haversine
  const haversineDistance = (lat1, lng1, lat2, lng2) => {
    const earthRadius = 6371; // Radio de la Tierra en km
    const deg2rad = (deg) => deg * (Math.PI / 180);
    const dLat = deg2rad(lat2 - lat1);
    const dLng = deg2rad(lng2 - lng1);
    const a =
      Math.sin(dLat / 2) ** 2 +
      Math.cos(deg2rad(lat1)) *
        Math.cos(deg2rad(lat2)) *
        Math.sin(dLng / 2) ** 2;
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    return earthRadius * c;
  };

  // Asigna cada orden a la ruta más cercana
  const assignOrdersToRoutes = (orders, routeCentroids) => {
    return orders.map((order) => {
      if (
        order.toAddressLocation &&
        typeof order.toAddressLocation._lat === "number" &&
        typeof order.toAddressLocation._long === "number"
      ) {
        let bestRoute = null;
        let bestDist = Infinity;

        Object.entries(routeCentroids).forEach(([routeName, centroid]) => {
          const dist = haversineDistance(
            order.toAddressLocation._lat,
            order.toAddressLocation._long,
            centroid.lat,
            centroid.lng
          );

          if (dist < bestDist) {
            bestDist = dist;
            bestRoute = routeName;
          }
        });

        return {
          ...order,
          driverLicensePlate: bestRoute || order.driverLicensePlate,
        };
      }
      return order;
    });
  };

  // Maneja la combinación de órdenes con rutas
  const handleCombineWithRoutes = () => {
    if (!selectedOrders || selectedOrders.length === 0) return;

    // Calcular centroides de las rutas
    const routeCentroids = calculateRouteCentroids(routeInfo);

    const ordersToUpdate = selectedOrders
      .map((id) => dataMerge.find((order) => order.id === id))
      .filter(Boolean);

    console.log(ordersToUpdate);
    // Asignar las órdenes a rutas
    const updatedOrders = assignOrdersToRoutes(ordersToUpdate, routeCentroids);

    // Actualizar las órdenes en el estado
    setViewDataMerge((prev) =>
      prev.map((order) =>
        selectedOrders.includes(order.id)
          ? updatedOrders.find((updated) => updated.id === order.id) || order
          : order
      )
    );

    setLocalDataMerge((prev) =>
      prev.map((order) =>
        selectedOrders.includes(order.id)
          ? updatedOrders.find((updated) => updated.id === order.id) || order
          : order
      )
    );

    // Limpiar selección de órdenes y rutas
    setSelectedOrders([]);
    setSelectedRoutes([]);
    setTargetRoute("");
  };

  return (
    <Button
      disabled={selectedOrders.length === 0}
      onClick={handleCombineWithRoutes}
    >
      Combinar con rutas cercanas
    </Button>
  );
};

export default ButonhandleCombineWithRoutes;
