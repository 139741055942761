import { Timestamp } from "firebase/firestore";
import {
  createDocumentRoute,
  waypoint,
  waypointOutRute,
} from "./DocumentRoute";

export function buildRouteDocuments({
  routes,
  routeInfo,
  reqApiOptimizationData,
  routeGeometry,
  optimizedWaypoints,
  metricsRoutes,
  companyID = "demoCompany",
  userCreator = "demoUser",
  routeColors,
}) {
  const routeDocuments = [];

  // Función para convertir la distancia en texto a metros
  function normalizeDistance(formattedDistance) {
    if (!formattedDistance) return 0;
    const numericValue = parseFloat(formattedDistance);
    return Math.round(numericValue * 1000); // Convertir a metros
  }

  // Función para convertir el tiempo en texto a segundos
  function normalizeTime(formattedTime) {
    if (!formattedTime) return 0;
    const numericValue = parseFloat(formattedTime);
    return Math.round(numericValue * 60); // Convertir a segundos
  }

  // Función para convertir el tiempo en texto a segundos
  function normalizeTime(formattedTime) {
    if (!formattedTime) return 0;
    const numericValue = parseFloat(formattedTime);
    return Math.round(numericValue * 60); // Convertir a segundos
  }

  function formatTimestampToString(timestamp) {
    if (!timestamp) return "";

    let dateObj;

    // Si el timestamp es un Firestore Timestamp
    if (
      timestamp?.seconds !== undefined &&
      timestamp?.nanoseconds !== undefined
    ) {
      dateObj = new Date(timestamp.seconds * 1000); // Convertir segundos a milisegundos
    } else if (timestamp instanceof Date) {
      dateObj = timestamp;
    } else {
      return ""; // Manejar valores inválidos
    }

    const date = dateObj.toISOString().slice(0, 10); // YYYY-MM-DD
    const time = dateObj.toISOString().slice(11, 19); // HH:MM:SS
    return `${date} ${time}`; // Formato completo
  }

  for (const item of routes) {
    const doc = createDocumentRoute();

    // 1. Generar ID único para la ruta
    const dateStamp = new Date().toISOString().slice(0, 10).replace(/-/g, "");
    const randomPart = Math.floor(Math.random() * 90000000 + 10000000);
    const nombreRuta = `${dateStamp}_${companyID}_${item}`; // necesito utizar rando y no companyID

    doc.id = nombreRuta;
    // 2. Asignar datos básicos
    doc.route = item;
    doc.companyID = companyID;
    doc.createdBy = userCreator;
    doc.packageCounts = routeInfo.packageCounts[item] || 0;
    doc.routeColor = routeColors[item];

    // 3. Distancia y tiempo estimado
    if (metricsRoutes[item]) {
      doc.totalDistance = metricsRoutes[item].totalDistance || "";
      doc.totalTime = metricsRoutes[item].totalTime || "";
    }

    // 4. Obtener geometría de la ruta y almacenarla como un objeto indexado
    const geometryMatch = routeGeometry.find((g) => g.route === item);

    if (geometryMatch && Array.isArray(geometryMatch.geometry)) {
      doc.geometry = geometryMatch.geometry.reduce((acc, point, index) => {
        acc[index] = point; // 🔥 Asigna cada punto con un índice como clave
        return acc;
      }, {});
    } else {
      doc.geometry = {}; // 🔥 Si no hay datos, almacena un objeto vacío
    }

    // 5. Extraer subrutas asociadas
    doc.subRoutes = routes.filter((sub) => sub.startsWith(`${item}_sub`));

    // 6. Extraer Waypoints dentro de la ruta
    const waypointsArray = optimizedWaypoints
      .filter((waypoint) => waypoint.routeName === item)
      .map((wp, index) => {
        const wpt = waypoint();

        wpt.id = `${item}_index_${index}`;
        wpt.routeColor = routeColors[item];
        wpt.ids = wp.ids || [];
        wpt.routeName = nombreRuta;
        wpt.name = wp.name || "";
        wpt.index = index;
        wpt.currentStatus = wp.status || 0;
        wpt.nonDeliveryStatus = wp.nonDeliveryStatus || "";
        wpt.location.lat = wp.lat || 0;
        wpt.location.log = wp.lon || 0;
        wpt.routingData.distanceMeters = normalizeDistance(wp.distancia || 0);
        wpt.routingData.formattedDistance = wp.distancia;
        wpt.routingData.estimatedTimeSeconds = normalizeTime(wp.tiempo || 0);
        wpt.routingData.formattedTime = wp.tiempo || 0;
        wpt.routingData.segment = wp.tramo || "";
        wpt.routingData.total = wp.total || 1;

        // Obtener fecha de creación desde la orden original
        const orderData = routeInfo.ordersByRoute[item]?.find(
          (o) => o.id === wp.ids[0]
        );

        if (orderData?.createdAt) {
          wpt.trackingData.creationDateFormated = formatTimestampToString(
            orderData.createdAt
          );
          wpt.trackingData.creationTime = orderData.createdAt;
        } else {
          const nowTimestamp = Timestamp.now();
          wpt.trackingData.creationDateFormated =
            formatTimestampToString(nowTimestamp);
          wpt.trackingData.creationTime = nowTimestamp;
        }
        return wpt;
      });

    // 7. Extraer Waypoints fuera de ruta (entregas no planeadas)
    const waypointsOutRoute = optimizedWaypoints
      .filter(
        (waypoint) => waypoint.routeName !== item && waypoint.nearRoute === item
      )
      .map((wp, index) => {
        const wptOut = waypointOutRute();
        wptOut.id = `${item}_index_${index}_${wp.ids?.[0] || "unknown"}`;
        wptOut.ids = wp.ids || [];
        wptOut.routeName = item;
        wptOut.name = wp.toAddress || "";
        wptOut.index = index;
        wptOut.currentStatus = wp.status || 0;
        wptOut.nonDeliveryStatus = wp.nonDeliveryStatus || "";
        wptOut.location.lat = wp.lat || 0;
        wptOut.location.log = wp.lon || 0;
        return wptOut;
      });

    // 8. Obtener datos de optimización de ruta
    // const optimizationData = reqApiOptimizationData.find(
    //   (ro) => ro.route === item
    // );

    // 9. Inicializar Analíticas
    const ordersArray = routeInfo.ordersByRoute[item] || [];
    doc.analytics.totalOrders = ordersArray.length;

    // 10. Fechas de inicio y fin
    doc.dayStart = new Date().toISOString().slice(0, 10).replace(/-/g, "");
    doc.initHour = "08:00";
    doc.endHour = "18:00";

    // 11. Estado de la ruta
    doc.isComplete = false;
    doc.isFinish = false;
    doc.isClosed = false;

    // 12. Fechas de creación y actualización
    const now = new Date();

    doc.createAt = Timestamp.fromDate(new Date(now));
    doc.updateAt = Timestamp.fromDate(new Date(now));

    // 13. Añadir subcolecciones al documento
    doc.subcollections = {
      waypoints: waypointsArray,
      waypointsOutRoute: waypointsOutRoute,
      observations: [
        {
          id: `${doc.id}_obs_1`,
          comment: "Ruta generada automáticamente",
          createdAt: now,
          createdBy: userCreator,
        },
      ],
    };

    // 14. Guardar el documento final
    routeDocuments.push(doc);
  }

  return routeDocuments;
}
