import { useState, useEffect } from "react";
import {
  getFirestore,
  collection,
  query,
  where,
  onSnapshot,
  getDocs,
} from "firebase/firestore";

const useRutasDelDia = () => {
  const [routes, setRoutes] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const db = getFirestore();
    const today = new Date();
    const todayLocal = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate()
    )
      .toISOString()
      .slice(0, 10)
      .replace(/-/g, ""); // Formato YYYYMMDD

    const rutasQuery = query(
      collection(db, "xpress_deliveryPlans"),
      where("dayStart", "==", todayLocal)
    );

    // Suscripción en tiempo real
    const unsubscribe = onSnapshot(rutasQuery, async (snapshot) => {
      const rutasData = await Promise.all(
        snapshot.docs.map(async (doc) => {
          const rutaData = { id: doc.id, ...doc.data() };

          // Obtener `waypoints` en tiempo real
          const waypointsRef = collection(doc.ref, "waypoints");
          const waypointsSnapshot = await getDocs(waypointsRef);
          const waypoints = waypointsSnapshot.docs.map((wp) => ({
            id: wp.id,
            ...wp.data(),
          }));

          // Obtener `observations` en tiempo real
          const observationsRef = collection(doc.ref, "observations");
          const observationsSnapshot = await getDocs(observationsRef);
          const observations = observationsSnapshot.docs.map((obs) => ({
            id: obs.id,
            ...obs.data(),
          }));

          return { ...rutaData, waypoints, observations };
        })
      );

      setRoutes(rutasData);
      setLoading(false);
    });

    return () => unsubscribe(); // Detener suscripción cuando el componente se desmonte
  }, []);

  return { routes, loading };
};

export default useRutasDelDia;
