import { haversineDistance } from "./haversineDistance";

export const getFarthestPoint = (origin, orders) => {
  if (!origin || !orders.length) return origin;

  return orders.reduce(
    (farthest, order) => {
      const dist = haversineDistance(
        origin.lat,
        origin.lng,
        order.toAddressLocation._lat,
        order.toAddressLocation._long
      );

      return dist > farthest.dist
        ? {
            lat: order.toAddressLocation._lat,
            lng: order.toAddressLocation._long,
            dist,
          }
        : farthest;
    },
    { lat: origin.lat, lng: origin.lng, dist: 0 }
  );
};
