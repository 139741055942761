import React, { useMemo, useState, useEffect, useRef } from "react";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import * as turf from "@turf/turf";
import {
  MapContainer,
  TileLayer,
  Marker,
  Popup,
  FeatureGroup,
  CircleMarker,
  Polyline,
  Tooltip,
} from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import * as XLSX from "xlsx";
import moment from "moment";
import {
  Box,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  FormControlLabel,
  Switch,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { EditControl } from "react-leaflet-draw";
import "leaflet-draw/dist/leaflet.draw.css";

import MapPanelLateral from "../MapPanelViewRoutes/MapPanelViewRoutes";
import * as routeManager from "../MapPanelViewRoutes/RouteManager";
import {
  translateStatesBicciFilter,
  translateStatesfalabbela,
} from "../../utils/objects/translateStates";

import { db } from "../../firebase";
import { collection, doc, getDocs, query, where } from "firebase/firestore";
import { addDoc } from "firebase/firestore";
import { updateDoc } from "firebase/firestore";
import SelectorGeoRecf from "../Dispatchdetails/SelectorGeoRecf";
import { GeoPoint } from "firebase/firestore";
import MapMenuFlotante from "./MapMenuFlotante/MapMenuFlotante";
import { useMapMenuStates } from "./MapMenuFlotante/states";
import {
  handleApplyFilter,
  handleApplyOptimize,
  handleCancelFilter,
  handleCancelOptimize,
  handleCancelSave,
  handleClosePlansDialog,
  handleNextPage,
  handleOpenFilterDialog,
  handleOpenOptimizeDialog,
  handleOpenSaveDialog,
  handlePrevPage,
  handleSaveDocument,
  handleSelectPlan,
} from "./MapMenuFlotante/handlers";

import { buildRouteDocuments } from "./MapMenuFlotante/UtilsOptimize/buildRouteDocuments";
import FirestoreIndexTester from "./MapMenuFlotante/testDev";
import { colorPalette } from "../../utils/objects/stylesStates";
import StaticPolygonsLayer from "../MapStaticPolygonsLayer/StaticPolygonsLayer";

const MapComponentDistributionsLeaflet = ({
  dataMerge,
  ridersDataByCompany,
  companyID,
  loading,
  setLoading,
  polygons,
  userdata,
  contextReq,
}) => {
  const collectionRef = collection(
    db,
    `${process.env.REACT_APP_COL_USERS_ORDERS}`
  );

  const statesRoute = useMapMenuStates();

  const [center] = useState([-33.448891, -70.669266]);
  const [zoom] = useState(13);

  // -- Estados de Rutas y Órdenes --
  const [routes, setRoutes] = useState([]);
  const [routeColors, setRouteColors] = useState({});
  const [selectedRoutes, setSelectedRoutes] = useState([]);

  const [selectedViewRoutes, setSelectedViewRoutes] = useState([]);

  const [selectedOrders, setSelectedOrders] = useState([]);

  // -- Estados para dibujo en mapa (shapes) --
  const [drawnItems, setDrawnItems] = useState([]);
  const [selectedMarkers, setSelectedMarkers] = useState([]);

  // -- Estados para panel lateral / filtros --
  const [isFilterMode, setIsFilterMode] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");

  // -- Estado para elegir a qué ruta cambiar pedidos --
  const [targetRoute, setTargetRoute] = useState("");

  // -- Expandir/colapsar rutas en el panel lateral --
  const [expandedRoutes, setExpandedRoutes] = useState([]);

  // -- Diálogo para Cambiar paradas enre rutas --
  const [openChangeRouteDialog, setOpenChangeRouteDialog] = useState(false);

  //DATOS MASIVOS
  const [viewDataMerge, setViewDataMerge] = useState(dataMerge);
  const [LocaDataMerge, setLocalDataMerge] = useState(dataMerge);
  // -- Ref para viewDataMerge (por si se necesita en funciones de turf/draw) --
  const dataMergeRef = useRef([]);
  const mapRef = useRef(null);

  // Estado para el diálogo de edición de una orden
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [editOrder, setEditOrder] = useState(null); // Orden que se está editando
  const [newLocation, setNewLocation] = React.useState(null);

  const [availableStatuses, setAvailableStatuses] = useState([]);

  const [reqApiOptimizationData, setReqApiOptimizationData] = useState([]);

  //(Paradas en el Mapa)
  const [optimizedWaypoints, setOptimizedWaypoints] = useState([]); // Waypoints optimizados
  // resultado de calculos
  const [metricsRoutes, setMetricsByRoutes] = useState([]);
  // Lineas en el mapa
  const [routeGeometry, setRouteGeometry] = useState([]); // Geometría de la ruta
  // detalles de Cada ruta
  const [routeDetails, setRouteDetails] = useState([]);

  // Agregar Rutas
  // -- Diálogo para agregar rutas --
  const [openAddRouteDialog, setOpenAddRouteDialog] = useState(false);
  const [newRouteName, setNewRouteName] = useState("");
  const [manualRoutes, setManualRoutes] = useState([]); // 📌 Almacena rutas manuales
  const [openRuoterDialog, setOpenRuoterDialog] = useState(false);
  const [isSubRoute, setIsSubRoute] = useState(false);
  const [parentRoute, setParentRoute] = useState("");
  const [availableParentRoutes, setAvailableParentRoutes] = useState([]);

  useEffect(() => {
    // console.count("reqApiOptimizationData", reqApiOptimizationData);
    // console.count("routeGeometry", routeGeometry);
    // console.count("optimizedWaypoints", optimizedWaypoints);
    // console.count("metricsRoutes", metricsRoutes);
    // console.log(routeColors);
  }, [
    reqApiOptimizationData,
    routeGeometry,
    optimizedWaypoints,
    metricsRoutes,
    routeColors,
  ]);

  useEffect(() => {}, [selectedViewRoutes]);

  useEffect(() => {
    setViewDataMerge(dataMerge);
    setLocalDataMerge(dataMerge);
    setRouteGeometry([]);
    setOptimizedWaypoints([]);
    setMetricsByRoutes([]);
    const statuses = Array.from(
      new Set(
        dataMerge.flatMap((order) => [
          // (order.falabella_status || "Sin estado").trim().toLowerCase(),
          (order.status || "Sin estado").toString().trim().toLowerCase(),
          // (order.intState || "Sin estado").toString().trim().toLowerCase(),
        ])
      )
    );
    setAvailableStatuses(statuses);
  }, [dataMerge]);

  useEffect(() => {
    if (isSubRoute && parentRoute) {
      // Contar cuántas subrutas existen para la ruta padre
      const subRoutes = routes.filter((route) =>
        route.startsWith(`${parentRoute}_sub`)
      );
      const newSubIndex = subRoutes.length + 1; // Obtener el siguiente número disponible
      setNewRouteName(`${parentRoute}_sub${newSubIndex}`);
    } else {
      // Contar cuántas rutas principales existen
      const mainRoutes = routes.filter((route) => route.startsWith("Ruta_"));
      const newIndex = mainRoutes.length + 1;
      setNewRouteName(`Ruta_${newIndex}`);
    }
  }, [isSubRoute, parentRoute, routes]);

  useEffect(() => {
    dataMergeRef.current = viewDataMerge;
  }, [viewDataMerge]);

  // Cuando cambia viewDataMerge, obtenemos la lista de rutas distintas:

  useEffect(() => {
    if (LocaDataMerge && LocaDataMerge.length > 0) {
      const existingRoutes = new Set();
      LocaDataMerge.forEach((order) => {
        const route = order.driverLicensePlate || "Sin Ruta";
        existingRoutes.add(route);
      });

      // 📌 Combinamos rutas generadas desde LocaDataMerge con las manuales
      setRoutes(Array.from(new Set([...existingRoutes, ...manualRoutes])));
    }
  }, [LocaDataMerge, manualRoutes]); // Ahora depende también de manualRoutes

  useEffect(() => {
    // Evitar actualización innecesaria
    if (!isFilterMode) {
      if (
        selectedOrders.length !== selectedMarkers.length ||
        !selectedOrders.every((id) => selectedMarkers.includes(id))
      ) {
        setSelectedMarkers(selectedOrders);
      }
    }
  }, [selectedOrders]);

  useEffect(() => {
    // Evitar actualización innecesaria
    if (
      selectedMarkers.length !== selectedOrders.length ||
      !selectedMarkers.every((id) => selectedOrders.includes(id))
    ) {
      setSelectedOrders(selectedMarkers);
    }
  }, [selectedMarkers]);

  // Asignamos colores a cada ruta
  useEffect(() => {
    const updatedRouteColors = {};
    let colorIndex = 0;
    const assignedColors = {}; // Almacenar colores por ruta principal

    routes.forEach((route) => {
      // 🔹 Extraer la ruta principal antes del primer "_"
      const match = route.match(/^Ruta_(\d+)/);
      const mainRoute = match ? `Ruta_${match[1]}` : route;

      // 🔹 Si la ruta principal ya tiene un color asignado, usarlo
      if (!assignedColors[mainRoute]) {
        assignedColors[mainRoute] =
          colorPalette[colorIndex % colorPalette.length];
        colorIndex++;
      }

      // 🔹 Asignar el mismo color a la ruta y sus subrutas
      updatedRouteColors[route] = assignedColors[mainRoute];
    });

    setRouteColors(updatedRouteColors);
  }, [routes]);

  const homeIcon = new L.divIcon({
    className: "custom-icon",
    html: "🏠", // Usa un emoji de casa o cambia por un icono SVG
    iconSize: [30, 30],
    iconAnchor: [15, 30],
  });

  // ------------------------------------------------------------------------
  //  Funciones para manejar las RUTAS (Agregar, Cambiar, etc.)
  // ------------------------------------------------------------------------
  // Función para generar el nombre de una nueva ruta principal
  const generateRouteName = (existingRoutes) => {
    const mainRoutes = existingRoutes
      .map((route) => route.match(/^Ruta_(\d+)/))
      .filter(Boolean)
      .map((match) => parseInt(match[1], 10));

    const newRouteNumber =
      mainRoutes.length > 0 ? Math.max(...mainRoutes) + 1 : 1;
    return `Ruta_${newRouteNumber}`;
  };

  // Función para generar el nombre de una nueva subruta
  const generateSubRouteName = (parentRoute, existingRoutes) => {
    const subRoutes = existingRoutes.filter((route) =>
      route.startsWith(`${parentRoute}_sub`)
    );
    return `${parentRoute}_sub${subRoutes.length + 1}`;
  };

  // Función para manejar el cambio en el Switch de subruta
  const handleSubRouteToggle = (event) => {
    setIsSubRoute(event.target.checked);

    if (event.target.checked) {
      // Filtrar solo rutas principales (evitar anidación de subrutas)
      const mainRoutes = routes.filter((route) => !route.includes("_sub"));
      setAvailableParentRoutes(mainRoutes);

      // Si hay rutas disponibles, seleccionar la primera por defecto
      if (mainRoutes.length > 0) {
        setParentRoute(mainRoutes[0]);
      } else {
        setParentRoute(""); // Si no hay rutas disponibles, limpiar
      }
    } else {
      setParentRoute(""); // Resetear al desactivar subruta
    }
  };

  // Función para manejar la selección de la ruta padre
  const handleParentRouteChange = (event) => {
    setParentRoute(event.target.value);
  };

  // Abrir diálogo y generar el nombre de la ruta automáticamente
  const handleOpenAddRouteDialog = () => {
    setOpenAddRouteDialog(true);
    setNewRouteName(generateRouteName(routes)); // Por defecto, nueva ruta principal
  };

  // Confirmar adición de nueva ruta
  const handleAddRouteConfirm = () => {
    if (newRouteName.trim() === "") {
      alert("Por favor, ingresa un nombre para la nueva ruta.");
      return;
    }
    if (routes.includes(newRouteName)) {
      alert("La ruta ya existe. Por favor, ingresa un nombre diferente.");
      return;
    }

    // 📌 Agregar la nueva ruta a `manualRoutes`
    setManualRoutes((prev) => [...prev, newRouteName]);

    // 📌 Agregar nueva ruta vacía a `reqApiOptimizationData` si no existe
    setReqApiOptimizationData((prevData) => {
      const alreadyExists = prevData.some(
        (route) => route.route === newRouteName
      );

      if (!alreadyExists) {
        return [
          ...prevData,
          { route: newRouteName, waypoints: [] }, // ✅ Ahora se agregará la ruta correctamente
        ];
      }
      return prevData; // Si ya existe, no la duplicamos
    });

    setIsSubRoute(false);
    setNewRouteName("");
    setOpenAddRouteDialog(false);
  };

  const handleAddRouteCancel = () => {
    setNewRouteName("");
    setOpenAddRouteDialog(false);
  };

  const handleChangeRoute = () => {
    if (selectedOrders.length === 0) {
      alert("Selecciona al menos una orden para mover.");
      return;
    }

    setOpenChangeRouteDialog(true);
    // Aquí podrías re-asignar los pedidos seleccionados a la ruta `targetRoute`
    // Ejemplo sencillo:

    // ...
  };

  const handleRemoveRoute = (route) => {
    if (!route) {
      alert("Selecciona una ruta para eliminar.");
      return;
    }

    // 1️⃣ Filtrar órdenes que pertenecen a la ruta eliminada (liberarlas)
    setViewDataMerge((prevData) =>
      prevData.map((order) =>
        order.driverLicensePlate === route
          ? { ...order, driverLicensePlate: "" } // Liberar la orden
          : order
      )
    );

    setLocalDataMerge((prevData) =>
      prevData.map((order) =>
        order.driverLicensePlate === route
          ? { ...order, driverLicensePlate: "" }
          : order
      )
    );

    // 2️⃣ Remover la ruta de `manualRoutes` si fue agregada manualmente
    setManualRoutes((prevRoutes) => prevRoutes.filter((r) => r !== route));

    // 3️⃣ Remover la ruta de la lista de rutas
    setRoutes((prevRoutes) => prevRoutes.filter((r) => r !== route));

    // 4️⃣ Eliminar la ruta de los datos de optimización (reqApiOptimizationData)
    setReqApiOptimizationData((prevData) =>
      prevData.filter((r) => r.route !== route)
    );

    // 5️⃣ Eliminar la geometría de la ruta
    setRouteGeometry((prev) =>
      prev.filter((geometry) => geometry.route !== route)
    );

    // 6️⃣ Eliminar waypoints optimizados relacionados con la ruta
    setOptimizedWaypoints((prev) =>
      prev.filter((waypoint) => waypoint.routeName !== route)
    );

    // 7️⃣ Eliminar métricas de la ruta eliminada
    setMetricsByRoutes((prevMetrics) => {
      const newMetrics = { ...prevMetrics };
      delete newMetrics[route];
      return newMetrics;
    });

    // 8️⃣ Limpiar la ruta seleccionada si se estaba eliminando
    setTargetRoute("");
  };

  const handleRemoveSelectedFromRoute = () => {
    if (selectedOrders.length === 0) {
      alert("Selecciona órdenes para liberar.");
      return;
    }

    // 1️⃣ Actualizar `viewDataMerge` y `dataMerge`, eliminando la ruta de las órdenes seleccionadas
    setViewDataMerge((prevData) =>
      prevData.map((order) =>
        selectedOrders.includes(order.id)
          ? { ...order, driverLicensePlate: null }
          : order
      )
    );

    setLocalDataMerge((prevData) =>
      prevData.map((order) =>
        selectedOrders.includes(order.id)
          ? { ...order, driverLicensePlate: null }
          : order
      )
    );

    // 2️⃣ Actualizar `reqApiOptimizationData` eliminando los waypoints correspondientes
    setReqApiOptimizationData((prevData) => {
      let updatedData = [...prevData];

      updatedData = updatedData.map((route) => {
        return {
          ...route,
          waypoints: route.waypoints.filter(
            (waypoint) => !selectedOrders.includes(waypoint.id) // Eliminar los waypoints seleccionados
          ),
        };
      });

      // 3️⃣ Recalcular los índices de los waypoints en cada ruta después de la eliminación
      updatedData = updatedData.map((route) => ({
        ...route,
        waypoints: route.waypoints.map((waypoint, index) => ({
          ...waypoint,
          index: index + 1, // Reasignar índices
        })),
      }));

      // 4️⃣ Eliminar rutas vacías después de la eliminación
      updatedData = updatedData.filter((route) => route.waypoints.length > 0);

      return updatedData;
    });

    // 5️⃣ Limpiar la selección de órdenes
    setSelectedOrders([]);
    setRouteGeometry([]);
    setOptimizedWaypoints([]);
  };

  const handleToggleRouteSelection = (route, orders) => {
    const allSelected = orders.every((order) =>
      selectedOrders.includes(order.id)
    );

    setSelectedOrders((prevSelected) => {
      let newSelectedOrders;

      if (allSelected) {
        // Si todas están seleccionadas, quitarlas del estado
        newSelectedOrders = prevSelected.filter(
          (id) => !orders.some((order) => order.id === id)
        );

        // Si se quitaron todas las órdenes de esta ruta, remover la ruta de `selectedRoutes`
        setSelectedRoutes((prevRoutes) =>
          prevRoutes.includes(route)
            ? prevRoutes.filter((r) => r !== route)
            : prevRoutes
        );
      } else {
        // Si no todas están seleccionadas, añadir todas
        newSelectedOrders = [
          ...prevSelected,
          ...orders
            .map((order) => order.id)
            .filter((id) => !prevSelected.includes(id)),
        ];

        // Asegurar que la ruta se añade a `selectedRoutes`
        setSelectedRoutes((prevRoutes) =>
          prevRoutes.includes(route) ? prevRoutes : [...prevRoutes, route]
        );
      }

      return newSelectedOrders;
    });
  };

  const handleOpenChangeRouteDialog = () => {
    if (selectedOrders.length === 0) {
      alert("Selecciona al menos una orden para mover.");
      return;
    }
    setOpenChangeRouteDialog(true);
  };

  const handleChangeRouteCancel = () => {
    setTargetRoute(""); // Limpiar selección
    setOpenChangeRouteDialog(false);
  };

  const handleChangeRouteConfirm = () => {
    if (!targetRoute) {
      alert("Selecciona una ruta de destino.");
      return;
    }

    setReqApiOptimizationData((prevData) => {
      let updatedData = [...prevData];

      // 1️⃣ Extraer waypoints seleccionados de su ruta original
      let waypointsToMove = [];
      updatedData = updatedData.map((route) => {
        const remainingWaypoints = route.waypoints.filter(
          (waypoint) => !selectedOrders.includes(waypoint.id)
        );

        const movedWaypoints = route.waypoints.filter((waypoint) =>
          selectedOrders.includes(waypoint.id)
        );

        // Guardar los waypoints que se van a mover
        waypointsToMove.push(...movedWaypoints);

        return { ...route, waypoints: remainingWaypoints };
      });

      // 2️⃣ Si la ruta no existe, agregarla con los waypoints movidos
      let existingRoute = updatedData.find(
        (route) => route.route === targetRoute
      );

      if (!existingRoute) {
        updatedData.push({
          vehicle: "driving",
          route: targetRoute,
          waypoints: [...waypointsToMove],
        });
      } else {
        // 3️⃣ Si la ruta ya existe, agregar los waypoints
        updatedData = updatedData.map((route) =>
          route.route === targetRoute
            ? { ...route, waypoints: [...route.waypoints, ...waypointsToMove] }
            : route
        );
      }

      // 4️⃣ Asegurar que los índices de los waypoints se recalculan correctamente
      updatedData = updatedData.map((route) => ({
        ...route,
        waypoints: route.waypoints.map((waypoint, index) => ({
          ...waypoint,
          index: index + 1,
        })),
      }));

      return updatedData;
    });

    // 5️⃣ Actualizar las órdenes en `viewDataMerge` y `LocalDataMerge`
    setLocalDataMerge((prevData) =>
      prevData.map((order) =>
        selectedOrders.includes(order.id)
          ? { ...order, driverLicensePlate: targetRoute }
          : order
      )
    );

    setViewDataMerge((prevData) =>
      prevData.map((order) =>
        selectedOrders.includes(order.id)
          ? { ...order, driverLicensePlate: targetRoute }
          : order
      )
    );

    // 🔹 Limpiar selección y cerrar el diálogo
    setSelectedOrders([]);
    setTargetRoute("");
    setRouteGeometry([]);
    setOpenChangeRouteDialog(false);
  };

  // ------------------------------------------------------------------------
  //  Funciones para manejar la SELECCIÓN de rutas
  // ------------------------------------------------------------------------
  const handleRouteExpansion = (route) => {
    setExpandedRoutes((prevExpanded) => {
      if (prevExpanded.includes(route)) {
        return prevExpanded.filter((r) => r !== route);
      } else {
        return [...prevExpanded, route];
      }
    });
  };

  const handleRouteSelection = (route) => {
    setSelectedRoutes((prev) =>
      prev.includes(route) ? prev.filter((r) => r !== route) : [...prev, route]
    );
  };
  // ------------------------------------------------------------------------
  //  Funciones para manejar la VISTA de rutas
  // ------------------------------------------------------------------------

  // ------------------------------------------------------------------------
  //  Funciones para filtrar órdenes (según searchQuery)
  // ------------------------------------------------------------------------
  const filterOrders = (orders) => {
    return orders.filter((order) =>
      Object.values(order).some((value) => {
        if (value !== null && value !== undefined) {
          return value
            .toString()
            .toLowerCase()
            .includes(searchQuery.toLowerCase());
        }
        return false;
      })
    );
  };

  // ------------------------------------------------------------------------
  //  Funciones para manejar órdenes (selección, doble clic, mouseOver, etc.)
  // ------------------------------------------------------------------------
  const handleOrderSelection = (orderId) => {
    setSelectedOrders((prevSelected) =>
      prevSelected.includes(orderId)
        ? prevSelected.filter((id) => id !== orderId)
        : [...prevSelected, orderId]
    );
  };

  const handleOrderDoubleClick = (orderId) => {
    // console.log("Doble clic en orden:", orderId);
  };

  const handleOrderMouseEnter = (orderId) => {
    //  console.log("Mouse sobre orden:", orderId);
  };

  const handleOrderMouseLeave = () => {
    //  console.log("Mouse fuera de la orden");
  };

  const handleOpenEditDialog = (order) => {
    setEditOrder({ ...order });
    setOpenEditDialog(true);
  };

  const handleCloseEditDialog = () => {
    setOpenEditDialog(false);
    setEditOrder(null);
  };

  const handleSaveEditedOrder = async () => {
    if (!editOrder || !editOrder.id) {
      console.log("⚠️ No hay orden para editar");
      return;
    }

    // Validar newLocation antes de continuar
    if (!newLocation || isNaN(newLocation.lat) || isNaN(newLocation.lng)) {
      return;
    }

    let newGeo = new GeoPoint(
      parseFloat(newLocation.lat),
      parseFloat(newLocation.lng)
    );
    const orderRef = doc(collectionRef, `${editOrder.id}`);

    try {
      await updateDoc(orderRef, { toAddressLocation: newGeo });

      // Actualizar estados locales solo si Firestore se actualizó correctamente
      const updatedOrders = dataMerge.map((o) =>
        o.id === editOrder.id ? { ...o, toAddressLocation: newGeo } : o
      );

      setViewDataMerge(updatedOrders);
      setLocalDataMerge(updatedOrders);

      console.count(
        "✅ Orden actualizada correctamente en Firestore y en el estado local."
      );
      handleCloseEditDialog();
    } catch (error) {
      console.error("❌ Error al actualizar la orden en Firestore:", error);
    }
  };

  // ------------------------------------------------------------------------
  //  Datos para el panel lateral (counts, etc.)
  // ------------------------------------------------------------------------
  const routeInfo = useMemo(() => {
    const packageCounts = {};
    const ordersByRoute = {};
    // Variables vienen de respuesta api se debe considerar que no existen
    const metricsByRoute = { totalDistance: "", totalTime: "" }; // totalDistance y totalTime metricsRoutes
    const waypointsByRoute = {}; // Detalles Paradas por ruta optimizedWaypoints
    const geometryByRoute = {}; // geometria routeGeometry

    if (LocaDataMerge && LocaDataMerge.length > 0) {
      LocaDataMerge.forEach((order) => {
        const route = order.driverLicensePlate || "Sin Ruta";
        if (!packageCounts[route]) {
          packageCounts[route] = 0;
        }
        packageCounts[route] += 1; // Suponiendo 1 bulto por orden
        if (!ordersByRoute[route]) {
          ordersByRoute[route] = [];
        }
        ordersByRoute[route].push(order);
      });
    }

    // Asegurar que existan entradas para rutas sin órdenes
    routes.forEach((route) => {
      if (!ordersByRoute[route]) {
        ordersByRoute[route] = [];
        packageCounts[route] = 0;
      }
    });

    return { packageCounts, ordersByRoute };
  }, [LocaDataMerge, routes]);

  // ------------------------------------------------------------------------
  //  Manejadores para Leaflet Draw (crear, editar, borrar figuras)
  // ------------------------------------------------------------------------
  const handleCreated = (e) => {
    if (!dataMergeRef.current || dataMergeRef.current.length === 0) return;

    const newShape = e.layer.toGeoJSON();
    setDrawnItems((prev) => [...prev, newShape]);

    let polygonCoords = newShape.geometry.coordinates[0];

    // Validar y cerrar polígono si hace falta
    if (polygonCoords.length < 3) return;
    const firstPoint = polygonCoords[0];
    const lastPoint = polygonCoords[polygonCoords.length - 1];
    if (firstPoint[0] !== lastPoint[0] || firstPoint[1] !== lastPoint[1]) {
      polygonCoords.push(firstPoint);
    }

    try {
      const polygon = turf.polygon([polygonCoords]);
      let selectedIds = new Set();

      dataMergeRef.current.forEach((marker) => {
        if (!marker.toAddressLocation) return;
        const position = [
          parseFloat(marker.toAddressLocation._lat),
          parseFloat(marker.toAddressLocation._long),
        ];
        if (isNaN(position[0]) || isNaN(position[1])) return;
        const markerPoint = turf.point([position[1], position[0]]);
        if (turf.booleanPointInPolygon(markerPoint, polygon)) {
          selectedIds.add(marker.id);
        }
      });
      setSelectedMarkers(Array.from(selectedIds));
    } catch (error) {
      console.error("Error al crear el polígono con Turf.js:", error);
    }
  };

  const handleEdited = (e) => {
    const editedShapes = e.layers.toGeoJSON();
    let selectedIds = new Set();

    for (let n = 0; n < editedShapes.features.length; n++) {
      let polygonCoords = editedShapes.features[n].geometry.coordinates[0];
      if (polygonCoords.length < 3) continue;
      const firstPoint = polygonCoords[0];
      const lastPoint = polygonCoords[polygonCoords.length - 1];
      if (firstPoint[0] !== lastPoint[0] || firstPoint[1] !== lastPoint[1]) {
        polygonCoords.push(firstPoint);
      }

      try {
        const polygon = turf.polygon([polygonCoords]);
        dataMergeRef.current.forEach((marker) => {
          if (!marker.toAddressLocation) return;
          const position = [
            parseFloat(marker.toAddressLocation._lat),
            parseFloat(marker.toAddressLocation._long),
          ];
          if (isNaN(position[0]) || isNaN(position[1])) return;
          const markerPoint = turf.point([position[1], position[0]]);
          if (turf.booleanPointInPolygon(markerPoint, polygon)) {
            selectedIds.add(marker.id);
          }
        });
      } catch (error) {}
    }
    setSelectedMarkers(Array.from(selectedIds));
  };

  const handleDeleted = (e) => {};

  // ------------------------------------------------------------------------
  //  Filtrar las rutas a mostrar si está activo "isFilterMode"
  // ------------------------------------------------------------------------
  useEffect(() => {
    if (isFilterMode) {
      console.count("🔍 Modo filtro activado. Filtrando órdenes...");

      if (selectedRoutes.length > 0) {
        // Filtrar órdenes solo de rutas seleccionadas
        setViewDataMerge(
          LocaDataMerge.filter((order) =>
            selectedRoutes.includes(order.driverLicensePlate)
          )
        );
      } else {
        // Si no hay rutas seleccionadas, mantener todas las órdenes
        setViewDataMerge(LocaDataMerge);
      }
    } else {
      setViewDataMerge(LocaDataMerge);
    }
  }, [isFilterMode, selectedRoutes, LocaDataMerge]);

  const filteredRoutes = useMemo(() => {
    // Mostramos todas las rutas en el panel lateral sin afectarlas
    return routes;
  }, [isFilterMode, routes]);

  // ------------------------------------------------------------------------
  //  Manu de herramientas de Optimizacion
  // ------------------------------------------------------------------------
  const handleSelectModeChange = (mode) => {
    //setSelectMode(mode);
  };

  const exportarHojaRutasActualizaciones = async (routeInfo) => {
    const currentURL = window.location.pathname;
    const prefix = currentURL === "/dashboard/map-plans" ? "" : "F";
    const routeOrders = Object.entries(routeInfo.ordersByRoute).flatMap(
      ([route, orders]) =>
        orders.map((order) => ({
          driverLicensePlate: order.driverLicensePlate || "SIN-RUTA",
          orderNumber: order.orderNumber !== undefined ? order.orderNumber : 1,
          id: order.id || "",
        }))
    );

    const matrizReporte = [
      ["driverLicensePlate", "orderNumber", "PREFIX", "BICCIID"],
      ...routeOrders.map((dato) => [
        dato.driverLicensePlate,
        dato.orderNumber,
        prefix,
        dato.id,
      ]),
    ];

    const libro = XLSX.utils.book_new();
    const hoja = XLSX.utils.aoa_to_sheet(matrizReporte);
    XLSX.utils.book_append_sheet(libro, hoja, "Plan");

    XLSX.writeFile(
      libro,
      `Reporte de rutas ${moment(Date.now()).format(
        "YYYY-MM-DD HH_mm_ss"
      )}.xlsx`
    );
  };

  // console.log(routes);

  // 3. Genera los documentos de ruta
  const documentsToSend = buildRouteDocuments({
    routes,
    routeInfo,
    reqApiOptimizationData,
    routeGeometry,
    optimizedWaypoints,
    metricsRoutes,
    companyID: companyID || "MiEmpresaSA",
    userCreator: userdata?.uid || "User_XYZ",
    routeColors,
  });

  console.log("MONITOR TODO:", documentsToSend);

  const sizeBytes = new Blob([JSON.stringify(documentsToSend[0])]).size;
  const sizeKB = (sizeBytes / 1024).toFixed(2);
  const sizeMB = (sizeKB / 1024).toFixed(2);

  // console.log(`Tamaño del objeto: ${sizeBytes} bytes (${sizeKB} KB / ${sizeMB} MB)`);

  //FORZAR INDICE

  // Llamar la función con los documentos generados
  // saveRoutesToFirestore(routeDocuments);

  //recibidos
  // console.log("dataMerge", dataMerge);
  // console.log("ridersDataByCompany", ridersDataByCompany);
  // console.log("companyID", companyID);
  // console.log("loading", loading);
  // console.log("userdata", userdata);
  // console.log("contextReq", contextReq);
  // console.log(polygons);

  // if (documentsToSend.length > 0) {
  //   console.log(documentsToSend[0]);
  //   console.log(documentsToSend[0].waypoints);
  //   console.log(documentsToSend[0].waypointsOutRoute);
  //   console.log(documentsToSend[0].notifications);
  // }

  // console.log("routeInfo", routeInfo);
  // console.log("reqApiOptimizationData", reqApiOptimizationData);
  // console.log("routeGeometry", routeGeometry);
  // console.log("optimizedWaypoints", optimizedWaypoints);
  // console.log("metricsRoutes", metricsRoutes);
  // console.log("routeColors", routeColors);
  // const document = createDocumentRoute();
  // console.log(document);

  for (const item of routes) {
    // console.log(item);
    // console.log(routeInfo.ordersByRoute[item]);
    // console.log(routeInfo.packageCounts[item]);
    for (const routeOptimizations of reqApiOptimizationData) {
      if (routeOptimizations.route === item) {
      }
    }
    let geometries = [];
    for (const geometry of routeGeometry) {
      if (geometry.route === item) {
        geometries.push(geometry);
      }
    }
    let Waypoints = [];
    for (const waypoint of optimizedWaypoints) {
      if (waypoint.routeName === item) {
        Waypoints.push(waypoint);
      }
    }
    // console.log(Waypoints);
    // console.log("geometries", geometries);
    // console.log(metricsRoutes[item]);
  }

  /**
import { collectionGroup, query, where, getDocs } from "firebase/firestore";
import { db } from "./firebase";

/**
 * 📌 Encuentra la ruta a partir de un orderID buscando en la subcolección "waypoints"


export const findRouteByOrderID = async (orderID) => {
  try {
    // 🔹 Buscar en la subcolección "waypoints" de todas las rutas
    const waypointsQuery = query(
      collectionGroup(db, "waypoints"), // 🔥 Buscar en todas las subcolecciones llamadas "waypoints"
      where("ids", "array-contains", orderID)
    );

    const snapshot = await getDocs(waypointsQuery);
    if (snapshot.empty) {
      console.warn("⚠️ No se encontró el waypoint con el orderID:", orderID);
      return null;
    }

    // 🔹 Obtener el ID de la ruta y del waypoint
    const waypointDoc = snapshot.docs[0]; // Tomamos el primer resultado
    const routeID = waypointDoc.ref.parent.parent.id; // ID de la ruta
    const waypointID = waypointDoc.id; // ID del waypoint

    return { routeID, waypointID };
  } catch (error) {
    console.error("❌ Error buscando la ruta por orderID:", error);
    return null;
  }
};

// 🔹 Uso:
findRouteByOrderID("99M026594845CL").then((result) =>
  console.log("📌 Ruta y Waypoint encontrados:", result)
);

  
  */

  console.log("Padre", optimizedWaypoints);

  return (
    <Box display="flex" height="100%" position="relative">
      <MapPanelLateral
        // Botones para agregar/cambiar ruta
        handleAddRoute={handleOpenAddRouteDialog}
        handleChangeRoute={handleChangeRoute}
        // Selector de ruta de destino
        targetRoute={targetRoute}
        setTargetRoute={setTargetRoute}
        // Listado de rutas
        routes={routes}
        // Búsqueda
        searchQuery={searchQuery}
        setSearchQuery={setSearchQuery}
        // Switch de modo filtro
        isFilterMode={isFilterMode}
        setIsFilterMode={setIsFilterMode}
        // Rutas filtradas
        filteredRoutes={filteredRoutes}
        // Info para mostrar pedidos por ruta
        routeInfo={routeInfo}
        expandedRoutes={expandedRoutes}
        handleRouteExpansion={handleRouteExpansion}
        selectedRoutes={selectedRoutes}
        setSelectedRoutes={setSelectedRoutes}
        handleRouteSelection={handleRouteSelection}
        // Colores
        routeColors={routeColors}
        // Filtrar pedidos
        filterOrders={filterOrders}
        // Selección de órdenes
        selectedOrders={selectedOrders}
        setSelectedOrders={setSelectedOrders}
        handleOrderDoubleClick={handleOrderDoubleClick}
        handleOrderSelection={handleOrderSelection}
        handleOrderMouseEnter={handleOrderMouseEnter}
        handleOrderMouseLeave={handleOrderMouseLeave}
        handleOpenEditDialog={handleOpenEditDialog}
        // Traductor de estados
        translateStatesfalabbela={translateStatesfalabbela}
        translateStatesBicciFilter={translateStatesBicciFilter}
        // Dialogos para cambiar Folios de Rutas
        handleToggleRouteSelection={handleToggleRouteSelection}
        handleOpenChangeRouteDialog={handleOpenChangeRouteDialog}
        handleChangeRouteCancel={handleChangeRouteCancel}
        handleChangeRouteConfirm={handleChangeRouteConfirm}
        openChangeRouteDialog={openChangeRouteDialog}
        setOpenChangeRouteDialog={setOpenChangeRouteDialog}
        handleRemoveSelectedFromRoute={handleRemoveSelectedFromRoute}
        handleRemoveRoute={handleRemoveRoute}
        metricsRoutes={metricsRoutes}
        setSelectedViewRoutes={setSelectedViewRoutes}
        selectedViewRoutes={selectedViewRoutes}
        optimizedWaypoints={
          optimizedWaypoints && optimizedWaypoints.length > 0 ? true : false
        }
      />

      <MapMenuFlotante
        // Data en edicio
        dataMerge={dataMerge}
        viewDataMerge={viewDataMerge}
        polygons={polygons}
        //Ordenes seleccionas
        selectedOrders={selectedOrders}
        //Actualizadores de vista
        setViewDataMerge={setViewDataMerge}
        setLocalDataMerge={setLocalDataMerge}
        setSelectedRoutes={setSelectedRoutes}
        setTargetRoute={setTargetRoute}
        setSelectedOrders={setSelectedOrders}
        // otros
        isChangeMode={statesRoute.isChangeMode}
        setIsChangeMode={statesRoute.setIsChangeMode}
        handleClosePlansDialog={handleClosePlansDialog}
        handleSelectPlan={handleSelectPlan}
        handlePrevPage={handlePrevPage}
        handleNextPage={handleNextPage}
        handleOpenFilterDialog={handleOpenFilterDialog}
        handleCancelFilter={handleCancelFilter}
        handleApplyFilter={handleApplyFilter}
        handleOpenOptimizeDialog={handleOpenOptimizeDialog}
        handleCancelOptimize={handleCancelOptimize} // Se llama el handler y se envia con el metodo de state.
        handleApplyOptimize={handleApplyOptimize}
        handleOpenSaveDialog={handleOpenSaveDialog}
        handleCancelSave={handleCancelSave}
        handleSaveDocument={handleSaveDocument}
        plans={statesRoute.plans}
        openPlansDialog={statesRoute.openPlansDialog}
        currentPlans={statesRoute.currentPlans}
        currentPage={statesRoute.currentPage}
        endIndex={statesRoute.endIndex}
        availableStatuses={availableStatuses}
        selectedStatuses={statesRoute.selectedStatuses}
        setSelectedStatuses={statesRoute.setSelectedStatuses}
        // estados
        translateStatesfalabbela={translateStatesfalabbela}
        translateStatesBicciFilter={translateStatesBicciFilter}
        // estados
        setOpenOptimizeDialog={statesRoute.setOpenOptimizeDialog}
        openFilterDialog={statesRoute.openFilterDialog}
        openOptimizeDialog={statesRoute.openOptimizeDialog}
        openSaveDialog={statesRoute.openSaveDialog}
        setOpenPlansDialog={statesRoute.setOpenPlansDialog}
        documentData={statesRoute.documentData}
        setDocumentData={statesRoute.setDocumentData}
        ridersDataByCompany={ridersDataByCompany}
        routes={routes}
        exportarHojaRutasActualizaciones={exportarHojaRutasActualizaciones}
        companyID={companyID}
        setOpenFilterDialog={statesRoute.setOpenFilterDialog}
        setOpenSaveDialog={statesRoute.setOpenSaveDialog}
        routeInfo={routeInfo}
        loading={loading}
        setLoading={setLoading}
        setOpenRuoterDialog={setOpenRuoterDialog}
        openRuoterDialog={openRuoterDialog}
        reqApiOptimizationData={reqApiOptimizationData}
        setReqApiOptimizationData={setReqApiOptimizationData}
        // Paradas
        optimizedWaypoints={optimizedWaypoints}
        setOptimizedWaypoints={setOptimizedWaypoints}
        // Rutas
        routeGeometry={routeGeometry}
        setRouteGeometry={setRouteGeometry}
        setMetricsByRoutes={setMetricsByRoutes}
        routeDetails={routeDetails}
        setRouteDetails={setRouteDetails}
        documentsToSend={documentsToSend}
        contextReq={contextReq}
      ></MapMenuFlotante>

      {/* Diálogo para agregar ruta */}
      <Dialog open={openAddRouteDialog} onClose={handleAddRouteCancel}>
        <DialogTitle>Agregar Nueva Ruta</DialogTitle>
        <DialogContent>
          <Box>
            {/* Switch para elegir si es una subruta */}
            <FormControlLabel
              control={
                <Switch checked={isSubRoute} onChange={handleSubRouteToggle} />
              }
              label="Es una Subruta"
            />

            {/* Select para elegir la ruta padre si es una subruta */}
            {isSubRoute && (availableParentRoutes?.length ?? 0) > 0 && (
              <FormControl fullWidth margin="dense">
                <InputLabel>Seleccionar Ruta Padre</InputLabel>
                <Select value={parentRoute} onChange={handleParentRouteChange}>
                  {availableParentRoutes?.map((route, index) => (
                    <MenuItem key={index} value={route}>
                      {route}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
            {/* Nombre de la ruta generado automáticamente */}
            <TextField
              margin="dense"
              label="Nombre de la Ruta"
              fullWidth
              value={newRouteName}
              InputProps={{
                readOnly: true, // Bloquear edición
              }}
            />
          </Box>
        </DialogContent>

        <DialogActions>
          <Button onClick={handleAddRouteCancel} color="primary">
            Cancelar
          </Button>
          <Button onClick={handleAddRouteConfirm} color="primary">
            Agregar
          </Button>
        </DialogActions>
      </Dialog>

      {/* Contenedor del Mapa */}
      <Box flexGrow={1}>
        <MapContainer
          center={center}
          zoom={zoom}
          style={{ height: "70vh", width: "100%" }}
          ref={mapRef}
        >
          <TileLayer
            attribution={routeManager.attributionBICCI}
            url={routeManager.urlGrey}
          />
          {polygons ? <StaticPolygonsLayer mapVectoriales={polygons} /> : null}
          <FeatureGroup>
            <EditControl
              position="topright"
              onCreated={handleCreated}
              onEdited={handleEdited}
              onDeleted={handleDeleted}
              draw={{
                rectangle: true,
                polygon: true,
                circle: false,
                marker: false,
                polyline: false,
              }}
            />
          </FeatureGroup>
          {/* Marcadores de órdenes */}
          {viewDataMerge.map((order, index) => {
            if (!order.toAddressLocation) return null;
            const position = [
              parseFloat(order.toAddressLocation._lat),
              parseFloat(order.toAddressLocation._long),
            ];
            if (isNaN(position[0]) || isNaN(position[1])) return null;

            const route = order.driverLicensePlate || "Sin Ruta";
            const color = routeColors[route]
              ? route === "Sin Ruta"
                ? "grey"
                : routeColors[route]
              : "blue";
            const isSelected = selectedMarkers.includes(order.id);

            return (
              <Marker
                key={index}
                position={position}
                icon={routeManager.createSVGIcon(isSelected ? "white" : color)}
                eventHandlers={{
                  click: () => {
                    // Al hacer click en un marcador, marcamos el pedido
                    setSelectedMarkers((prev) =>
                      prev.includes(order.id)
                        ? prev.filter((id) => id !== order.id)
                        : [...prev, order.id]
                    );
                  },
                }}
              >
                <Popup>
                  <Typography variant="caption" display="block">
                    <strong>Orden ID:</strong> {order.id} <br />
                    <strong>Ruta:</strong> {route} | Parada: {order.orderNumber}
                    <br /> <strong>Dirección:</strong>{" "}
                    {order.toAddress || "Sin dirección"}
                  </Typography>
                </Popup>
              </Marker>
            );
          })}
          {/* Dibujar la ruta */}

          {routeGeometry && routeGeometry.length > 0 ? (
            <>
              {routeGeometry
                //.filter((segment) => selectedViewRoutes.includes(segment.route))
                .map((segment, index) => {
                  const routeName = segment.route || `Ruta_${index + 1}`;
                  const color = routeColors[routeName] || "blue";

                  return (
                    <Polyline
                      key={`${routeName}-${index}`}
                      positions={segment.geometry.map(([lon, lat]) => [
                        lat,
                        lon,
                      ])}
                      color={color} // Color principal
                      weight={3} // Grosor de la línea
                      opacity={0.7} // Transparencia para mejor visibilidad
                      lineCap="round" // Bordes más suaves en las curvas
                      stroke={true} // Asegura que tenga borde
                      fillColor={color} // Color de relleno (puede ayudar a simular borde)
                      fillOpacity={1} // Hace que el borde azul sea visible
                    >
                      <Popup>
                        <Typography variant="caption" display="block">
                          <strong>Ruta:</strong> {routeName} <br />
                        </Typography>
                      </Popup>
                    </Polyline>
                  );
                })}
            </>
          ) : null}

          {optimizedWaypoints
            .filter((wp) => selectedViewRoutes.includes(wp.routeName)) // Filtrar por rutas seleccionadas
            .map((wp, index) =>
              index === 0 ? (
                <Marker
                  key={`home-${index}`}
                  position={[wp.lat, wp.lon]}
                  icon={homeIcon}
                >
                  <Tooltip permanent direction="top">
                    🏡 Origen
                  </Tooltip>
                </Marker>
              ) : (
                <CircleMarker key={index} center={[wp.lat, wp.lon]} radius={0}>
                  <Tooltip permanent direction="top">
                    {index === optimizedWaypoints.length - 1
                      ? "Destino"
                      : `P:${wp.index} x ${
                          wp.ids.length > 1 ? wp.ids.length : "1"
                        } 📦`}
                  </Tooltip>
                </CircleMarker>
              )
            )}

          {/*           

          */}
          {/* : ${wp.ids.length > 1 ? wp.ids.length : "1"} 📦`} */}
        </MapContainer>
      </Box>

      {/* Diálogo Editar Orden */}
      <Dialog
        open={openEditDialog}
        onClose={handleCloseEditDialog}
        sx={{
          minwidth: 700,
          width: 700,
          height: 700,
          top: "0px",
          right: "opx",
        }}
      >
        <DialogTitle>Editar Orden {editOrder?.id}</DialogTitle>
        <DialogContent>
          {editOrder && (
            <>
              {/* Dirección principal */}
              <Typography
                fontFamily="Lato, Arial, Helvetica, sans-serif"
                color="textPrimary"
                gutterBottom
                variant="h6"
                sx={{ marginTop: 2, marginBottom: 1 }}
              >
                {editOrder.toAddress}
              </Typography>

              {/* Información de georeferencia actual */}
              <Typography
                fontFamily="Lato, Arial, Helvetica, sans-serif"
                color="textPrimary"
                gutterBottom
                variant="body2"
                sx={{ marginTop: 2, marginBottom: 1 }}
              >
                Ajustar Geo referencia de entrega:
              </Typography>

              {editOrder.toAddressLocation ? (
                <Typography variant="body2" gutterBottom>
                  <strong>Geo referencia actual:</strong>
                  <div>
                    Lat:{" "}
                    {editOrder.toAddressLocation.latitude ??
                      editOrder.toAddressLocation._lat ??
                      "No disponible"}
                    , Lng:{" "}
                    {editOrder.toAddressLocation.longitude ??
                      editOrder.toAddressLocation._long ??
                      "No disponible"}
                  </div>
                </Typography>
              ) : (
                <Typography variant="body2" gutterBottom>
                  No hay georeferencia actual.
                </Typography>
              )}

              {/* Nueva georeferencia */}
              {newLocation && newLocation.lat && (
                <Box mt={2}>
                  <Typography variant="body2">
                    <strong>Nueva Geo referencia Seleccionada:</strong>
                  </Typography>
                  <Typography
                    fontFamily="Lato, Arial, Helvetica, sans-serif"
                    color="textPrimary"
                    gutterBottom
                    variant="h6"
                    sx={{ marginTop: 2, marginBottom: 1 }}
                  >
                    {editOrder.toAddress}
                  </Typography>
                  <Box display="flex" gap={2} mt={1}>
                    <TextField
                      label="Latitud"
                      fullWidth
                      margin="dense"
                      value={newLocation.lat.toString()}
                      onChange={(e) => {
                        const newLat = parseFloat(e.target.value) || 0;
                        setNewLocation((prev) => ({ ...prev, lat: newLat }));
                        setEditOrder((prev) => ({
                          ...prev,
                          toAddressLocation: {
                            ...prev.toAddressLocation,
                            latitude: newLat,
                          },
                        }));
                      }}
                    />

                    <TextField
                      label="Longitud"
                      fullWidth
                      margin="dense"
                      value={newLocation.lng.toString()}
                      onChange={(e) => {
                        const newLng = parseFloat(e.target.value) || 0;
                        setNewLocation((prev) => ({ ...prev, lng: newLng }));
                        setEditOrder((prev) => ({
                          ...prev,
                          toAddressLocation: {
                            ...prev.toAddressLocation,
                            longitude: newLng,
                          },
                        }));
                      }}
                    />
                  </Box>

                  <Button
                    sx={{
                      backgroundColor: "#591E8F",
                      color: "#fff",
                      marginTop: "10px",
                      ":hover": {
                        backgroundColor: "#25b2f4 !important",
                        color: "#591e8f",
                      },
                    }}
                    variant="contained"
                    onClick={() => {
                      setEditOrder((prev) => ({
                        ...prev,
                        toAddressLocation: {
                          latitude: newLocation.lat,
                          longitude: newLocation.lng,
                        },
                      }));
                    }}
                  >
                    Guardar Geo Referencia
                  </Button>
                </Box>
              )}

              {/* Componente para seleccionar nueva georeferencia */}
              <Box mt={2}>
                <SelectorGeoRecf
                  markersPending={editOrder.toAddressLocation}
                  setNewLocation={setNewLocation}
                />
              </Box>
            </>
          )}
        </DialogContent>

        <DialogActions>
          <Button onClick={handleCloseEditDialog} color="primary">
            Cancelar
          </Button>
          <Button onClick={handleSaveEditedOrder} color="primary">
            Guardar
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default MapComponentDistributionsLeaflet;
