import React, { useState } from "react";
import { OptimizeDialog } from "./dialogs";
import { Button, CircularProgress } from "@mui/material";
import { haversineDistance } from "./UtilsOptimize/haversineDistance";
import { routeInfo } from "./UtilsOptimize/routeInfo";
import { buildOptimizationPayload } from "./UtilsOptimize/buildOptimizationPayload";
import { kmeans } from "./UtilsOptimize/kmeans";
import { shouldOptimize } from "./UtilsOptimize/shouldOptimize";
import { assignOrdersToPolygons } from "./UtilsOptimize/assignOrdersToPolygons";
import { Snackbar, Alert } from "@mui/material";

const ButonOptimizeDialog = ({
  dataMerge,
  selectedOrders,
  routes,
  polygons,
  setLoading,
  loading,
  // Actualizar `viewDataMerge` y `dataMerge` eliminando la ruta de las órdenes seleccionadas
  setViewDataMerge,
  setLocalDataMerge,
  optimizeOptions,
  setOptimizeOptions,
  setReqApiOptimizationData,
  setRouteGeometry,
  setOptimizedWaypoints,
  setMetricsByRoutes,
}) => {
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const [openOptimizeDialog, setOpenOptimizeDialog] = useState(false);

  const handleCancelOptimize = () => {
    setOpenOptimizeDialog(false);
  };

  const handleApplyOptimize = async () => {
    setRouteGeometry([]);
    setOptimizedWaypoints([]);
    setMetricsByRoutes([]);
    setLoading(true);

    //ORIGEN

    //DESTINO
    let simularValorDestinoIngresado = [-33.3670325, -70.5025386];
    let destination; // destino mas lejano
    let origin; // retornar a origen

    // Función de validación (NO debe contener lógica de K-Means ni de API)
    // 1) Ejecutar validación antes de continuar
    const isValid = shouldOptimize(optimizeOptions);
    if (!isValid) {
      setLoading(false);
      return;
    }

    // 2) Si pasa la validación, ejecutamos la lógica de optimización local (K-Means)
    try {
      // Filtramos las órdenes a optimizar
      const ordersToOptimize =
        optimizeOptions.selector === "Todos"
          ? dataMerge
          : dataMerge.filter((order) => selectedOrders.includes(order.id));

      // Convertimos datos a {id, lat, lng}
      const dataToSend = ordersToOptimize
        .filter(
          (o) =>
            o.toAddressLocation &&
            typeof o.toAddressLocation._lat === "number" &&
            typeof o.toAddressLocation._long === "number"
        )
        .map((order) => ({
          id: order.id,
          lat: parseFloat(order.toAddressLocation._lat),
          lng: parseFloat(order.toAddressLocation._long),
        }));

      // Si no hay órdenes con coordenadas válidas, cerramos
      if (dataToSend.length === 0) {
        setLoading(false);
        setOpenOptimizeDialog(false);
        return;
      }

      // Calculamos cuántas rutas necesitamos
      let totalOrders = dataToSend.length;
      let numberOfRoutes;

      if (optimizeOptions.createRequireAgrupations) {
        // Si se crean agrupaciones automáticamente
        if (optimizeOptions.createRequireVehicles) {
          // Y también se crean vehículos automáticamente buscar lo optimo

          numberOfRoutes = Math.ceil(
            totalOrders / 80 //parseInt(optimizeOptions.agrupationCount)
          );
        } else {
          // Si no, se basa en la cantidad en total de ordenes entre vehiculos

          numberOfRoutes = parseInt(optimizeOptions.vehicleCount);
        }
      } else {
        // Si no se crean agrupaciones automáticamente

        if (optimizeOptions.createRequireVehicles) {
          numberOfRoutes = Math.ceil(
            totalOrders / parseInt(optimizeOptions.agrupationCount)
          );
        } else {
          numberOfRoutes = Math.ceil(totalOrders / 60);
        }
      }

      // Ejecutar K-Means local para la distribución
      // Donde 100 es el número de iteraciones o el criterio de convergencia.

      const { assignments } = kmeans(dataToSend, numberOfRoutes, 100);

      // Creamos un array para agrupar las IDs de cada cluster
      let clusters = [];
      let updatedOrders = [];
      let routeCounter = 1;
      let ordersByRoute;
      let ordersByPolygon = {};
      let poligonsData = polygons;
      let routesInPolygon;
      const MAX_ORDERS_PER_ROUTE = 100;

      try {
        if (optimizeOptions.applySectorization) {
          if (!poligonsData) {
            alert("Debe cargar algun poligono antes de continuar.");
            setOpenOptimizeDialog(false);
            setLoading(false);
          }

          ordersByPolygon = await assignOrdersToPolygons(
            ordersToOptimize,
            poligonsData,
            optimizeOptions
          );
        } else {
          ordersByPolygon["General"] = ordersToOptimize;
        }
      } catch (error) {}

      if (optimizeOptions.applySectorization) {
        Object.entries(ordersByPolygon).forEach(([polygonName, orders]) => {
          if (!ordersByPolygon || typeof ordersByPolygon !== "object") {
            console.error(
              "❌ Error: `ordersByPolygon` no es un objeto válido."
            );
          }

          if (optimizeOptions.keepSectorsSeparated) {
            try {
              // 🚀 Cada polígono es una única ruta
              const routeName = `Ruta_${routeCounter++}`;
              orders.forEach((order) => {
                updatedOrders.push({
                  ...order,
                  driverLicensePlate: routeName,
                });
              });

              ordersByRoute[routeName] = orders;
            } catch (error) {}
          }

          if (!optimizeOptions.keepSectorsSeparated) {
            try {
              const routeName = `Ruta_${routeCounter++}`;
              // 🚀 Cada polígono es una única ruta
              orders.forEach((order) => {
                updatedOrders.push({
                  ...order,
                  driverLicensePlate: routeName,
                });
              });

              ordersByRoute[routeName] = orders;
            } catch (error) {}
          }
        });
      }

      if (!optimizeOptions.applySectorization) {
        clusters = Array.from({ length: numberOfRoutes }, () => []);

        // Asignamos las órdenes a cada cluster resultante
        assignments.forEach((clusterIndex, i) => {
          clusters[clusterIndex].push(dataToSend[i].id);
        });

        //SUBDIVICION DE RUTAS GRANDES
        //subDivicion Si clusters[n] > 98 se debe reperir el ciclo externamente crear y traer nuevos clusters actualiar el updatedOrders `Ruta_${foundCluster + 1}_R_${Fondsub+1}`

        updatedOrders = dataMerge.map((order) => {
          const foundCluster = clusters.findIndex((cluster) =>
            cluster.includes(order.id)
          );
          if (foundCluster >= 0) {
            return { ...order, driverLicensePlate: `Ruta_${foundCluster + 1}` };
          }
          return order;
        });
        ordersByRoute = await routeInfo(updatedOrders, routes); // genera un nuevo archivo de routesInfo deonde estan las rutas actuales
      }
      // Actualizamos el campo driverLicensePlate en 'dataMerge'

      // creo que hay un error
      const optimizationData = await buildOptimizationPayload(
        optimizeOptions,
        updatedOrders,
        routes
      );

      setReqApiOptimizationData(optimizationData);

      // 3) Simulamos llamada a la API con retraso

      // Actualizar `viewDataMerge` y `dataMerge` en vista principal
      setViewDataMerge((prevData) =>
        prevData.map(
          (order) =>
            updatedOrders.find((updated) => updated.id === order.id) || order
        )
      );

      setLocalDataMerge((prevData) =>
        prevData.map(
          (order) =>
            updatedOrders.find((updated) => updated.id === order.id) || order
        )
      );

      setTimeout(() => {
        setOpenOptimizeDialog(false);

        setLoading(false);
        setOpenSnackbar(true);
      }, 3000);

      // Final
    } catch (error) {
      alert("Ocurrió un error al optimizar las rutas.");
      setLoading(false);
    }
  };

  return (
    <>
      <Button onClick={() => setOpenOptimizeDialog(true)} disabled={loading}>
        {loading ? <CircularProgress size={24} /> : "Optimizar y agrupar"}
      </Button>
      <OptimizeDialog
        dataMerge={dataMerge}
        openOptimizeDialog={openOptimizeDialog}
        handleCancelOptimize={handleCancelOptimize}
        handleApplyOptimize={handleApplyOptimize}
        optimizeOptions={optimizeOptions}
        setOptimizeOptions={setOptimizeOptions}
      />
      <Snackbar
        open={openSnackbar}
        autoHideDuration={10000} // Se cierra automáticamente en 5 segundos
        onClose={() => setOpenSnackbar(false)}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert severity="success" onClose={() => setOpenSnackbar(false)}>
          Órdenes optimizadas y reasignadas a las rutas creadas localmente.
        </Alert>
      </Snackbar>
    </>
  );
};

export default ButonOptimizeDialog;
