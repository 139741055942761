import React, { useState, useContext, useEffect, useCallback } from "react";
import { ScreenSizeContext } from "../../../context/ScreenSizeProvider";
import { Grid, Tooltip, Typography } from "@mui/material";
import {
  Card,
  Divider,
  CardHeader,
  LinearProgress,
  Box,
  IconButton,
  Button,
} from "@mui/material";
import {
  Combobox,
  ComboboxInput,
  ComboboxPopover,
  ComboboxList,
  ComboboxOption,
} from "@reach/combobox";
import { db } from "../../../firebase";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import ButtonGroup from "@mui/material/ButtonGroup";
import { makeStyles } from "@mui/styles";
import { doc, deleteDoc } from "firebase/firestore";
import Moment from "moment";
import useGetDataRiderByCompany from "../../../hooks/getDataRiderByCompany";
import { createOrderBicciClientCompanyBicciexpress } from "../../../redux/accionesOrders";
import QrCode2Icon from "@mui/icons-material/QrCode2";
import DirectionsBikeIcon from "@mui/icons-material/DirectionsBike";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import SystemUpdateAltIcon from "@mui/icons-material/SystemUpdateAlt";
import { useDispatch, useSelector } from "react-redux";
import ToolDashAppBarCompanyOps from "../../../components/Dashboard/dashboard/ToolDashAppBarCompanyOps/ToolDashAppBarCompanyOps";
import generarReporteProformas from "../../../utils/Reports/generarReporteProformas";
import DocumentoPDFShippingLabelPrint from "../../../components/DocumentoPDFShippingLabelPrint/DocumentoPDFShippingLabelPrint";
import { generateBarcodeBase64 } from "../../../components/DocumentoPDFShippingLabelPrint/generateBarcodeBase64";
import { generateQRBase64 } from "../../../components/DocumentoPDFShippingLabelPrint/generateQRBase64";
import { Toolbar } from "@mui/material";
import {
  translateStatesBicci,
  translateStatesBicciFilter,
} from "../../../utils/objects/translateStates";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ReportGmailerrorredIcon from "@mui/icons-material/ReportGmailerrorred";
import CloudSyncIcon from "@mui/icons-material/CloudSync";
import UpdateIcon from "@mui/icons-material/Update";
import { pdf } from "@react-pdf/renderer";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import { format } from "date-fns";
import RouteIcon from "@mui/icons-material/Route";
import generarReportePicking from "../../../utils/Reports/generarReportePicking";
import generarReporteBicciDetalle from "../../../utils/Reports/generarReporteBicciDetalle";
import * as utils from "../../../utils/objects/BicciExpressOrderStatuses";
import { customLocaleText } from "../../../utils/objects/CustomLocaleTextTables";
import { grey, green } from "@mui/material/colors";
import PopUpAsignament from "../../../components/PopUpAsignament/PopUpAsignament";
import ModalAccionsDispacthDirectionsResolve from "../../../components/ModalAccionsDispacth/ModalAccionsDispacthDirectionsResolve";
import generarArchivoCargaSimpliRoute from "../../../utils/Reports/generarArchivoCargaSimplirouteApp";
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";
import { Snackbar } from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import FadeInOut from "../../../components/animations/FadeInOut";
import ToolDashResumenOpsAutoCreate from "../../../components/Dashboard/dashboard/ToolDashResumenOpsAutoCreate";
import CreateManiFestByCurrentListPdf from "../../../components/CreateManiFestByCurrentListPdf/CreateManiFestByCurrentListPdf";
import { OriginsCompanys } from "../../../utils/objects/OriginsCompanys";
import useHandleUpdateRoutesAndPositions from "../../../hooks/useHandleUpdateRoutesAndPositions/useHandleUpdateRoutesAndPositions";
import useCreateSetResumenTable from "../../../hooks/useCreateSetResumenTable";

var dataOrdersForAccions = [];
var listadeItemsNoMach = [];
var popUpAccion = "-";
const GuiasByCompany = ({
  handleModeChange,
  userdata,
  firebaseUser,
  usuarioStorage,
  userDataContext,
  mergedOrders,
  companiesData,
  loadingContext,
  setInitDate,
  setEndDate,
  initDate,
  endDate,
}) => {
  const dispacth = useDispatch();

  // console.log(mergedOrders);
  const { widthHeight, movilActive } = useContext(ScreenSizeContext);
  const response = useSelector((store) => store.usuarioDriver.response);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("success");

  const { ridersDataByCompany } = useGetDataRiderByCompany(
    userDataContext.companyID
  );

  const [file, setFile] = React.useState(null);
  const { data, loading, error, uploadDocument } =
    useHandleUpdateRoutesAndPositions();

  const listItems = utils.listItems;

  const [cantListItems, setcantListItems] = useState({
    Creadas: 0,
    Ruta: 0,
    Incidencias: 0,
    Finalizadas: 0,
    Total: 0,
  });

  const [datailInsures, setDetailInsures] = useState([]);

  const [ordersView, setOrderView] = React.useState([]);

  const [viewCreateRoutes, setViewCreateRoutes] = React.useState(false);

  const [viewTools, setviewTools] = React.useState(true);

  const [toolsData, setToolsData] = React.useState(null);

  const [dataType, setDataType] = React.useState("request");

  const [documentData, setDocumentData] = React.useState(null);

  const [remder, setRender] = React.useState(true);

  const [alertMensajes, setAlertMensajes] = React.useState(null);

  const [columns, setColum] = useState([]);

  const [rows, setRows] = useState([]);

  const [viewMergedOrders, setViewMergedOrders] = useState([]);

  const [companiesOrigin, setCompaniesOrigin] = useState([]);

  const [dataCurrentMohstDelivery, setCurrentMohstDelivery] =
    React.useState(null);

  const [selectedCompanies, setSelectedCompanies] = React.useState([]);

  const [selectedOrigins, setSelectedOrigins] = React.useState([]);

  const [filteredCompanies, setFilteredCompanies] = React.useState([]);

  const [filteredOrigins, setFilteredOrigins] = React.useState([]);

  const [staticDateBillingFilter, setStaticDateBillingFilter] = React.useState(
    Moment(Date.now()).format("YYYY-MM-DD")
  );

  const [viewFindAnSelectorOrdes, setViewFindAnSelectorOrdes] =
    React.useState(false);

  const [selectedData, setSelectedData] = useState([]);

  const [origenGeoCode, setOrigenGeoCode] = useState({
    lat: -33.4592491,
    lng: -70.6014753,
  });
  const [adressOrigin, setAdressOrigin] = useState(
    "Alcalde Eduardo Castillo Velasco 3101"
  );
  const [adressDestiny, setAdressDestiny] = useState(
    "Alcalde Eduardo Castillo Velasco 3101"
  );
  const [destinyGeoCode, setDestinyGeoCode] = useState({
    lat: -33.4592491,
    lng: -70.6014753,
  });

  const Menu = Array.from(new Set(Object.values(translateStatesBicciFilter)));

  const displayMenu = {
    creado: "Creados",
    confirmado: "Procesando",
    qsignado: "Asignado al conductor",
    enruta: "En Ruta",
    visiado: "En Punto de entrega",
    entregado: "Entregado",
    nocofirmacion: "Requiere Actualización",
  };

  const [wiewPrePdf, setWiewPrePdf] = React.useState(false);

  const [Buttondisable, setButtondisable] = React.useState(true);

  const [dateMonths, setDateMonths] = React.useState(
    Moment(Date.now()).format("YYYY-MM")
  );

  const [itemsDataOrdersForAccions, setItemsDataOrdersForAccions] =
    useState(false);

  const [viewCreateRoutesGoogle, setViewCreateRoutesGoogle] = useState(false);

  const [routesOrdersForAccions, setRoutesOrdersForAccions] = useState([]);

  const [opemPopUpAsignament, setOpemPopUpAsignament] = useState(false);

  const [routesDataOrdersForAccions, setRoutesDataOrdersForAccions] = useState(
    []
  );

  const [selectedIDs, setSelectedIDs] = useState([]);

  const [wiewAssignmentIndIcon, setWiewAssignment] = useState(true);

  const [showPopUp, setShowPopUp] = useState(false);

  const { dataResumentables } = useCreateSetResumenTable(
    viewMergedOrders,
    selectedCompanies,
    filteredCompanies
  );

  useEffect(() => {}, [Menu]);

  useEffect(() => {
    if (mergedOrders) {
      //setViewMergedOrders(mergedOrders);
      applyFilters(selectedCompanies, selectedOrigins);
    }
  }, [mergedOrders, selectedCompanies, selectedOrigins]);

  useEffect(() => {
    if (viewMergedOrders) {
      upDateTable(viewMergedOrders);
      createSelectorOrigins(viewMergedOrders);
    }
  }, [viewMergedOrders]);

  useEffect(() => {
    if (dataResumentables) {
      setcantListItems(dataResumentables);
    }
  }, [dataResumentables]);

  useEffect(() => {}, [userDataContext]);

  useEffect(() => {}, [viewTools]);

  useEffect(() => {
    if (response) {
      try {
        if (response.result.status === "success") {
          setMessage(response.result.message);
          setSeverity("success");
        } else {
          // // En caso de que 'response' contenga un objeto de error de axios
          // const errorMessage =
          //   response.response.data?.message ||
          //   response.message ||
          //   "Error desconocido";
          // setMessage(`Error en la asignación: ${errorMessage}`);
          // setSeverity("error");
          setMessage(`Error en la asignación.`);
          setSeverity("error");
        }

        setOpen(true);
      } catch (error) {
        console.log(error);
      }
    }
  }, [response]);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const onChageRangePiker = (createDate, FinalDate) => {
    setInitDate(Moment(new Date(createDate.value)).format("YYYY-MM-DD"));
    setEndDate(Moment(new Date(FinalDate.value)).format("YYYY-MM-DD"));
  };

  const onChageRangeMonths = (e) => {
    setDateMonths(e.target.value);
  };

  const generarReporteTurno = () => {
    generarReporteBicciDetalle(viewMergedOrders, ridersDataByCompany, initDate);
  };

  const deletedItems = async (data) => {
    for (let index = 0; index < data.length; index++) {
      let docRef = doc(db, process.env.REACT_APP_COL_ORDERS, data[index].id);
      await deleteDoc(docRef)
        .then(() => {})
        .catch((error) => {});
    }
  };

  const exportartReportePagos = (userOrders) => {
    generarReporteProformas(userOrders);
  };

  const exportartPicking = (userOrders) => {
    generarReportePicking(userOrders);
  };

  const handleCreate = (order) => {
    let orderSelect = ordersView.filter((item) => item.id === order.id);
    dispacth(createOrderBicciClientCompanyBicciexpress(orderSelect[0]));
  };

  const handlePrint = async (order) => {
    let orderSelect = mergedOrders.filter((item) => item.id === order.id);

    const barcodeImage = await generateBarcodeBase64(orderSelect[0].id);

    const barcodeImageLpn = orderSelect[0].int_lpn
      ? await generateBarcodeBase64(orderSelect[0].int_lpn)
      : await generateBarcodeBase64(orderSelect[0].id);

    const QRImage = await generateQRBase64(orderSelect[0].id);

    const blob = await pdf(
      <DocumentoPDFShippingLabelPrint
        order={orderSelect[0]}
        barcodeImage={barcodeImage}
        barcodeImageLpn={barcodeImageLpn}
        qrImage={QRImage}
      />
    ).toBlob();

    const url = URL.createObjectURL(blob);
    window.open(url, "_blank");
  };

  const handleViewAll = () => {
    function flattenData(data) {
      let flattenedArray = [];
      for (const key in data) {
        flattenedArray = flattenedArray.concat(data[key]);
      }
      return flattenedArray;
    }
    const combinedArray = flattenData(viewMergedOrders);

    setOrderView(combinedArray);
  };

  const handleAssingDriver = (order) => {
    let orderSelect = ordersView.filter((item) => item.id === order.id);
  };

  const handleUpdateOrdes = (order) => {
    let orderSelect = ordersView.filter((item) => item.id === order.id);
  };

  const handleEdithNavigateRows = (dataRow) => {
    const url = `/dashboard/dispatch/edithDetails/${dataRow.id}`;
    window.open(url, "_blank");
  };

  const handleNavigateRows = (dataRow) => {
    const url = `/dashboard/dispatch/details/${dataRow.id}`;
    window.open(url, "_blank");
  };

  const upDateTable = (ordersView) => {
    const renderPiking = (params) => {
      if (params.row.isPickedUp === true) {
        return <CheckCircleIcon sx={{ color: "#4FFF33" }}></CheckCircleIcon>;
      } else {
        return (
          <ReportGmailerrorredIcon
            sx={{ color: "#D80B0B" }}
          ></ReportGmailerrorredIcon>
        );
      }
    };

    const renderActionButtons = (params) => {
      if (params.row.id !== undefined && params.row.id !== "") {
        return (
          <Box
            sx={{
              display: "flex", // Arrange the buttons in a row
              flexDirection: "row",
              justifyContent: "space-between", // Ensure there's space between buttons
              gap: "8px", // Add some space between buttons
            }}
          >
            <IconButton
              sx={{
                width: "30px !important",
                color: "#1e2158 !important",
              }}
              aria-label="Etiqueta"
              onClick={() => handlePrint(params.row)}
            >
              <QrCode2Icon />
            </IconButton>

            <IconButton
              sx={{
                width: "30px !important",
                color: "#1e2158 !important",
              }}
              aria-label="ver"
              onClick={() => handleNavigateRows(params.row)}
            >
              <VisibilityIcon />
            </IconButton>

            <IconButton
              sx={{
                width: "30px !important",
                color: "#1e2158 !important",
              }}
              aria-label="Editar"
              onClick={() => handleEdithNavigateRows(params.row)}
            >
              <ModeEditIcon />
            </IconButton>
          </Box>
        );
      }
    };
    const columnsTable = [
      {
        field: "id",
        headerName: "ID",
        width: 150,
      },
      {
        field: "placeName",
        headerName: "ORIGEN",
        width: 150,
      },
      {
        field: "driverName",
        headerName: "RIDER",
        width: 150,
      },
      {
        field: "createdAt",
        headerName: "CREADO",
        width: 200,
      },
      {
        field: "toAddress",
        headerName: "DESTINO",
        width: 200,
      },
      {
        field: "isPickedUp",
        headerName: "PICKING",
        width: 20,
        renderCell: renderPiking,
        sortable: true,
        filterable: true,
      },
      {
        field: "driverLicensePlate",
        headerName: "RUTA",
        width: 100,
      },
      {
        field: "status",
        headerName: "ESTADO",
        width: 100,
      },
      {
        field: "instStatus",
        headerName: "SUB ESTADO",
        width: 100,
      },
      {
        field: "actions",
        headerName: "ACCIONES",
        width: 150,
        renderCell: renderActionButtons,
      },
    ];

    setColum(columnsTable);

    var rows = [];

    for (var i = 0; i < ordersView.length; i++) {
      rows.push({
        id: ordersView[i].id,
        placeName: ordersView[i].placeName,
        driverName: ordersView[i].driverName,
        createdAt: format(
          ordersView[i].createdAt.seconds * 1000,
          "dd/MM/yy HH:mm:ss"
        ),
        toAddress: `${ordersView[i].toAddress}`,
        isPickedUp: ordersView[i].isPickedUp,
        driverLicensePlate: ordersView[i].driverLicensePlate,
        status:
          ordersView[i].lostDelivery !== true
            ? translateStatesBicci[ordersView[i].status]
            : "No Entregado",
        instStatus: ordersView[i].intState,
      });
    }

    setRows(rows);
  };

  const setResumenTable = (dataOrdes) => {
    if (!dataOrdes && dataOrdes.length === 0) {
      return;
    }
    //   setMenssajeAdmin(
    //     <span className="alert alert-danger">
    //       "NO se encuentran envíos para la fecha Solicitada"
    //     </span>
    //   );
    // } else {
    //   setMenssajeAdmin(null);
    // }

    let Creadas = dataOrdes.filter(
      (item) => item.status === 0 || item.status === 1
    );
    //cantListItems.Creadas = Creadas.length
    let Finalizadas = dataOrdes.filter((item) => item.status === 8);
    //cantListItems.Finalizadas = Finalizadas.length
    let Ruta = dataOrdes.filter((item) => item.status >= 4 && item.status < 8);
    //cantListItems.Ruta = Ruta.length
    let Incidencias = dataOrdes.filter(
      (item) => item.status < 0 || item.lostDelivery === true
    );
    //  cantListItems.Incidencias = Incidencias.length
    //  cantListItems.Total = dataOrdes.length
    setcantListItems({
      Creadas: Creadas.length,
      Ruta: Ruta.length,
      Incidencias: Incidencias.length,
      Finalizadas: Finalizadas.length,
      Total: dataOrdes.length,
    });
  };

  const handleViewChange = (item) => {
    if (viewMergedOrders && viewMergedOrders[item]) {
      setOrderView(viewMergedOrders[item]);
    } else {
      setOrderView([]);
    }
  };

  const createSelectorOrigins = (dataOrdes) => {
    let placeNames = [];
    mergedOrders.forEach((items) => {
      if (!placeNames.some((place) => place.placeName === items.placeName)) {
        placeNames.push({ placeName: items.placeName });
      }
    });

    setCompaniesOrigin(placeNames);
  };

  const applyFilters = (selectedCompanies, selectedOrigin) => {
    const ExternalIntegrationsOrigins = ["falabella", "firo"];

    let tempView = mergedOrders;

    // Filtro para las compañías seleccionadas
    if (selectedCompanies.length > 0) {
      // Crear una lista temporal que combine los resultados de las diferentes compañías seleccionadas
      let filteredByCompanies = [];

      selectedCompanies.forEach((companyID) => {
        // Si la compañía está en ExternalIntegrationsOrigins, filtrar por orígenes
        if (ExternalIntegrationsOrigins.includes(companyID)) {
          if (OriginsCompanys[companyID]) {
            let tempOrigins = OriginsCompanys[companyID];
            let companyFiltered = tempView.filter((items) =>
              tempOrigins.includes(items.placeName)
            );
            filteredByCompanies = filteredByCompanies.concat(companyFiltered);
          }
        } else {
          // Si la compañía no está en ExternalIntegrationsOrigins, filtrar por companyID
          let companyFiltered = tempView.filter((items) => {
            return items.companyID === companyID;
          });
          filteredByCompanies = filteredByCompanies.concat(companyFiltered);
        }
      });

      // Actualizamos tempView con el conjunto de resultados acumulados
      tempView = filteredByCompanies;
    }

    // Aplicar filtro por origen si hay alguno seleccionado
    if (selectedOrigin.length > 0) {
      tempView = tempView.filter((items) =>
        selectedOrigin.includes(items.placeName)
      );
    }

    // Actualizar las vistas con los resultados filtrados
    setViewMergedOrders(tempView);
  };

  const setViewFilterMergedOrders = (selectedCompanies) => {
    applyFilters(selectedCompanies, selectedOrigins);
  };

  const setViewFilterMergedOrdersOrigins = (selectedOrigin) => {
    applyFilters(selectedCompanies, selectedOrigin);
  };

  const handleViewTools = (view) => {
    setviewTools(view);
  };

  const HandleSeleccions = (ids) => {
    const selectedRowsData = ids.map((id) => rows.find((row) => row.id === id));
    setSelectedData(selectedRowsData);
    setSelectedIDs(ids);
    UpdateOrderForAcctions(ids);
    if (ids.length > 0) {
      handleViewTools(true);
    } else {
      handleViewTools(false);
    }
  };

  const UpdateOrderForAcctions = (items) => {
    listadeItemsNoMach = [];
    let ordersForAccions = [];
    let dataOrdersForAccions = [];
    var tempuserOrders = mergedOrders;
    var listadeItems = items;

    for (let i = 0; i < tempuserOrders.length; i++) {
      tempuserOrders[i].setectedItem = false;
    }
    for (let i = 0; i < listadeItems.length; i++) {
      for (let n = 0; n < tempuserOrders.length; n++) {
        if (listadeItems[i] === tempuserOrders[n].id) {
          tempuserOrders[n].setectedItem = true;
          ordersForAccions.push(tempuserOrders[n].id);
          dataOrdersForAccions.push(tempuserOrders[n]);
        }
      }
    }

    for (let index = 0; index < listadeItems.length; index++) {
      if (!ordersForAccions.includes(listadeItems[index])) {
        listadeItemsNoMach.push(listadeItems[index]);
      }
    }
    if (ordersForAccions.length > 0) {
      setItemsDataOrdersForAccions(true);
      setRoutesDataOrdersForAccions(dataOrdersForAccions);
      setRoutesOrdersForAccions(ordersForAccions);
    } else {
      setItemsDataOrdersForAccions(false);
    }
  };

  const accionArchivoCargaSimpliRoute = (data) => {
    generarArchivoCargaSimpliRoute(routesDataOrdersForAccions);
  };

  const isShowPopUp = (accion) => {
    popUpAccion = accion;
    setShowPopUp(!showPopUp);
  };

  const handleClosePopUp = () => {
    setShowPopUp(false);
  };

  const handleClickSelectRiderCurrentList = (e, item) => {
    e.preventDefault();
    const selectedRowsData = item.countersListOrders.map((id) =>
      rows.find((row) => row.id === id)
    );
    setSelectedData(selectedRowsData);
    setSelectedIDs(item.countersListOrders);
    UpdateOrderForAcctions(item.countersListOrders);
  };

  const handleClickSelectRiderPendintList = (e, item) => {
    e.preventDefault();
    const selectedRowsDataPending = item.countersIssuresListOrders.map((id) =>
      rows.find((row) => row.id === id)
    );

    const pendingIDs = selectedRowsDataPending.map((row) => row.id);
    setSelectedData(selectedRowsDataPending);
    setSelectedIDs(item.countersIssuresListOrders);
    UpdateOrderForAcctions(pendingIDs);
  };

  const handleCopySelectRiderCurrentList = (e, item) => {
    e.preventDefault();

    listadeItemsNoMach = [];
    let copyOrdersForAccions = [];
    var tempuserOrders = mergedOrders.slice();
    var listadeItems = new Set(item.countersListOrders);

    try {
      const tempuserOrdersMap = new Map();
      tempuserOrders.forEach((order) => {
        tempuserOrdersMap.set(order.id, order);
      });

      listadeItems.forEach((id) => {
        const order = tempuserOrdersMap.get(id);
        if (order) {
          copyOrdersForAccions.push(order.id);
        }
      });

      let copyData = copyOrdersForAccions.join("\n"); // Convertir a string con saltos de línea
      navigator.clipboard.writeText(copyData);
    } catch (error) {
      console.log(error);
    }
  };

  const createManiFestByCurrentList = async (e, item) => {
    e.preventDefault();

    listadeItemsNoMach = [];
    let copyOrdersForAccions = [];
    var tempuserOrders = mergedOrders;
    var listadeItems = item.countersListOrders;

    for (let i = 0; i < listadeItems.length; i++) {
      for (let n = 0; n < tempuserOrders.length; n++) {
        if (listadeItems[i] === tempuserOrders[n].id) {
          copyOrdersForAccions.push(tempuserOrders[n]);
        }
      }
    }

    const blob = await pdf(
      <CreateManiFestByCurrentListPdf orders={copyOrdersForAccions} />
    ).toBlob();

    const url = URL.createObjectURL(blob);
    window.open(url, "_blank");
  };

  const handleFileChange = async (event) => {
    const selectedFile = event.target.files[0];
    setFile(selectedFile);
    if (selectedFile) {
      await uploadDocument(selectedFile);
      setViewFindAnSelectorOrdes(true);
      resetState();
    }
  };

  const resetState = () => {
    setFile(null);
    setViewFindAnSelectorOrdes(false);
    document.getElementById("upload-input").value = null; // Resetear el input de archivo
  };

  return (
    <>
      <Box>
        <Grid
          sx={{
            width: {
              xs: "100%",
              sm: "100%",
              md: "100%",
              lg: "100%",
            },
          }}
        >
          <Grid
            sx={{
              width: {
                xs: "100%",
                sm: "100%",
                md: "100%",
                lg: "100%",
              },
            }}
          ></Grid>

          <Card
            sx={{
              width: {
                xs: "98%",
                sm: "98%",
                md: "98%",
                lg: "98%",
              },
            }}
          >
            <CardHeader
              subheader={
                <ToolDashAppBarCompanyOps
                  handleModeChange={handleModeChange}
                  movil={widthHeight.width < 950 ? true : false}
                  cantDataOrdersFilter={
                    viewMergedOrders
                      ? Object.values(viewMergedOrders).reduce(
                          (total, currentArray) => total + currentArray.length,
                          0
                        )
                      : 0
                  }
                  dataOrdersFilter={viewMergedOrders}
                  dataCurrentMohstDelivery={dataCurrentMohstDelivery}
                  dataOrdersForAccions={dataOrdersForAccions}
                  setWiewPrePdf={setWiewPrePdf}
                  wiewPrePdf={wiewPrePdf}
                  listadeItemsNoMach={listadeItemsNoMach}
                  onChageRangeMonths={onChageRangeMonths}
                  dateMonths={dateMonths}
                  staticDateBillingFilter={staticDateBillingFilter}
                  setAlertMensajes={setAlertMensajes}
                  toolsData={toolsData}
                  setToolsData={setToolsData}
                  documentData={documentData}
                  generarReporteTurno={generarReporteTurno}
                  dataType={dataType}
                  setDocumentData={setDocumentData}
                  setDataType={setDataType}
                  remder={remder}
                  setRender={setRender}
                  onChageRangePiker={onChageRangePiker}
                  userDataContext={userDataContext}
                  companiesData={companiesData}
                  exportartReportePagos={exportartReportePagos}
                  listItems={listItems}
                  cantListItems={cantListItems}
                  setViewFilterMergedOrders={setViewFilterMergedOrders}
                  companiesOrigin={companiesOrigin}
                  setViewFilterMergedOrdersOrigins={
                    setViewFilterMergedOrdersOrigins
                  }
                  setSelectedCompanies={setSelectedCompanies}
                  setSelectedOrigins={setSelectedOrigins}
                  filteredCompanies={filteredCompanies}
                  filteredOrigins={filteredOrigins}
                  setFilteredCompanies={setFilteredCompanies}
                  setFilteredOrigins={setFilteredOrigins}
                  selectedCompanies={selectedCompanies}
                  selectedOrigins={selectedOrigins}
                  //   datailInsures={datailInsures}
                  exportartPicking={exportartPicking}
                ></ToolDashAppBarCompanyOps>
              }
              sx={{ padding: "0px" }}
            />
            <Divider />

            <Box>
              <div>{alertMensajes}</div>
              <Grid
                sx={{
                  width: {
                    xs: "100%",
                    sm: "100%",
                    md: "100%",
                    lg: "100%",
                  },
                }}
              >
                <Typography
                  sx={{
                    color: "#591e8f",
                    fontWeight: 400,
                    padding: "12px",
                  }}
                >
                  Resumen de Operación
                </Typography>
                {!viewTools ? (
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      border: "solid 1px #591e8f",
                      borderRadius: "15px",
                      padding: "5px",
                      margin: "5px",
                    }}
                  >
                    <Button onClick={() => handleViewTools(true)}>
                      <Typography
                        sx={{
                          color: "#591e8f",
                          fontWeight: 400,
                          padding: "12px",
                        }}
                      >
                        Ver Herramientas de gestión
                      </Typography>
                    </Button>
                  </Box>
                ) : (
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      border: "solid 1px #591e8f",
                      borderRadius: "15px",
                      padding: "5px",
                      margin: "5px",
                    }}
                  >
                    <div>
                      <Button onClick={() => handleViewTools(false)}>
                        {" "}
                        <Typography
                          sx={{
                            color: "#591e8f",
                            fontWeight: 400,
                            padding: "12px",
                          }}
                        >
                          Herramientas de gestión:
                        </Typography>
                      </Button>
                      <IconButton
                        size="large"
                        aria-label="account of current user"
                        aria-controls="menu-appbar"
                        aria-haspopup="true"
                        onClick={() => {
                          setViewCreateRoutes(!viewCreateRoutes);
                          //setWiewAssignment(true);
                        }}
                        sx={{ color: "#4955af" }}
                      >
                        <Tooltip title="Crear Rutas">
                          <RouteIcon
                            sx={{
                              color: viewCreateRoutes ? "#eeeeee" : "#4955af",
                            }}
                          />
                        </Tooltip>
                      </IconButton>
                      <IconButton
                        size="large"
                        aria-label="account of current user"
                        aria-controls="menu-appbar"
                        aria-haspopup="true"
                        sx={{ color: "#4955af" }}
                        onClick={() =>
                          accionArchivoCargaSimpliRoute(viewCreateRoutes)
                        }
                      >
                        <Tooltip title="Doc Data Route">
                          <SystemUpdateAltIcon
                          // sx={{
                          //   color: viewFindAnSelectorOrdes
                          //     ? green[500]
                          //     : "#4955af",
                          // }}
                          />
                        </Tooltip>
                      </IconButton>{" "}
                      <Box sx={{ display: "inline-block" }}>
                        <input
                          type="file"
                          onChange={handleFileChange}
                          style={{ display: "none" }}
                          id="upload-input"
                        />
                        <label htmlFor="upload-input">
                          <IconButton
                            size="large"
                            aria-label="upload document"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            sx={{ color: "#4955af" }}
                            component="span"
                          >
                            <Tooltip title="Re-Ordenar Rutas, Con archivo de ruteo">
                              <CloudSyncIcon
                                sx={{
                                  color: viewFindAnSelectorOrdes
                                    ? green[500]
                                    : "#4955af",
                                }}
                              />
                            </Tooltip>
                          </IconButton>
                        </label>
                        {loading && <div>Cargando...</div>}
                        {data && !loading && <div>{data.response}</div>}
                        {error && (
                          <div>
                            Error al subir el documento: {error.message}
                          </div>
                        )}
                      </Box>
                      <IconButton
                        size="large"
                        aria-label="account of current user"
                        aria-controls="menu-appbar"
                        aria-haspopup="true"
                        onClick={
                          () => setWiewAssignment(!wiewAssignmentIndIcon)
                          //   //accionArchivoCargaSimpliRoute(dataMergeLocalOrder)
                        }
                        sx={{ color: "#4955af" }}
                      >
                        <Tooltip title="Ver Resumen de operaciones">
                          <AssignmentIndIcon
                          // sx={{
                          //   color: props.wiewAssignmentIndIcon
                          //     ? green[500]
                          //     : "#4955af",
                          // }}
                          />
                        </Tooltip>
                      </IconButton>{" "}
                      <IconButton
                        size="large"
                        aria-label="account of current user"
                        aria-controls="menu-appbar"
                        aria-haspopup="true"
                        onClick={
                          () => setOpemPopUpAsignament(true)
                          //accionArchivoCargaSimpliRoute(dataMergeLocalOrder)
                        }
                        sx={{ color: "#4955af" }}
                      >
                        <Tooltip title="Asignar conductor">
                          <DirectionsBikeIcon
                          // sx={{
                          //   color: props.wiewAssignmentIndIcon
                          //     ? green[500]
                          //     : "#4955af",
                          // }}
                          />
                        </Tooltip>
                      </IconButton>{" "}
                      <Button onClick={() => console.log(selectedData)}>
                        <Typography sx={{ color: "#4955af" }}>
                          {" "}
                          Seleccionadas : {selectedData.length}
                        </Typography>
                      </Button>
                    </div>
                  </Box>
                )}
                <Box>
                  <div>
                    {viewCreateRoutes === true ? (
                      <Grid container spacing={2} columns={16}>
                        <Grid
                          item
                          sx={{
                            width: {
                              xs: "100%",
                              sm: "100%",
                              md: "100%",
                              lg: "50%",
                            },
                          }}
                        >
                          <Toolbar
                            sx={{
                              backgroundColor: "#eeeeee",
                              border: 0.5,
                              borderColor: "#eeeeee",
                            }}
                          >
                            <ButtonGroup
                              variant="contained"
                              aria-label="outlined primary button group"
                            >
                              <Button
                                className="btn-tool-small-onlyColor"
                                onClick={() =>
                                  setViewCreateRoutesGoogle(
                                    !viewCreateRoutesGoogle
                                  )
                                }
                              >
                                Ruta Google Maps
                              </Button>
                              <Button
                                className="btn-tool-small-onlyColor"
                                onClick={() => isShowPopUp("ExcelFile")}
                              >
                                Ruta Circuit
                              </Button>
                              <Button
                                className="btn-tool-small-onlyColor"
                                onClick={() =>
                                  accionArchivoCargaSimpliRoute(selectedData)
                                }
                              >
                                Generar data para Simpli
                              </Button>
                              <Button
                                className="btn-tool-small-onlyColor"
                                onClick={() => isShowPopUp("RutaBicciexpress")}
                              >
                                Ruta Bicci
                              </Button>

                              {/* Botón para subir archivo */}
                              <div>
                                <input
                                  type="file"
                                  onChange={handleFileChange}
                                  style={{ display: "none" }}
                                  id="upload-input"
                                />
                                <label htmlFor="upload-input">
                                  <IconButton
                                    size="large"
                                    aria-label="upload document"
                                    aria-controls="menu-appbar"
                                    aria-haspopup="true"
                                    sx={{ color: "#4955af" }}
                                    component="span"
                                  >
                                    <Tooltip title="Re-Ordenar Rutas, Con archivo de ruteo">
                                      <CloudSyncIcon
                                        sx={{
                                          color: viewFindAnSelectorOrdes
                                            ? green[500]
                                            : "#4955af",
                                        }}
                                      />
                                    </Tooltip>
                                  </IconButton>
                                </label>
                                {loading && <div>Cargando...</div>}
                                {data && !loading && <div>{data.response}</div>}
                                {error && (
                                  <div>
                                    Error al subir el documento: {error.message}
                                  </div>
                                )}
                              </div>
                            </ButtonGroup>
                          </Toolbar>
                        </Grid>
                        <Grid
                          item
                          sx={{
                            width: {
                              xs: "100%",
                              sm: "100%",
                              md: "100%",
                              lg: "50%",
                            },
                          }}
                        ></Grid>
                      </Grid>
                    ) : null}
                  </div>
                </Box>
                <Box>
                  <div>
                    {viewCreateRoutesGoogle === true &&
                    viewCreateRoutes === true ? (
                      <Grid container spacing={2} columns={16}>
                        <Grid
                          item
                          sx={{
                            width: {
                              xs: "100%",
                              sm: "100%",
                              md: "100%",
                              lg: "50%",
                            },
                          }}
                        >
                          <Grid container spacing={3} columns={16}>
                            <Grid
                              item
                              sx={{
                                width: {
                                  xs: "100%",
                                  sm: "100%",
                                  md: "30%",
                                  lg: "30%",
                                },
                              }}
                            >
                              <div className="typeMiniformsBarsAccionsItemsMinimal">
                                <span>Google Maps API: Origen.</span>
                                <div className="m-2">
                                  <Search
                                    setButtondisable={setButtondisable}
                                    setOrigenGeoCode={setOrigenGeoCode}
                                    setAdressOrigin={setAdressOrigin}
                                    id="imputSearchFromOrigen"
                                  />
                                </div>
                              </div>
                            </Grid>{" "}
                            <Grid
                              item
                              sx={{
                                width: {
                                  xs: "100%",
                                  sm: "100%",
                                  md: "30%",
                                  lg: "30%",
                                },
                              }}
                            >
                              <div className="typeMiniformsBarsAccionsItemsMinimal">
                                <span>Google Maps API: Destino.</span>
                                <div className="m-2">
                                  <SearchDestiny
                                    setButtondisable={setButtondisable}
                                    setDestinyGeoCode={setDestinyGeoCode}
                                    setAdressDestiny={setAdressDestiny}
                                    id="imputSearchFromDestiny"
                                  />
                                </div>
                              </div>
                            </Grid>
                            <Grid
                              item
                              sx={{
                                width: {
                                  xs: "100%",
                                  sm: "100%",
                                  md: "30%",
                                  lg: "30%",
                                },
                              }}
                            >
                              <button
                                onClick={() => isShowPopUp("GoogleMaps")}
                                disabled={Buttondisable}
                                className={
                                  adressOrigin && adressDestiny
                                    ? "btn-tool-small"
                                    : "extentButton Buttondisable m-1"
                                }
                              >
                                {" "}
                                {adressOrigin && adressDestiny
                                  ? "Crear Ruta Google Maps"
                                  : "Ingresa dirección origen y destino para continuar"}
                              </button>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid
                          item
                          sx={{
                            width: {
                              xs: "100%",
                              sm: "100%",
                              md: "100%",
                              lg: "50%",
                            },
                          }}
                        ></Grid>
                      </Grid>
                    ) : null}
                  </div>
                </Box>
              </Grid>

              <Grid>
                {wiewAssignmentIndIcon ? (
                  <div className="frontMetricBar">
                    <div className="content-small-screm">
                      <FadeInOut
                        sx={{ padding: "0px" }}
                        show={wiewAssignmentIndIcon}
                        duration={500}
                      >
                        <ToolDashResumenOpsAutoCreate
                          mergedOrders={mergedOrders}
                          handleClickSelectRiderCurrentList={
                            handleClickSelectRiderCurrentList
                          }
                          handleClickSelectRiderPendintList={
                            handleClickSelectRiderPendintList
                          }
                          handleCopySelectRiderCurrentList={
                            handleCopySelectRiderCurrentList
                          }
                          createManiFestByCurrentList={
                            createManiFestByCurrentList
                          }
                        ></ToolDashResumenOpsAutoCreate>
                      </FadeInOut>
                    </div>
                  </div>
                ) : (
                  <></>
                )}
              </Grid>
              <Grid>
                {ordersView && widthHeight.width > 950 ? (
                  <DataGrid
                    rows={rows}
                    columns={columns}
                    pageSize={1000}
                    rowsPerPage={[50, 100, 1000]}
                    pagination
                    localeText={customLocaleText} // Aquí se aplica la localización
                    slots={{
                      toolbar: GridToolbar, // Incluye la barra de herramientas para opciones adicionales
                    }}
                    groupingModel={["driverName"]}
                    checkboxSelection
                    onRowSelectionModelChange={(items) =>
                      HandleSeleccions(items)
                    }
                    rowSelectionModel={selectedIDs}
                  ></DataGrid>
                ) : (
                  <LinearProgress></LinearProgress>
                )}
              </Grid>
              {showPopUp ? (
                <ModalAccionsDispacthDirectionsResolve
                  opened={showPopUp}
                  handleClosePopUp={handleClosePopUp}
                  popUpAccion={popUpAccion}
                  interfaceOrigen={"liveOrders"}
                  ordersForAccions={routesOrdersForAccions}
                  dataOrdersForAccions={routesDataOrdersForAccions}
                  companyId={"26456608-8"}
                  ridersDataByCompany={ridersDataByCompany}
                  adressOrigin={adressOrigin}
                  origenGeoCode={origenGeoCode}
                  adressDestiny={adressDestiny}
                  destinyGeoCode={destinyGeoCode}
                >
                  {" "}
                </ModalAccionsDispacthDirectionsResolve>
              ) : null}
              {ridersDataByCompany ? (
                <PopUpAsignament
                  opemPopUpAsignament={opemPopUpAsignament}
                  setOpemPopUpAsignament={setOpemPopUpAsignament}
                  ordersForAccions={routesOrdersForAccions}
                  ridersDataByCompany={ridersDataByCompany}
                ></PopUpAsignament>
              ) : null}
            </Box>

            <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
              <MuiAlert
                onClose={handleClose}
                severity={severity}
                sx={{ width: "100%" }}
              >
                {message}
              </MuiAlert>
            </Snackbar>
            <Divider />
          </Card>
        </Grid>
      </Box>
    </>
  );
};

function Search({ setButtondisable, setAdressOrigin, setOrigenGeoCode }) {
  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      location: { lat: () => 43.6532, lng: () => -79.3832 },
      radius: 10 * 1000,
      componentRestrictions: {
        country: "Cl",
      },
    },
  });
  // https://developers.google.com/maps/documentation/javascript/reference/places-autocomplete-service#AutocompletionRequest
  const handleInput = (e) => {
    setValue(e.target.value);
  };
  const handleSelect = async (address) => {
    // console.log(address)
    setValue(address, false);
    clearSuggestions();
    let element = [];
    try {
      const results = await getGeocode({ address });

      setAdressOrigin(address);
      //console.log(address)

      const { lat, lng } = await getLatLng(results[0]);

      setOrigenGeoCode({ lat: lat, lng: lng });
      //console.log(lat, lng)

      setButtondisable(false);
    } catch (error) {
      //console.log("😱 Error: ", error);
      //console.log(element)
    }
  };

  return (
    <div className="search-box-ebiex">
      <Combobox onSelect={handleSelect}>
        <ComboboxInput
          className="input-group mb-3 imput-modal-routeMaps"
          id="imputSearchFromOrigenRoutes"
          value={value}
          onChange={handleInput}
          disabled={!ready}
          placeholder="Buscar Origen..."
        />
        <ComboboxPopover className="comboboxList-ebiex-modal">
          <ComboboxList className="comboboxList-ebiex-modal-items">
            {status === "OK" &&
              data.map(({ id, description }) => (
                <ComboboxOption
                  key={Math.random().toString()}
                  value={description}
                />
              ))}
          </ComboboxList>
        </ComboboxPopover>
      </Combobox>
    </div>
  );
}

function SearchDestiny({
  setButtondisable,
  setAdressDestiny,
  setDestinyGeoCode,
}) {
  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      location: { lat: () => 43.6532, lng: () => -79.3832 },
      radius: 10 * 1000,
      componentRestrictions: {
        country: "Cl",
      },
    },
  });
  // https://developers.google.com/maps/documentation/javascript/reference/places-autocomplete-service#AutocompletionRequest
  const handleInput = (e) => {
    setValue(e.target.value);
  };
  const handleSelect = async (address) => {
    // console.log(address)
    setValue(address, false);
    clearSuggestions();
    let element = [];
    try {
      const results = await getGeocode({ address });

      setAdressDestiny(address);
      //console.log(address)

      const { lat, lng } = await getLatLng(results[0]);

      setDestinyGeoCode({ lat: lat, lng: lng });
      //console.log(lat, lng)

      // setButtondisable(false);
    } catch (error) {
      //console.log("😱 Error: ", error);
      //console.log(element)
    }
  };

  return (
    <div className="search-box-ebiex">
      <Combobox onSelect={handleSelect}>
        <ComboboxInput
          className="input-group mb-3 imput-modal-routeMaps"
          id="imputSearchFromOrigenRoutes"
          value={value}
          onChange={handleInput}
          disabled={!ready}
          placeholder={"Buscar destino.."}
        />
        <ComboboxPopover className="comboboxList-ebiex-modal">
          <ComboboxList className="comboboxList-ebiex-modal-items">
            {status === "OK" &&
              data.map(({ id, description }) => (
                <ComboboxOption
                  key={Math.random().toString()}
                  value={description}
                />
              ))}
          </ComboboxList>
        </ComboboxPopover>
      </Combobox>
    </div>
  );
}

export default GuiasByCompany;
