import * as turf from "@turf/turf";

export const assignOrdersToPolygons = async (
  orders = [],
  polygonsData = {}, // Ahora recibimos el archivo completo, no solo el array de polígonos
  optimizeOptions
) => {
  const ordersByPolygon = {};

  // 📌 Validación: Asegurar que `polygonsData.features` es un array válido
  const polygons = Array.isArray(polygonsData.features)
    ? polygonsData.features
    : [];

  // 📌 Si no hay polígonos y `applySectorization` está **desactivado**, agrupamos todo en "General"
  if (polygons.length === 0 || !optimizeOptions.applySectorization) {
    console.warn(
      "⚠️ No hay polígonos válidos o la sectorización está desactivada. Se agrupan todas las órdenes."
    );
    ordersByPolygon["General"] = orders; // Todas las órdenes quedan en "General"
    return ordersByPolygon;
  }

  // 📌 Asignación de órdenes a polígonos
  orders.forEach((order) => {
    let assigned = false;

    for (const feature of polygons) {
      const { geometry, properties } = feature;

      // 📌 Validamos que sea un polígono con coordenadas
      if (
        geometry &&
        geometry.type === "Polygon" &&
        Array.isArray(geometry.coordinates)
      ) {
        const polygon = turf.polygon(geometry.coordinates);
        const point = turf.point([
          order.toAddressLocation?._long || 0,
          order.toAddressLocation?._lat || 0,
        ]);

        if (turf.booleanPointInPolygon(point, polygon)) {
          const polygonName =
            properties?.Name?.trim() || "Polígono Desconocido";

          if (!ordersByPolygon[polygonName]) ordersByPolygon[polygonName] = [];
          ordersByPolygon[polygonName].push(order);

          assigned = true;
          break; // Si ya asignó la orden, no sigue iterando
        }
      }
    }

    // 📌 Manejo de órdenes fuera de polígonos según `respectZones`
    if (!assigned) {
      if (optimizeOptions.respectZones) {
        console.warn(
          `❌ Orden ${order.id} descartada por no estar en un polígono.`
        );
      } else {
        if (!ordersByPolygon["General"]) ordersByPolygon["General"] = [];
        ordersByPolygon["General"].push(order);
      }
    }
  });

  return ordersByPolygon;
};
