import { Button } from "@mui/material";
import React from "react";
import { RoutingDialog } from "./dialogs";
import axios from "axios";
import { kmeans } from "./UtilsOptimize/kmeans";
import { buildOptimizationPayload } from "./UtilsOptimize/buildOptimizationPayload";
import { routeInfo as functionRouteInfo } from "./UtilsOptimize/routeInfo";

const ButonRutearDialog = ({
  setOpenRuoterDialog,
  openRuoterDialog,
  dataMerge,
  selectedOrders,
  setViewDataMerge,
  setLocalDataMerge,
  loading,
  routes,
  setLoading,
  optimizeOptions,
  setOptimizeOptions,
  reqApiOptimizationData,
  setOptimizedWaypoints,
  setRouteGeometry,
  routeInfo,
  setMetricsByRoutes,
  setReqApiOptimizationData,
}) => {
  const urlPro = process.env.REACT_APP_BICCI_ROUTES_OPTIMIZE;

  const handleOpenRutearDialog = () => {
    setOpenRuoterDialog(true);
  };

  const handleCancelRutearDialog = () => {
    setOpenRuoterDialog(false);
  };

  const fetchOptimizedWaypoints = async (data) => {
    try {
      const response = await axios.post(urlPro, data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${process.env.REACT_APP_ROUTES_BICCI_TOKEN}`,
        },
        credentials: "include",
      });

      if (response.status === 200) {
        return response.data;
      } else {
        console.error("Respuesta con error de OSRM:", response);
        return null;
      }
    } catch (error) {
      console.error("Error en la solicitud a OSRM /optimizeRoute:", error);
      return null;
    }
  };

  const indexById = Object.entries(routeInfo.ordersByRoute).reduce(
    (acc, [routeName, orders]) => {
      orders.forEach((order) => {
        // order.id será la clave,
        // y guardamos por ejemplo { routeName, order } como valor
        acc[order.id] = {
          routeName,
          order,
        };
      });
      return acc;
    },
    {}
  );

  const handleApplyRuteo = async () => {
    setOptimizedWaypoints([]);
    setRouteGeometry([]);
    setLoading(true);
    let finalUpdatedOrders = [...dataMerge];
    let allOptimizedRoutes = [];
    let allOptimizedWaypoints = [];
    let allMetrics = {};
    let ordersByRoute;
    let updatedOrders;

    setTimeout(handleCancelRutearDialog, 1000);

    for (let route of reqApiOptimizationData) {
      if (route.waypoints.length < 0) {
        return;
      }

      const found = route.waypoints[1]
        ? indexById[route.waypoints[1].id]
        : null;
      if (found) {
        const { routeName } = found;
        if (route.waypoints.length > 99) {
          const origen = route.waypoints.find((wp) => wp.id === "origen");
          const destino = route.waypoints.find((wp) => wp.id === "destino");

          // 🔹 1. Filtrar órdenes válidas
          let selectedOrders = route.waypoints.filter(
            (order) => order.id !== "origen" && order.id !== "destino"
          );

          const selectedOrderIds = selectedOrders.map((order) => order.id);

          // 🔹 2. Obtener `ordersToOptimize`
          const ordersToOptimize = dataMerge.filter((order) =>
            selectedOrderIds.includes(order.id)
          );

          // 🔹 3. Convertir datos a `{id, lat, lng}`
          const dataToSend = ordersToOptimize
            .filter(
              (o) =>
                o.toAddressLocation &&
                typeof o.toAddressLocation._lat === "number" &&
                typeof o.toAddressLocation._long === "number"
            )
            .map((order) => ({
              id: order.id,
              lat: parseFloat(order.toAddressLocation._lat),
              lng: parseFloat(order.toAddressLocation._long),
            }));

          let numberOfRoutes = Math.ceil(parseInt(dataToSend.length) / 100);

          const { assignments } = kmeans(dataToSend, numberOfRoutes, 100);

          // 🔹 4. Crear clusters iniciales
          let clusters = Array.from({ length: numberOfRoutes }, () => []);

          // Asignamos las órdenes a cada cluster resultante
          assignments.forEach((clusterIndex, i) => {
            clusters[clusterIndex].push(dataToSend[i].id);
          });
          const getBaseRouteName = (name) => name.split("_sub")[0];
          // aqui ya esta el cluster grande  dividido
          // solo debo crear un segundo archivo reqApiOptimizationData seria un optimizationData
          // realizar la consultas fetchOptimizedWaypoints
          // con la respuesta actualizar estas ordenes en updatedOrders de este grupo de ordenes el cual
          let optimizationDataSubRoutes = clusters.map((cluster, index) => {
            const baseRouteName = getBaseRouteName(routeName);
            // Generamos un nombre único para la sub-ruta
            const subRouteName = `${baseRouteName}_sub${index + 1}`;

            // Waypoints => [origen, ...cluster, destino]
            const subWaypoints = [
              {
                id: origen.id,
                lon: origen.lon,
                lat: origen.lat,
                name: "",
              },
              ...cluster
                .map((id) => {
                  let ord = dataMerge.find((o) => o.id === id);
                  if (!ord) {
                    console.warn(`⚠️ No se encontró orden con id: ${id}`);
                    return null; // Retorna null para filtrar después
                  }
                  return {
                    id: ord.id,
                    lat: ord.toAddressLocation?._long || 0, // Asegurar que latitud tenga un valor
                    lon: ord.toAddressLocation?._lat || 0, // Asegurar que longitud tenga un valor
                    name: "",
                  };
                })
                .filter(Boolean), // Elimina elementos null que se generaron en el .map()
              {
                id: destino.id,
                lon: origen.lon,
                lat: origen.lat,
                name: "",
              },
            ];

            return {
              route: subRouteName, // Nombre de sub-ruta
              vehicle: "driving",
              waypoints: subWaypoints,
            };
          });

          // 1) Crear un nuevo array con nombres de ruta únicos
          const renamedSubRoutes = optimizationDataSubRoutes.map(
            (subRoute, index) => {
              const newRouteName = `${subRoute.route}_${index + 1}`;

              return {
                ...subRoute,
                route: newRouteName, // Asigna el nuevo nombre
              };
            }
          );

          console.log(optimizationDataSubRoutes);
          // 8) Llamar a la API por cada sub-ruta y acumular resultados
          for (let subRoute of optimizationDataSubRoutes) {
            let optimizedRoute = await fetchOptimizedWaypoints(subRoute);
            if (!optimizedRoute) {
              console.warn("No se pudo optimizar la sub-ruta:", subRoute);
              continue;
            }

            // Nombre de la sub-ruta (e.g. "RutaA_sub1")
            const subRouteName = subRoute.route;

            // Mapeo de waypoints con info extra (tramo, distancia, tiempo) + routeName
            const waypointsWithRoute = optimizedRoute.waypoints.map(
              (waypoint) => {
                // Buscar el tramo correspondiente según 'index'
                const matchingLeg = optimizedRoute.legs.find(
                  (leg, idx) => idx + 1 === waypoint.index
                );
                return {
                  ...waypoint,
                  routeName: subRouteName, // Asignar nombre único a cada sub-ruta
                  tramo: matchingLeg?.tramo || "",
                  distancia: matchingLeg?.distancia || "",
                  tiempo: matchingLeg?.tiempo || "",
                };
              }
            );

            // Acumular en allOptimizedWaypoints
            allOptimizedWaypoints.push(...waypointsWithRoute);

            // Acumular la geometría
            allOptimizedRoutes.push({
              route: subRouteName,
              geometry: optimizedRoute.geometry,
            });

            // Acumular métricas
            allMetrics[subRouteName] = {
              totalDistance: optimizedRoute.totalDistance,
              totalTime: optimizedRoute.totalTime,
            };

            // 9) Actualizar finalUpdatedOrders para los pedidos de esta sub-ruta
            finalUpdatedOrders = finalUpdatedOrders.map((order) => {
              // Encontrar si este pedido está en la lista de waypoints
              const foundWaypoint = waypointsWithRoute.find(
                (wp) => wp.id === order.id
              );
              if (foundWaypoint) {
                return {
                  ...order,
                  driverLicensePlate: foundWaypoint.routeName, // sub-ruta
                  orderNumber: foundWaypoint.index, // Índice en la ruta
                };
              }
              return order;
            });
          }
        } else {
          let optimizedRoute = await fetchOptimizedWaypoints(route);
          if (!optimizedRoute) {
            console.warn("No se pudo optimizar la ruta:", route);
            continue;
          }

          // Mapeo de waypoints
          const waypointsWithRoute = optimizedRoute.waypoints.map(
            (waypoint) => {
              const matchingLeg = optimizedRoute.legs.find(
                (leg, idx) => idx + 1 === waypoint.index
              );
              return {
                ...waypoint,
                routeName: routeName,
                tramo: matchingLeg?.tramo || "",
                distancia: matchingLeg?.distancia || "",
                tiempo: matchingLeg?.tiempo || "",
              };
            }
          );

          // Acumular en allOptimizedWaypoints
          allOptimizedWaypoints.push(...waypointsWithRoute);

          // Acumular geometry
          allOptimizedRoutes.push({
            route: routeName, // aquí no subdividimos, así que es el mismo
            geometry: optimizedRoute.geometry,
          });

          // Acumular métricas
          allMetrics[routeName] = {
            totalDistance: optimizedRoute.totalDistance,
            totalTime: optimizedRoute.totalTime,
          };

          // Actualizar finalUpdatedOrders
          finalUpdatedOrders = finalUpdatedOrders.map((order) => {
            const foundWaypoint = waypointsWithRoute.find(
              (wp) => wp.id === order.id
            );
            if (foundWaypoint) {
              return {
                ...order,
                driverLicensePlate: foundWaypoint.routeName,
                orderNumber: foundWaypoint.index,
              };
            }
            return order;
          });
        }
      }
    }

    updatedOrders = dataMerge.map((order) => {
      // Buscar el waypoint que contiene el ID de la orden
      const foundWaypoint = allOptimizedWaypoints.find((waypoint) =>
        waypoint.ids.includes(order.id)
      );

      if (foundWaypoint) {
        return {
          ...order,
          driverLicensePlate: foundWaypoint.routeName, // Ruta asignada
          orderNumber: foundWaypoint.index, // Índice de la posición en la ruta
        };
      }

      return order; // Si no se encuentra, se mantiene igual
    });

    setViewDataMerge((prevData) =>
      prevData.map(
        (order) =>
          updatedOrders.find((updated) => updated.id === order.id) || order
      )
    );

    setLocalDataMerge((prevData) =>
      prevData.map(
        (order) =>
          updatedOrders.find((updated) => updated.id === order.id) || order
      )
    );

    setRouteGeometry(allOptimizedRoutes);
    setOptimizedWaypoints(allOptimizedWaypoints);
    setMetricsByRoutes(allMetrics);

    setLoading(false);
  };

  return (
    <>
      <Button onClick={handleOpenRutearDialog}>Rutar visitas</Button>
      <RoutingDialog
        openRuoterDialog={openRuoterDialog}
        handleCancelRutearDialog={handleCancelRutearDialog}
        handleApplyRuteo={handleApplyRuteo}
        setOpenRuoterDialog={setOpenRuoterDialog}
        dataMerge={dataMerge}
        selectedOrders={selectedOrders}
        setViewDataMerge={setViewDataMerge}
        setLocalDataMerge={setLocalDataMerge}
        loading={loading}
        routes={routes}
        setLoading={setLoading}
        optimizeOptions={optimizeOptions}
        setOptimizeOptions={setOptimizeOptions}
        reqApiOptimizationData={reqApiOptimizationData}
      />
    </>
  );
};

export default ButonRutearDialog;
