import { Fragment, useEffect, useState } from "react";
import { collection, deleteDoc, doc } from "firebase/firestore";

import {
  Box,
  List,
  ListItem,
  ListItemText,
  Typography,
  Divider,
  Collapse,
  IconButton,
  Tooltip,
  Button,
} from "@mui/material";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { translateStatesBicci } from "../../utils/objects/translateStates";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import LocalPrintshopIcon from "@mui/icons-material/LocalPrintshop";
import RouteIcon from "@mui/icons-material/Route";
import { db } from "../../firebase";
import axios from "axios";
import { Row } from "react-bootstrap";

const MapPanelAdminRoutes = ({
  loading,
  routes,
  optimizedWaypoints,
  selectedViewRoutes,
  setSelectedViewRoutes,
  hoveredWaypoint,
  setHoveredWaypoint,
  routeGeometry,
  selectedViewLines,
  setSelectedViewLines,
}) => {
  const [rutas, setRutas] = useState([]);

  const [expandedRoutes, setExpandedRoutes] = useState([]);

  useEffect(() => {}, [
    optimizedWaypoints,
    selectedViewRoutes,
    selectedViewLines,
  ]);

  useEffect(() => {
    if (routes) {
      setRutas(routes);
    }
  }, [routes]);

  const toggleRouteExpansion = (routeId) => {
    setExpandedRoutes((prev) =>
      prev.includes(routeId)
        ? prev.filter((id) => id !== routeId)
        : [...prev, routeId]
    );
  };

  const formatTimestamp = (timestamp) => {
    if (!timestamp) return "Fecha no disponible";

    // Si es un Timestamp de Firestore (objeto con `seconds` y `nanoseconds`)
    if (timestamp.seconds) {
      return new Date(timestamp.seconds * 1000).toLocaleString();
    }

    // Si es una cadena de fecha (Ej: "2025-02-11T14:49:10.708Z")
    return new Date(timestamp).toLocaleString();
  };

  const handleToggleVisibility = (route) => {
    setSelectedViewRoutes((prevRoutes) => {
      // Si la ruta ya está en la lista, la eliminamos (desactivamos la visibilidad)
      if (prevRoutes.includes(route.id)) {
        return prevRoutes.filter((r) => r !== route.id);
      } else {
        // Si la ruta no está en la lista, la agregamos (activamos la visibilidad)
        return [...prevRoutes, route.id];
      }
    });
  };

  const handleToggleVisibilityLines = (route) => {
    setSelectedViewLines((prevRoutes) => {
      // Si la ruta ya está en la lista, la eliminamos (desactivamos la visibilidad)
      if (prevRoutes.includes(route.id)) {
        return prevRoutes.filter((r) => r !== route.id);
      } else {
        // Si la ruta no está en la lista, la agregamos (activamos la visibilidad)
        return [...prevRoutes, route.id];
      }
    });
  };

  const handleDelete = async (id) => {
    const colletionRef = collection(db, "xpress_deliveryPlans");
    const docRef = doc(colletionRef, id);
    await deleteDoc(docRef);
    console.log("borrado ", id);
  };

  const extracOptimizedWaypoints = (ruta, nameToAsing) =>
    ruta.waypoints
      ? ruta.waypoints.flatMap((wp) =>
          wp.ids.map((id) => ({
            id, // 🔥 ID del waypoint
            orderNumber: wp.index, // 🔥 Posición del waypoint
            driverLicensePlate: nameToAsing,
          }))
        )
      : []; // 🔥 Devuelve un array vacío si no hay waypoints

  const getDataUpdate = (id) => {
    let nameToAsing = "";
    let extractedIDs = [];

    for (let n = 0; n < rutas.length; n++) {
      if (id === rutas[n].id) {
        nameToAsing = rutas[n].route;
        extractedIDs = extracOptimizedWaypoints(rutas[n], rutas[n].route); // 🔥 Ahora solo obtiene `ids`
        break; // 🔥 Sale del loop al encontrar la ruta correcta
      }
    }

    // 🔹 Validar si la ruta no tiene IDs
    if (extractedIDs.length === 0) {
      console.warn(
        `⚠️ No hay IDs en los waypoints para la ruta: ${nameToAsing}`
      );
      return;
    }

    return { extractedIDs, nameToAsing };
  };

  const handleAsingOrders = async (id) => {
    const { extractedIDs, nameToAsing } = getDataUpdate(id);

    let doc = {
      asigm: extractedIDs,
    };

    try {
      const config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_BICCIEXPRESS_UPDATE_ORDERS_ROUTE}`,
        data: { data: doc }, // Enviar documentos en el body
      };

      const response = await axios(config);

      if (response.status === 200) {
        alert("Documento subido correctamente.");
      } else {
        console.warn("⚠️ Respuesta inesperada del servidor:", response.status);
        alert("Hubo un problema al actualizar  el documento.");
      }
    } catch (error) {
      alert(
        "Error al actualizar el documento. Por favor, inténtelo nuevamente."
      );
    }
  };

  return (
    <Box
      width={"100%"}
      p={2}
      bgcolor="background.paper"
      borderRight={1}
      borderColor="divider"
    >
      <Box
        sx={{ display: "flex", justifyContent: "space-between", color: "grey" }}
      >
        <Typography variant="h6">🚚 Rutas del Día </Typography>
        <Tooltip title={"imprimir reporte"}>
          <IconButton
            onClick={() => console.log("Imprimir reporte")}
            sx={{ color: "grey" }}
          >
            <LocalPrintshopIcon
              sx={{
                color: "grey",
                borderRadius: "50%",
              }}
            />
          </IconButton>
        </Tooltip>{" "}
      </Box>
      {loading ? (
        <Typography>Cargando rutas...</Typography>
      ) : rutas.length > 0 ? (
        <List>
          {rutas.map((ruta) => {
            const isExpanded = expandedRoutes.includes(ruta.id);

            return (
              <Box key={ruta.id} mb={2} sx={{ width: "100%" }}>
                {/* 📌 Información de la Ruta */}
                <ListItem
                  button
                  onClick={() => toggleRouteExpansion(ruta.id)}
                  sx={{
                    display: "flex",
                    width: "100%", // 🔹 Ocupar todo el ancho disponible
                    justifyContent: "space-between", // 🔥 Uno a la izquierda y otro a la derecha
                    alignItems: "center", // 🔹 Alinear verticalmente en el centro
                  }}
                >
                  <ListItemText
                    sx={{
                      fontWeight: "bold",
                      color: ruta.routeColor,
                      fontSize: "1.5em",
                    }}
                    primary={`📌  | 🏢 ${ruta.totalDistance} | 🕒  ${
                      ruta.totalTime
                    } ${
                      !ruta.totalDistance
                        ? "Requiere actualización de recorridos"
                        : ""
                    }`}
                    secondary={
                      <>
                        <strong>
                          {" "}
                          📌 Paradas : {ruta.analytics?.totalOrders || 0} |📍
                          Avance {ruta.analytics?.delivered || 0} Entregados |
                          🚛 Inicio: {ruta.initHour} | : 🏁
                          {parseInt(ruta.analytics?.progress)} %{" "}
                        </strong>
                        <br></br>
                        <strong>
                          {" "}
                          REF# : {ruta.id}{" "}
                          {
                            <Button
                              onClick={() => {
                                const confirmDelete = window.confirm(
                                  "¿Estás seguro de que deseas eliminar esta ruta?"
                                );
                                if (confirmDelete) {
                                  handleDelete(ruta.id);
                                }
                              }}
                            >
                              <Typography
                                sx={{ fontSize: "0.9em", color: "red" }}
                              >
                                Borrar
                              </Typography>
                            </Button>
                          }
                        </strong>
                        <strong>
                          {
                            <Button
                              onClick={() => {
                                const confirmAsignarOrdenesARutas =
                                  window.confirm(
                                    "¿Estás seguro de actualizar ordenes en esta ruta?"
                                  );
                                if (confirmAsignarOrdenesARutas) {
                                  handleAsingOrders(ruta.id);
                                }
                              }}
                            >
                              <Typography
                                sx={{ fontSize: "0.9em", color: "blue" }}
                              >
                                Asignar ruta
                              </Typography>
                            </Button>
                          }
                        </strong>
                      </>
                    }
                  />
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column", // 🔥 Alinea los elementos en columna (uno sobre otro)
                      alignItems: "end", // 🔹 Centra los elementos horizontalmente (opcional)
                      justifyContent: "center", // 🔹 Centra los elementos verticalmente (opcional)
                      gap: 0, // 🔥 Agrega espacio entre los elementos (opcional)
                      width: "20%", // 🔹 Asegura que ocupen el ancho completo
                    }}
                  >
                    <IconButton>
                      {isExpanded ? <ExpandLess /> : <ExpandMore />}
                    </IconButton>
                    {optimizedWaypoints && (
                      <Tooltip
                        title={
                          selectedViewRoutes.includes(ruta.id)
                            ? "Ocultar ruta"
                            : "Mostrar ruta"
                        }
                      >
                        <IconButton
                          onClick={() => handleToggleVisibility(ruta)}
                          sx={{
                            bgcolor: selectedViewRoutes.includes(ruta.id)
                              ? "#d1c4e9"
                              : "transparent", // Fondo morado claro si está activo
                            boxShadow: selectedViewRoutes.includes(ruta.id)
                              ? "0px 0px 8px 2px green"
                              : "none", // Sombra verde si está activo
                            borderRadius: "50%", // Redondear
                          }}
                        >
                          {selectedViewRoutes.includes(ruta.id) ? (
                            <VisibilityIcon sx={{ color: "#591e8f" }} />
                          ) : (
                            <VisibilityOffIcon />
                          )}
                        </IconButton>
                      </Tooltip>
                    )}

                    {routeGeometry && (
                      <Tooltip
                        title={
                          selectedViewLines.includes(ruta.id)
                            ? "Ocultar líneas"
                            : "Mostrar líneas"
                        }
                      >
                        <IconButton
                          onClick={() => handleToggleVisibilityLines(ruta)}
                          sx={{
                            bgcolor: selectedViewLines.includes(ruta.id)
                              ? "#d1c4e9"
                              : "transparent",
                            boxShadow: selectedViewLines.includes(ruta.id)
                              ? "0px 0px 8px 2px green"
                              : "none",
                            borderRadius: "50%",
                          }}
                        >
                          <RouteIcon
                            sx={{
                              color: selectedViewLines.includes(ruta.id)
                                ? "#591e8f"
                                : "inherit",
                            }}
                          />
                        </IconButton>
                      </Tooltip>
                    )}
                  </Box>
                </ListItem>

                <Collapse in={isExpanded} timeout="auto" unmountOnExit>
                  <Box pl={4}>
                    {/* 📍 Waypoints */}
                    <Typography variant="subtitle2">📍 Paradas: </Typography>
                    {ruta.waypoints.length > 0 ? (
                      <List dense>
                        {ruta.waypoints
                          .sort((a, b) => a.index - b.index) // Ordenar de menor a mayor por index
                          .map((wp) => (
                            <ListItem
                              key={wp.id}
                              sx={{
                                borderBottom: "1px solid #ddd",
                                paddingY: 1,
                              }}
                              onMouseEnter={() => setHoveredWaypoint(wp.ids[0])} // 🔹 Activa resaltado
                              onMouseLeave={() => setHoveredWaypoint(null)} // 🔹 Desactiva resaltado
                            >
                              <ListItemText
                                sx={{ color: "#030303" }}
                                primary={
                                  <Box sx={{ color: "#030303" }}>
                                    <Typography
                                      variant="body1"
                                      component="strong"
                                    >
                                      🛑 Parada {wp.index}
                                    </Typography>
                                    {" - "}
                                    <Typography
                                      variant="body1"
                                      component="span"
                                      sx={{
                                        color: "green",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      Estado:
                                    </Typography>{" "}
                                    {translateStatesBicci[wp.currentStatus]}
                                    <br />
                                    <Typography
                                      variant="body2"
                                      component="span"
                                      sx={{ fontSize: "0.9rem", color: "#666" }}
                                    >
                                      {wp.routingData ? (
                                        <>
                                          {wp.routingData?.formattedDistance ||
                                            ""}{" "}
                                          {wp.routingData?.formattedTime || ""}{" "}
                                          ({wp.routingData?.segment || ""})
                                        </>
                                      ) : (
                                        "Sin ruta"
                                      )}
                                    </Typography>
                                  </Box>
                                }
                                secondary={
                                  <>
                                    <strong>Carga:</strong>{" "}
                                    {wp.ids.map((id, index) => (
                                      <Fragment key={id}>
                                        <a
                                          href={`https://bicciexpress.cl/dashboard/dispatch/details/${id}`} // 🔹 URL dinámica con el ID
                                          target="_blank"
                                          rel="noopener noreferrer"
                                          style={{
                                            fontWeight: "bold",
                                            textDecoration: "none",
                                            color: "blue",
                                          }} // 🔹 Enlace en negrita sin subrayado
                                        >
                                          {id}
                                        </a>
                                        {index < wp.ids.length - 1 && ", "}{" "}
                                        {/* 🔹 Agrega coma entre IDs excepto en el último */}
                                      </Fragment>
                                    ))}
                                    <br />
                                    <strong>Ubicación:</strong>{" "}
                                    {wp.location.lat}, {wp.location.log}{" "}
                                    {/* 🔹 Corregido "log" a "lon" */}
                                  </>
                                }
                              />
                            </ListItem>
                          ))}
                      </List>
                    ) : (
                      <Typography variant="body2">
                        🔹 No hay waypoints
                      </Typography>
                    )}

                    {/* 📝 Observaciones */}
                    <Typography variant="subtitle2">
                      📝 Observaciones:
                    </Typography>
                    {ruta.observations.length > 0 ? (
                      <List dense>
                        {ruta.observations
                          .slice(-5) // Toma los últimos 5 elementos
                          .reverse()
                          .map((obs) => (
                            <ListItem key={obs.id}>
                              <ListItemText
                                primary={`📄 ${obs.comment.replace(
                                  ", intState: undefined",
                                  ""
                                )}`}
                                secondary={`🕒 ${formatTimestamp(
                                  obs.createdAt
                                )}`}
                              />
                            </ListItem>
                          ))}
                      </List>
                    ) : (
                      <Typography variant="body2">
                        🔹 No hay observaciones
                      </Typography>
                    )}
                  </Box>
                </Collapse>
                <Divider />
              </Box>
            );
          })}
        </List>
      ) : (
        <Typography>No hay rutas para hoy.</Typography>
      )}
    </Box>
  );
};

export default MapPanelAdminRoutes;
