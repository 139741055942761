import React, { useEffect, useState } from "react";
import { SavePlanDialog } from "./dialogs";
import { Button } from "@mui/material";
import axios from "axios";

const ButonHandleOpenSaveDialog = ({
  documentData,
  setDocumentData,
  routes,
  ridersDataByCompany,
  routeInfo,
  companyID,
  documentsToSend,
  setLoading,
  contextReq,
}) => {
  const [documentsTs, setDocumentsTs] = useState([]);

  const [openSaveDialog, setOpenSaveDialog] = useState(false);

  // ⚠️ Corrección: Sincronizar documentsTs cuando documentsToSend cambia
  useEffect(() => {
    if (documentsToSend && documentsToSend.length > 0) {
      setDocumentsTs(documentsToSend);
    }
  }, [documentsToSend]);

  const handleOpenSaveDialog = () => {
    setOpenSaveDialog(true);
  };

  const handleSaveDocument = async () => {
    setLoading(true);

    try {
      const config = {
        method: "post",
        maxBodyLength: Infinity,
        url: process.env.REACT_APP_BICCIEXPRESS_CREATE_ROUTE,
        headers: {
          companyID: "FroY2JbeirD7B1MRYEXo", //contextReq.companyID,
          Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJCSUNDSUNMT1VEIiwibmFtZSI6IkJJQ0NJRVhQUkVTUyIsImNvbXBhbnlJRCI6IkZyb1kySmJlaXJEN0IxTVJZRVhvIiwiZW52aXJvbm1lbnQiOiJwcm9kdWN0aW9uIiwiaWF0IjoxNzIwOTU5MTk3fQ.mOLiF57-r4lX48OnT4nxsjyG6Tw0MWiKEC1_56CxmWE`, //${contextReq.token},
        },
        data: { data: documentsTs }, // Enviar documentos en el body
      };

      const response = await axios(config);

      if (response.status === 200) {
        alert("Documento guardado correctamente.");
      } else {
        console.warn("⚠️ Respuesta inesperada del servidor:", response.status);
        alert("Hubo un problema al guardar el documento.");
      }
    } catch (error) {
      alert("Error al guardar el documento. Por favor, inténtelo nuevamente.");
    }

    setOpenSaveDialog(false);
    setLoading(false);
  };

  const handleCancelSave = () => {
    setOpenSaveDialog(false);
  };

  const changeDataValues = (route, newDate) => {
    setDocumentsTs((prev) =>
      prev.map((doc) =>
        doc.route === route
          ? {
              ...doc,
              id: `${newDate.replace(/-/g, "")}_${doc.id
                .split("_")
                .slice(1)
                .join("_")}`, // Actualiza el ID con la nueva fecha
              dayStart: newDate,
            }
          : doc
      )
    );
  };

  const exportarHojaRutasActualizaciones = () => {
    console.log("📄 Exportando hoja de rutas actualizadas");
  };

  return (
    <>
      <Button onClick={handleOpenSaveDialog}>Guardar Documentos</Button>
      <SavePlanDialog
        openSaveDialog={openSaveDialog}
        handleCancelSave={handleCancelSave}
        handleSaveDocument={handleSaveDocument}
        documentData={documentData}
        setDocumentData={setDocumentData}
        routes={routes}
        ridersDataByCompany={ridersDataByCompany}
        exportarHojaRutasActualizaciones={exportarHojaRutasActualizaciones}
        routeInfo={routeInfo}
        companyID={companyID}
        documentsToSend={documentsTs} // ⚠️ Ahora `documentsTs` se pasa correctamente al hijo
        changeDataValues={changeDataValues}
      />
    </>
  );
};

export default ButonHandleOpenSaveDialog;
