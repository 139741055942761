import React, { useState } from "react";
import { doc, updateDoc, collection, getDoc, setDoc } from "firebase/firestore";
import { db } from "../../firebase";
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  Typography,
  Paper,
  Box,
} from "@mui/material";
import * as XLSX from "xlsx";
import { useDropzone } from "react-dropzone";
import { format } from "date-fns";
import { es } from "date-fns/esm/locale";

const ReusableFormImportReportGeosort = ({
  open,
  handleClose,
  handleAddItem,
  setOpen,
  companyData,
  prefix,
  dataOrigin,
}) => {
  const collRef = collection(db, process.env.REACT_APP_COL_ORDERS);
  const collRefIntegracionBicci = collection(
    db,
    process.env.REACT_APP_COL_USERS_ORDERS
  );
  const [erroMensaje, setErroMensaje] = useState(null);
  const [file, setFile] = useState(null);

  const onDrop = (acceptedFiles) => {
    setErroMensaje(null);
    setFile(acceptedFiles[0]);
  };

  const formFieldsEdit = [
    { variable: "Suborden", label: "Suborden", type: "string", require: true },
    { variable: "Do", label: "Do", type: "string", require: true },
    { variable: "Estado", label: "Estado", type: "string", require: true },
    { variable: "Patente", label: "Patente", type: "string", require: true },
    { variable: "Empresa", label: "Empresa", type: "string", require: true },
    { variable: "Idruta", label: "Idruta", type: "number", require: true },
    {
      variable: "Posicionruta",
      label: "Posición Ruta",
      type: "number",
      require: false,
    },
    { variable: "Ct", label: "Ct", type: "number", require: true },
    {
      variable: "Direccion",
      label: "Direccion",
      type: "string",
      require: true,
    },
    {
      variable: "Localidad",
      label: "Localidad",
      type: "string",
      require: true,
    },
    { variable: "Region", label: "Region", type: "string", require: true },
    { variable: "Volumen", label: "Volumen", type: "number", require: true },
    {
      variable: "Horainicio",
      label: "Hora Inicio",
      type: "string",
      require: true,
    },
    { variable: "Horafin", label: "Hora Fin", type: "string", require: true },
    {
      variable: "Fechainicioruta",
      label: "Fecha Inicio Ruta",
      type: "string",
      require: true,
    },
    {
      variable: "Fechapactada",
      label: "Fecha Pactada",
      type: "string",
      require: true,
    },
    {
      variable: "Fechaentregareal",
      label: "Fecha Entrega Real",
      type: "string",
      require: false,
    },
    {
      variable: "Fechaestimada",
      label: "Fecha Estimada",
      type: "string",
      require: false,
    },
    {
      variable: "Tipoviaje",
      label: "Tipo de Viaje",
      type: "string",
      require: true,
    },
    { variable: "Tipogeo", label: "Tipo Geo", type: "string", require: true },
    {
      variable: "Tipodocumento",
      label: "Tipo Documento",
      type: "string",
      require: true,
    },
    {
      variable: "Paperlessreceptor",
      label: "Receptor Paperless",
      type: "string",
      require: false,
    },
    {
      variable: "Paperlessrut",
      label: "RUT Paperless",
      type: "string",
      require: false,
    },
    {
      variable: "Paperlesscode",
      label: "Código Paperless",
      type: "string",
      require: false,
    },
    {
      variable: "Metodoentrega",
      label: "Método de Entrega",
      type: "string",
      require: true,
    },
    {
      variable: "Comentarionoentrega",
      label: "Comentario No Entrega",
      type: "string",
      require: false,
    },
    {
      variable: "Motivonoentrega",
      label: "Motivo No Entrega",
      type: "string",
      require: false,
    },
    {
      variable: "Driveremail",
      label: "Email del Conductor",
      type: "string",
      require: false,
    },
    {
      variable: "Drivername",
      label: "Nombre del Conductor",
      type: "string",
      require: false,
    },
    {
      variable: "Driverrut",
      label: "RUT del Conductor",
      type: "string",
      require: false,
    },
    {
      variable: "Pointlatitude",
      label: "Latitud del Punto",
      type: "number",
      require: true,
    },
    {
      variable: "Pointlongitude",
      label: "Longitud del Punto",
      type: "number",
      require: true,
    },
    {
      variable: "Deliverylatitude",
      label: "Latitud de Entrega",
      type: "number",
      require: true,
    },
    {
      variable: "Deliverylongitude",
      label: "Longitud de Entrega",
      type: "number",
      require: true,
    },
    {
      variable: "Distance_totalization",
      label: "Totalización de Distancia",
      type: "number",
      require: true,
    },
    {
      variable: "Simpliroute_id",
      label: "ID Route",
      type: "number",
      require: false,
    },
    {
      variable: "Imageurl",
      label: "URL de la Imagen",
      type: "string",
      require: false,
    },
    { variable: "LPN", label: "LPN", type: "string", require: true },
    {
      variable: "LPN_Container",
      label: "Contenedor LPN",
      type: "string",
      require: true,
    },
    { variable: "Commerce", label: "Comercio", type: "string", require: true },
    {
      variable: "ParentOrder",
      label: "Orden Padre",
      type: "string",
      require: true,
    },
    { variable: "Activa", label: "Activa", type: "boolean", require: true },
  ];

  const handleFileSave = async () => {
    if (!file) return;
    const data = await file.arrayBuffer();
    const workbook = XLSX.read(data, { type: "buffer" });
    const worksheet = workbook.Sheets[workbook.SheetNames[0]];
    const jsonData = XLSX.utils.sheet_to_json(worksheet);

    const isValidData = (data, formFields) => {
      for (let item of data) {
        for (let field of formFields) {
          if (field.require === true) {
            if (
              field.variable !== "id" &&
              (!item.hasOwnProperty(field.variable) ||
                item[field.variable] === "")
            ) {
              setErroMensaje(
                `Falta un valor para la columna ${field.variable} o está vacío en uno de los registros.`
              );
              return false;
            }
          }
        }
      }
      return true;
    };

    if (isValidData(jsonData, formFieldsEdit)) {
      handleSave(jsonData);
      handleClose();
    } else {
      console.log("La validación de los datos falló.");
    }
  };

  const handleSave = async (formData) => {
    const upsertDocument = async (docData) => {
      let orderkey = prefix + docData.Suborden;

      const fechaActual = new Date();
      const opcionesFormato = {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
      };

      const formatoDeseado = fechaActual.toLocaleString(
        undefined,
        opcionesFormato
      );

      let Fechaentregareal = docData.Fechaentregareal
        ? docData.Fechaentregareal
        : "";

      let Deliverylongitude = docData.Deliverylongitude.toString().replace(
        '"',
        ""
      );
      let Deliverylatitude = docData.Deliverylatitude.toString().replace(
        '"',
        ""
      );
      let Pointlatitude = docData.Pointlatitude.toString().replace('"', "");
      let Pointlongitude = docData.Pointlongitude.toString().replace('"', "");
      let Distance_totalization =
        docData.Distance_totalization.toString().replace('"', "");

      let order = {
        int_client_soc: docData.Suborden.toString(),
        int_simpliRouteId: docData.Simpliroute_id ? docData.Simpliroute_id : "",
        int_falabella_status: docData.Estado,
        toAddressLocation: {
          lat: parseFloat(Pointlatitude),
          lng: parseFloat(Pointlongitude),
        },
        driverEmail: docData.Driveremail ? docData.Driveremail : "",
        driverName: docData.Drivername ? docData.Drivername : "",
        driverRut: docData.Driverrut ? docData.Driverrut : "",
        routeRanceTotalization: parseFloat(Distance_totalization),
        routeId: docData.Idruta,
        routePosition: docData.Posicionruta,
        routeLocality: docData.Localidad,
        deliveryMethod: docData.Metodoentrega,
        deliveryPictures: docData.Imageurl ? docData.Imageurl : "",
        deliveryComment: docData.Comentarionoentrega
          ? docData.Comentarionoentrega
          : "",
        int_falabella_status_descriptor: docData.Motivonoentrega
          ? docData.Motivonoentrega
          : "",
        deliveryRealDeliveryDate: Fechaentregareal ? Fechaentregareal : "",
        deliveryReceptor: docData.Paperlessreceptor
          ? docData.Paperlessreceptor
          : "",
        deliveryRut: docData.Paperlessrut ? docData.Paperlessrut : "",
        deliveryGeoref: {
          lat: parseFloat(Deliverylongitude),
          lng: parseFloat(Deliverylatitude),
        },
        int_warehouseName: docData.Ct,
        driverLicensePlate: docData.Patente,
      };

      const docRef = doc(collRef, orderkey);
      const docRefIntegracionBicci = doc(collRefIntegracionBicci, orderkey);
      const docSnap = await getDoc(docRef);
      const docSnapBicci = await getDoc(docRefIntegracionBicci);
      if (!docSnapBicci.exists()) {
        if (docSnap.exists()) {
          await updateDoc(docRef, order);
          console.log(
            "Documento actualizado con éxito REPORTE GEOSORT WEB.",
            docData.Suborden
          );
        } else {
          order.dataOrigin = "GSDATA";
          order.key = orderkey;
          order.id = docData.Suborden.toString();
          await setDoc(docRef, docData);
          console.log("Documento creado desde REPORTE GEOSORT WEB..", order);
        }
      } else {
        console.log("Documento Gestionado desde BICCI", docData.Suborden);
      }
    };

    if (Array.isArray(formData)) {
      for (const item of formData) {
        await upsertDocument(item);
      }
    } else {
      await upsertDocument(formData);
    }

    setOpen(false);
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: "text/*, application/*",
    maxFiles: 1,
    maxSize: 5000000,
  });

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">
        Atualizar estados Con Documento Reporte Geosort WEB{" "}
      </DialogTitle>
      <DialogContent>
        <Paper variant="outlined" sx={{ p: 2, textAlign: "center", mt: 2 }}>
          <Box {...getRootProps()} sx={{ border: "2px dashed #ccc", p: 2 }}>
            <input {...getInputProps()} />
            <Typography>
              Arrastra y suelta algunos archivos aquí, o haz clic para
              seleccionar archivos
            </Typography>
          </Box>
        </Paper>
        {file && <p>File ready to be saved: {file.name}</p>}
        <div>
          {erroMensaje ? (
            <Typography sx={{ m: 1, color: "red" }}>{erroMensaje}</Typography>
          ) : null}
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            setErroMensaje(null);
            setFile(null);
            handleClose();
          }}
          color="primary"
        >
          Cancelar
        </Button>
        <Button onClick={handleFileSave} color="primary" disabled={!file}>
          Guardar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ReusableFormImportReportGeosort;
