import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Grid,
  Paper,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Switch,
  Box,
  Divider,
  Table,
  TableHead,
  TableCell,
  List,
  ListItemText,
  ListItem,
  TableBody,
  TableRow,
} from "@mui/material";

export const PlansDialog = ({
  open,
  handleClose,
  plans = [],
  handleSelectPlan,
  currentPage,
  handlePrevPage,
  handleNextPage,
}) => (
  <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
    <DialogTitle>Planes Guardados</DialogTitle>
    <DialogContent>
      {plans.length === 0 ? (
        <Typography>No hay planes guardados.</Typography>
      ) : (
        <Grid container spacing={2}>
          {plans.map((plan) => (
            <Grid item xs={12} sm={6} md={4} key={plan.id}>
              <Paper
                onClick={() => handleSelectPlan(plan)}
                style={{ cursor: "pointer", padding: "10px" }}
              >
                <Typography variant="subtitle1">
                  {plan.name || "Sin nombre"}
                </Typography>
                <Typography variant="body2">
                  Fecha: {plan.date || "Sin fecha"}
                </Typography>
              </Paper>
            </Grid>
          ))}
        </Grid>
      )}
    </DialogContent>
    <DialogActions>
      <Button onClick={handlePrevPage} disabled={currentPage === 0}>
        Anterior
      </Button>
      <Button onClick={handleNextPage}>Siguiente</Button>
      <Button onClick={handleClose}>Cerrar</Button>
    </DialogActions>
  </Dialog>
);

export const OptimizeDialog = ({
  openOptimizeDialog,
  handleCancelOptimize,
  handleApplyOptimize,
  optimizeOptions,
  setOptimizeOptions,
}) => (
  <Dialog
    open={openOptimizeDialog}
    onClose={handleCancelOptimize}
    fullWidth
    maxWidth="md"
  >
    <DialogTitle>Configurar Rutas</DialogTitle>
    <DialogContent>
      {/* SECCIÓN: SECTORIZAR */}
      <Box mb={2}>
        <Divider textAlign="left">Sectorizar</Divider>
        <FormControlLabel
          control={
            <Switch
              checked={optimizeOptions.applySectorization}
              onChange={(e) =>
                setOptimizeOptions((prev) => ({
                  ...prev,
                  applySectorization: e.target.checked,
                }))
              }
            />
          }
          label="Aplicar sectorización por polígonos"
        />
        <FormControlLabel
          control={
            <Switch
              checked={optimizeOptions.keepSectorsSeparated}
              onChange={(e) =>
                setOptimizeOptions((prev) => ({
                  ...prev,
                  keepSectorsSeparated: e.target.checked,
                }))
              }
            />
          }
          label="Mantener separados los polígonos"
        />
        <FormControlLabel
          control={
            <Switch
              checked={optimizeOptions.returnToStore}
              onChange={(e) =>
                setOptimizeOptions((prev) => ({
                  ...prev,
                  returnToStore: e.target.checked,
                }))
              }
            />
          }
          label="Retornar a tienda origen en cada vuelta"
        />
        <FormControl fullWidth margin="dense">
          <InputLabel id="destination-label">Determinar destino</InputLabel>
          <Select
            labelId="destination-label"
            value={optimizeOptions.destinationType}
            onChange={(e) =>
              setOptimizeOptions((prev) => ({
                ...prev,
                destinationType: e.target.value,
              }))
            }
          >
            <MenuItem value="mas_lejano">Más lejano</MenuItem>
            <MenuItem value="destino">Destino</MenuItem>
            <MenuItem value="retorno">Retorno</MenuItem>
          </Select>
        </FormControl>
      </Box>

      {/* SECCIÓN: OPTIMIZACIÓN */}
      <Box mb={2}>
        <Divider textAlign="left">Optimización</Divider>
        <FormControlLabel
          control={
            <Switch
              checked={optimizeOptions.respectZones}
              onChange={(e) =>
                setOptimizeOptions((prev) => ({
                  ...prev,
                  respectZones: e.target.checked,
                }))
              }
            />
          }
          label="Respetar comunas o polígonos creados"
        />
        <FormControl fullWidth margin="dense">
          <InputLabel id="provider-label">Proveedor de Optimización</InputLabel>
          <Select
            labelId="provider-label"
            value={optimizeOptions.provider}
            onChange={(e) =>
              setOptimizeOptions((prev) => ({
                ...prev,
                provider: e.target.value,
              }))
            }
          >
            <MenuItem value="Bicci">Bicci</MenuItem>
          </Select>
        </FormControl>
        <FormControl fullWidth margin="dense">
          <InputLabel id="selector-label">Objetos a Optimizar</InputLabel>
          <Select
            labelId="selector-label"
            value={optimizeOptions.selector}
            onChange={(e) =>
              setOptimizeOptions((prev) => ({
                ...prev,
                selector: e.target.value,
              }))
            }
          >
            <MenuItem value="Todos">Todos los objetos</MenuItem>
            <MenuItem value="Seleccionados">Solo seleccionados</MenuItem>
          </Select>
        </FormControl>
        <FormControlLabel
          control={
            <Switch
              checked={optimizeOptions.createRequireVehicles}
              onChange={(e) =>
                setOptimizeOptions((prev) => ({
                  ...prev,
                  createRequireVehicles: e.target.checked,
                }))
              }
            />
          }
          label="Crear Vehículos"
        />
        {!optimizeOptions.createRequireVehicles && (
          <TextField
            label="Cantidad de Vehículos"
            type="number"
            inputProps={{ min: 1 }}
            fullWidth
            margin="dense"
            value={optimizeOptions.vehicleCount}
            onChange={(e) =>
              setOptimizeOptions((prev) => ({
                ...prev,
                vehicleCount: e.target.value,
              }))
            }
          />
        )}
        <FormControlLabel
          control={
            <Switch
              checked={optimizeOptions.createRequireAgrupations}
              onChange={(e) =>
                setOptimizeOptions((prev) => ({
                  ...prev,
                  createRequireAgrupations: e.target.checked,
                }))
              }
            />
          }
          label="Crear Agrupaciones"
        />
        {!optimizeOptions.createRequireAgrupations && (
          <TextField
            label="Máximo de carga por viaje"
            type="number"
            inputProps={{ min: 1 }}
            fullWidth
            margin="dense"
            value={optimizeOptions.agrupationCount}
            onChange={(e) =>
              setOptimizeOptions((prev) => ({
                ...prev,
                agrupationCount: e.target.value,
              }))
            }
          />
        )}
      </Box>

      {/* SECCIÓN: RESTRICCIONES */}
      <Box mb={2}>
        <Divider textAlign="left">Restricciones</Divider>
        <TextField
          label="Máximo de kilómetros recorridos"
          type="number"
          inputProps={{ min: 1 }}
          fullWidth
          margin="dense"
          value={optimizeOptions.maxKilometers}
          onChange={(e) =>
            setOptimizeOptions((prev) => ({
              ...prev,
              maxKilometers: e.target.value,
            }))
          }
        />
        <TextField
          label="Máximo de tiempo recorrido (minutos)"
          type="number"
          inputProps={{ min: 1 }}
          fullWidth
          margin="dense"
          value={optimizeOptions.maxTime}
          onChange={(e) =>
            setOptimizeOptions((prev) => ({
              ...prev,
              maxTime: e.target.value,
            }))
          }
        />
      </Box>
    </DialogContent>
    <DialogActions>
      <Button onClick={handleCancelOptimize} color="primary">
        Cancelar
      </Button>
      <Button onClick={handleApplyOptimize} color="primary">
        Aceptar
      </Button>
    </DialogActions>
  </Dialog>
);

export const SavePlanDialog = ({
  openSaveDialog,
  handleCancelSave,
  handleSaveDocument,
  routes,
  exportarHojaRutasActualizaciones,
  routeInfo,
  companyID,
  documentsToSend,
  changeDataValues,
}) => {
  return (
    <Dialog
      open={openSaveDialog}
      onClose={handleCancelSave}
      fullWidth
      maxWidth="lg"
      sx={{ height: "80vh", width: "80vw", padding: "auto" }}
    >
      <DialogTitle sx={{ color: "#030303" }}>Guardar Documento</DialogTitle>
      <DialogContent>
        <Box mt={2}>
          <Typography variant="subtitle1">Documentos a crear:</Typography>
          <List>
            {routes.map((route) => (
              <ListItem key={route}>
                <ListItemText
                  primary={
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        verticalAlign: "center",
                      }}
                    >
                      <Typography>{route} </Typography>
                      <Typography>
                        INTERNAL ID:{" "}
                        {documentsToSend.find((doc) => doc.route === route)
                          ?.id || "Sin fecha"}
                      </Typography>

                      <TextField
                        sx={{ width: "50%" }}
                        label={`Fecha de salida`}
                        type="date"
                        fullWidth
                        margin="dense"
                        InputLabelProps={{ shrink: true }}
                        value={
                          documentsToSend.find((doc) => doc.route === route)
                            ?.dayStart
                        }
                        onChange={(e) =>
                          changeDataValues(route, e.target.value)
                        }
                        inputProps={{
                          min: new Date().toISOString().split("T")[0],
                        }} // No permite fechas pasadas
                      />
                    </Box>
                  }
                />
              </ListItem>
            ))}
          </List>
        </Box>
        <Button
          variant="contained"
          color="primary"
          fullWidth
          style={{ marginTop: 10 }}
          onClick={() => exportarHojaRutasActualizaciones(routeInfo)}
        >
          Descargar Documento
        </Button>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancelSave} color="primary">
          Cancelar
        </Button>
        <Button onClick={() => handleSaveDocument(companyID)} color="primary">
          Aceptar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export const FilterDialog = ({
  openFilterDialog,
  handleCancelFilter,
  availableStatuses,
  selectedStatuses,
  setSelectedStatuses,
  translateStatesfalabbela,
  translateStatesBicciFilter,
  handleApplyFilter,
}) => (
  <Dialog open={openFilterDialog} onClose={handleCancelFilter}>
    <DialogTitle>Filtrar por Estados</DialogTitle>
    <DialogContent>
      <FormGroup>
        {availableStatuses.map((status) => {
          const normalizedStatus =
            typeof status === "string" ? status.trim().toLowerCase() : status;

          return (
            <FormControlLabel
              key={status}
              control={
                <Checkbox
                  checked={selectedStatuses.includes(normalizedStatus)}
                  onChange={(e) => {
                    setSelectedStatuses((prev) =>
                      e.target.checked
                        ? Array.from(new Set([...prev, normalizedStatus]))
                        : prev.filter((s) => s !== normalizedStatus)
                    );
                  }}
                />
              }
              label={
                translateStatesBicciFilter[String(status).trim()]
                  ? translateStatesBicciFilter[String(status).trim()]
                  : "sin estado"
              }
            />
          );
        })}
      </FormGroup>
    </DialogContent>
    <DialogActions>
      <Button onClick={handleCancelFilter} color="primary">
        Cancelar
      </Button>
      <Button onClick={handleApplyFilter} color="primary">
        Aceptar
      </Button>
    </DialogActions>
  </Dialog>
);

export const RoutingDialog = ({
  openRuoterDialog,
  handleCancelRutearDialog,
  handleApplyRuteo,
  reqApiOptimizationData,
}) => (
  <Dialog open={openRuoterDialog} onClose={handleCancelRutearDialog}>
    <DialogTitle sx={{ color: "#030303" }}>Crear rutas</DialogTitle>
    <DialogActions>
      <Button onClick={handleCancelRutearDialog} color="primary">
        Cancelar
      </Button>
      <Button onClick={handleApplyRuteo} color="primary">
        Aplicar
      </Button>
    </DialogActions>
    <>
      {reqApiOptimizationData.length > 0 ? (
        <DialogContent>
          <p>RUTAS: {reqApiOptimizationData.length}</p>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>#</TableCell>
                <TableCell>Paradas</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {reqApiOptimizationData.map((item, index) => (
                <TableRow key={index}>
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>{`Origen -> ${
                    item.waypoints.length - 2
                  } -> Destino`}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </DialogContent>
      ) : (
        <DialogContent>
          Primero configura las rutas en Optimizar y agrupar
        </DialogContent>
      )}
    </>
  </Dialog>
);
