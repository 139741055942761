import React, { useEffect, useState } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { db } from "../../firebase";
import FadeInOut from "../../components/animations/FadeInOut";
import {
  Grid,
  Box,
  Tooltip,
  Button,
  Drawer,
  IconButton,
  Typography,
  ListItemIcon,
  ListItemText,
  List,
  ListItem,
} from "@mui/material";
import { Home, Person, Settings, ChevronLeft } from "@mui/icons-material";
import WarehouseIcon from "@mui/icons-material/Warehouse";
import BallotIcon from "@mui/icons-material/Ballot";
import BusinessIcon from "@mui/icons-material/Business";
import { DashboardSidebar } from "../../components/Dashboard/MenusDashboardSidebars/DashboardSidebar";
import { DashboardSidebarCliente } from "../../components/Dashboard/MenusDashboardSidebars/DashboardSidebarCliente";
import { DashboardSidebarAdminCompany } from "../../components/Dashboard/MenusDashboardSidebars/DashboardSidebarAdminCompany";
import useTitle from "../../components/Seo/useTitle";
import useGlobalConfig from "../../hooks/globalConfig";
import { UserContext } from "../../context/CurrentUserProvider";
import CarouselItemsApp from "../../components/CarouselItemsApp/CarouselItemsApp";
import DashboardNavbarMui from "../../components/Dashboard/DashboardNavbarMui";
import { grey } from "@mui/material/colors";
import ViewSidebarIcon from "@mui/icons-material/ViewSidebar";
import { DashboardSidebarCompanyOperator } from "../../components/Dashboard/MenusDashboardSidebars/DashboardSidebarCompanyOperator";
import { DashboardSidebarAdminBicciExpress } from "../../components/Dashboard/MenusDashboardSidebars/DashboardSidebarAdminBicciExpress";
import MenuIcon from "@mui/icons-material/Menu";
import { OrdersProvider } from "../../hooks/useOrdersProviders/OrdersContext";

const Dashboard = (props) => {
  const location = useLocation();
  const userAgent = navigator.userAgent.toLowerCase();
  const { userDataContext } = React.useContext(UserContext);
  const { siteName } = useGlobalConfig();
  useTitle({ title: `${siteName} Dashboard` });

  const [drawerOpen, setDrawerOpen] = useState(false);

  const [mountedCarrusell, setMountedCarrusell] = React.useState(false);

  const [showoggleMenu, setShowoggleMenu] = React.useState(true);

  const [userNewMenssage, setuserNewMenssage] = React.useState(0);

  const [landScren, setLandScren] = React.useState(false); // pasar a false

  const toggleMenu = () => setShowoggleMenu(!showoggleMenu);

  const [isExpanded, setIsExpanded] = useState(false);

  const isMobile =
    /iphone|ipod|ipad|android|iemobile|operamini|blackberry|bb10|windows phone|mobile safari|mobile/.test(
      userAgent
    );
  useEffect(() => {
    props.setLandScrenMenu(true);
  }, []);

  useEffect(() => {
    if (isMobile) {
      setLandScren(true);
      setMountedCarrusell(true);
      return;
    }
  }, [isMobile]);
  const mobileMenuId = "primary-search-account-menu-mobile";

  const getNotifications = async (userCompanyID) => {
    if (userCompanyID) {
      try {
        const allMsj = await db
          .collection(`${process.env.REACT_APP_COL_MSJ}`)
          .doc(userCompanyID)
          .collection("system")
          .get();
        var dataAllMsj = allMsj.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        let cant = dataAllMsj.length;
        setuserNewMenssage(cant);
      } catch (error) {}
    }
  };

  getNotifications(userDataContext.CompanyID);

  const sidebarAdmin = (userRoll, userCompanyRoll, handleCloseDrawer) => {
    if (userRoll === process.env.REACT_APP_CLIENT) {
      // Cliente sin empresa asociada
      return (
        <DashboardSidebarCliente
          companyCollaborator={userDataContext.companyCollaborator}
          userDataContext={userDataContext}
          onNavigate={handleCloseDrawer}
        />
      );
    }

    if (userRoll === process.env.REACT_APP_OPS_COMPANY) {
      // Operador de empresa

      return (
        <DashboardSidebarCompanyOperator
          companyCollaborator={userDataContext.companyCollaborator}
          userDataContext={userDataContext}
          onNavigate={handleCloseDrawer}
        />
      );
    }

    if (userRoll === process.env.REACT_APP_ADMIN_COMPANY) {
      // Administrador de empresa
      return (
        <DashboardSidebarAdminCompany
          companyCollaborator={userDataContext.companyCollaborator}
          userDataContext={userDataContext}
          onNavigate={handleCloseDrawer}
        />
      );
    }
    if (userRoll === process.env.REACT_APP_ADMIN_XPRESS) {
      // Operador Bicci
      return (
        <DashboardSidebarAdminBicciExpress
          companyCollaborator={userDataContext.companyCollaborator}
          userDataContext={userDataContext}
          onNavigate={handleCloseDrawer}
        />
      );
    }

    if (userRoll === process.env.REACT_APP_ADMIN) {
      // Admiistrador Bicciexpress
      return (
        <DashboardSidebar
          companyCollaborator={userDataContext.companyCollaborator}
          userDataContext={userDataContext}
          onNavigate={handleCloseDrawer}
        />
      );
    }
  };

  const sidebarAdminButtoms = (
    userRoll,
    userCompanyRoll,
    handleCloseDrawer
  ) => {
    const buttons = [
      {
        text: "Empresa",
        icon: <BusinessIcon />,
        routes: [
          "/dashboard/tablero",
          "/dashboard/companies",
          "/dashboard/users",
          "/dashboard/drivers",
          "/dashboard/vehicles",
        ],
      },
      {
        text: "Gestión",
        icon: <BallotIcon />,
        routes: [
          "/dashboard/guias",
          "/dashboard/eagleeye",
          "/dashboard/search",
          "/dashboard/fleet",
          "/dashboard/routes",
          "/dashboard/planeacion",
          "/dashboard/planificacion-de-operaciones",
          "/dashboard/asignacion-y-ajustes-de-rutas",
          "/dashboard/monitoreo-de-operaciones",
          "/dashboard/evaluacion-de-resultados",
        ],
      },
      {
        text: "Bodegas",
        icon: <WarehouseIcon />,
        routes: [
          "/dashboard/scannerdistribution-v2",
          "/dashboard/incidencia-apelacion",
          "/dashboard/scanner-asignacion",
          "/dashboard/gestion-devoluciones",
          "/dashboard/guiasfalabella",
          "/dashboard/guiasfalabella-same-day",
          "/dashboard/guiasfalabella-home-delivery",
          "/dashboard/metricsfalabella",
          "/dashboard/excepcionesfalabella",
          "/dashboard/guias-facturacion",
          "/dashboard/guiasfalabella-geosort",
          "/dashboard/facturacion",
        ],
      },
      {
        text: "Cuenta",
        icon: <Person />,
        routes: ["/dashboard/mi-cuenta"],
      },
    ];

    return (
      <Box>
        <List sx={{ flexGrow: 1, overflowY: "auto", maxHeight: "100vh", p: 2 }}>
          {buttons.map((button) => (
            <ListItem
              button
              key={button.text}
              onClick={() => Navigate(button.routes[0])}
              selected={button.routes.includes(location.pathname)}
              sx={{
                bgcolor: button.routes.includes(location.pathname)
                  ? "#008000"
                  : "inherit",
                color: "white",
                borderRadius: "8px",
                my: 1,
                transition: "all 0.3s ease-in-out",
                "&:hover": { bgcolor: "#616161" },
                py: 2,
                display: "flex",
                flexDirection: "column", // Pone el texto debajo del icono
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                textAlign: "center",
              }}
            >
              <ListItemIcon
                sx={{
                  color: button.routes.includes(location.pathname)
                    ? "#07b107"
                    : "inherit",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  minWidth: "0",
                  flexDirection: "column", // Asegura que el icono y texto estén en columna
                }}
              >
                {button.icon}
                <ListItemText
                  primary={button.text}
                  sx={{
                    fontSize: "0.8rem",
                    textAlign: "center",
                    mt: 0.5, // Espaciado entre el icono y el texto
                    color: "white",
                  }}
                />
              </ListItemIcon>
            </ListItem>
          ))}
        </List>
      </Box>
    );
  };

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  const handleCloseDrawer = () => {
    setDrawerOpen(false);
  };

  const callButtomMenuAdmin = (userRoll, userCompanyRoll) => {
    if (userRoll === process.env.REACT_APP_ADMIN) {
      return (
        <CarouselItemsApp
          userRoll={userRoll}
          companyCollaborator={userDataContext.companyCollaborator}
        />
      );
    }
    if (userRoll === process.env.REACT_APP_CLIENT) {
      return (
        <CarouselItemsApp
          userRoll={userRoll}
          companyCollaborator={userDataContext.companyCollaborator}
        />
      );
    }
    if (userRoll === process.env.REACT_APP_ADMIN_COMPANY) {
      return (
        <CarouselItemsApp
          userRoll={userRoll}
          companyCollaborator={userDataContext.companyCollaborator}
        />
      );
    }
  };

  const menuOption = () => {
    setLandScren(!landScren);
  };

  const MobileMenu = ({ roll, userCompanyRoll }) => {
    return <Box>{sidebarAdmin(roll, userCompanyRoll, handleCloseDrawer)}</Box>;
  };

  return (
    <OrdersProvider>
      <div className="testcomponenbody">
        <Grid>
          <div id="btntogglemenucont"></div>
          <Grid className="DashboardNavbarrow">
            <Grid item xs={12} className="testcomponenbodynav">
              {/* Aquí adaptamos los tamaños */}
              <Grid item xs={12} md={12} lg={12}>
                {/* Tu código original ... */}
                {menuOption && props ? (
                  <DashboardNavbarMui
                    isMobile={isMobile}
                    userNewMenssage={userNewMenssage}
                    menuOption={menuOption}
                    toggleMenu={toggleMenu}
                    landScren={landScren}
                    props={props}
                    setLandScren={setLandScren}
                    handleDrawerToggle={handleDrawerToggle}
                  />
                ) : null}
              </Grid>
            </Grid>
          </Grid>

          {/* <Grid>
          {mountedCarrusell === true ? (
            <Grid item xs={12}>
              {callButtomMenuAdmin(
                userDataContext.roll,
                userDataContext.userCompanyRoll
              )}
            </Grid>
          ) : null}
        </Grid> */}
          <Grid sx={{ display: "flex", flexDirection: "row" }}>
            <Grid
              item
              xs={false}
              md={1}
              lg={2}
              sx={{
                height: "70vh",
                width: "35px",
                background: "#fff",
                padding: 0,
                margin: 0,
                display: {
                  xs: isMobile ? "none" : "block", // Oculta en dispositivos extra pequeños
                  md: "block", // Oculta en dispositivos medianos
                  lg: "block", // Muestra en dispositivos grandes
                },
              }}
            >
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="top"
                alignItems="center"
                height="100%"
                width="100%"
                padding={0}
                margin={0}
              >
                {/* <Tooltip title="Extender Dashboard">
              <IconButton
                onClick={props.expamAdmin}
                sx={{ margin: 1 }} // Ajustar según tus necesidades
              >
                <GridViewIcon fontSize="small" />
              </IconButton>
            </Tooltip> */}
                <Tooltip title="Menu lateral">
                  <IconButton
                    size="large"
                    aria-label="show more"
                    aria-controls={mobileMenuId}
                    aria-haspopup="true"
                    onClick={() => setIsExpanded(!isExpanded)}
                  >
                    {/* <ViewSidebarIcon
                      sx={{ color: landScren ? grey[500] : "#1e2158" }}
                    /> */}
                    {isExpanded ? <ChevronLeft /> : <MenuIcon />}
                  </IconButton>
                </Tooltip>
              </Box>
            </Grid>
            <Drawer
              variant="permanent"
              className={`sidebar ${isExpanded ? "expanded" : "expanded"}`} //  "collapsed"}`}
              onMouseEnter={() => setIsExpanded(true)}
              onMouseLeave={() => setIsExpanded(false)}
              sx={{
                width: isExpanded ? "350px" : "80px",
                flexShrink: 0,
                "& .MuiDrawer-paper": {
                  width: isExpanded ? "350px" : "80px",
                  transition: "width 0.3s ease-in-out",
                  overflowX: "hidden",
                  backgroundColor: "#1e2158",
                  position: "relative",
                  height: "100vh", // Fijar altura completa
                  display: "flex",
                  flexDirection: "column",
                },
              }}
            >
              {/* Contenedor Scrollable */}
              <Box
                sx={{
                  flexGrow: 1, // Permite que tome todo el espacio disponible
                  overflowY: "auto", // Permite scroll cuando sea necesario
                  maxHeight: "100vh", // Evita que crezca más de la pantalla
                }}
              >
                <FadeInOut
                  show={showoggleMenu}
                  duration={100}
                  className={"customStyleFade"}
                >
                  {isExpanded ? (
                    <Box
                      sx={{
                        width: "100%",
                      }}
                    >
                      {sidebarAdmin(
                        userDataContext.roll,
                        userDataContext.userCompanyRoll
                      )}
                    </Box>
                  ) : (
                    <Box
                      sx={{
                        width: "100%",
                      }}
                    >
                      {sidebarAdminButtoms(
                        userDataContext.roll,
                        userDataContext.userCompanyRoll
                      )}
                    </Box>
                  )}
                </FadeInOut>
              </Box>
            </Drawer>
            {isMobile && (
              <>
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  onClick={handleDrawerToggle}
                >
                  <MenuIcon />
                </IconButton>
                <Drawer
                  anchor="left"
                  open={drawerOpen}
                  onClose={handleDrawerToggle}
                >
                  <Box
                    sx={{ width: 250 }}
                    role="presentation"
                    // onClick={handleDrawerToggle}
                    // onKeyDown={handleDrawerToggle}
                  >
                    <MobileMenu
                      roll={userDataContext.roll}
                      userCompanyRoll={userDataContext.userCompanyRoll}
                    />
                  </Box>
                </Drawer>
              </>
            )}

            <Grid
              item
              xs={12}
              sm={12}
              // md={landScren === true ? 12 : 12} // Esta línea parece redundante, ya que estás estableciendo el mismo valor independientemente del valor de `landScren`
              lg={landScren === true ? 12 : 10}
              id="testScroll2"
              className={
                landScren === true ? "landScreend-testScroll2" : "testcomponen"
              }
            >
              <Grid id="dash-container">
                {location.pathname === "/dashboard" && (
                  <Grid item xs={12} className="dash-container-colmun">
                    {/* Contenido específico del Dashboard */}
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: { xs: "column", md: "row" },
                        alignItems: "center",
                        textAlign: "center",
                        color: "#f8f8f8",
                      }}
                    >
                      <Box
                        sx={{
                          width: { xs: "100%", md: "50%" },
                          p: 3,
                        }}
                      >
                        <Typography variant="h4" gutterBottom>
                          ¡Bienvenido al Dashboard!
                        </Typography>
                        <Typography variant="body1">
                          Vamos a revisar cómo están las entregas de hoy...
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          width: { xs: "100%", md: "50%" },
                          display: "flex",
                          justifyContent: "center",
                          p: 3,
                        }}
                      >
                        <img
                          style={{ width: "100%", maxWidth: "400px" }}
                          alt="Bienvenida"
                          src="https://firebasestorage.googleapis.com/v0/b/bicci-7ed2f.appspot.com/o/webfrontend%2Fweb-bicciexpress%2Finicio.webp?alt=media&token=7e1732d5-6931-4105-bf2b-f9b00b366910"
                        />
                      </Box>
                    </Box>
                  </Grid>
                )}
                <Grid item xs={12} className="dash-container-colmun">
                  {/* Contenido de rutas anidadas */}
                  <Outlet props={props} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </OrdersProvider>
  );
};

export default Dashboard;
